import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { MatrixDetailsGridDto } from './matrix-details-grid-data-model';
import { MatrixDetailsGridDataService } from './matrix-details-grid-data.service';
import {
  getMatrixDetailsGridToolbarItems,
  matrixDetailsGridColumns,
} from './matrix-details-grid-model';

@Component({
  selector: 'gk-matrix-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class MatrixDetailsGridComponent extends GkKendoGridComponent<MatrixDetailsGridDto> {
  constructor(
    private matrixDetailsGridDataService: MatrixDetailsGridDataService,
  ) {
    super(matrixDetailsGridDataService);
  }

  override topGridToolbarItems = getMatrixDetailsGridToolbarItems(this);
  override columns = matrixDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  protected override firstRowSelected = true;

  private requestQuantity = 50;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);

    this.matrixDetailsGridDataService
      .queryByState(payload)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): State {
    return {
      filter: {
        filters: [{ field: 'Geom', operator: 'intersects', value: geometry }],
        logic: 'and',
      },
      take: this.requestQuantity,
    };
  }
}
