import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  ActionButtonNavItemConfig,
  ColorsService,
  ContrastDropdownItemId,
  DefaultConfigService,
  DepartmentCode,
  DynamicNavbarConfig,
  GkDynamicNavbarUiStateStoreService,
  GkUserSettingsComponent,
  isActionButtonNavItemConfigType,
  LoginService,
  NavbarUiState,
  NavItemsSectionConfig,
  SystemConfigBlobGuestService,
  SystemSession,
  WebPortal,
} from '@gk/gk-modules';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  takeWhile,
  withLatestFrom,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MainRoutes, PzInitRoutes } from '../../guards/guard.models';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { DomRefService } from '../../services/dom-ref/dom-ref.service';
import { OfficeDepartment } from '../../services/office-departments/office-department.model';
import { OfficeDepartmentsService } from '../../services/office-departments/office-departments.service';
import { SessionService } from '../../services/session/session.service';
import { SettingsService } from '../../services/settings/settings.service';
import { TimerService } from '../../services/timer/timer.service';
import { TranslationVisibilityPortal } from '../../services/web-portal/web-portal.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import {
  accessToInformationOnEnvironmentAndItsProtectionNavSections,
  addressNumberRequestPortalNavSections,
  adjacentPropertyNeedEnterDecisionNavSections,
  agriculturalLandUseRequestNavSections,
  appraiserNavSections,
  AuthDropdownItemId,
  authDropdownNavItemConfig,
  bailiffNavSections,
  buildingOrPartUseChangeNavSections,
  buildingPermitModificationDecisionNavSections,
  buildingPermitNavSections,
  buildingPermitTransferPortalNavSections,
  communalPremisePortalNavSections,
  constructionPortalConstructionLogNavSections,
  constructionPortalConstructionNotificationNavSections,
  constructionPortalDemolitionNotificationNavSections,
  constructionPortalPremiseIndependenceNavSections,
  coordinationMeetingPortalNavSections,
  demolitionPermitNavSections,
  designerLogoImageTextNavItemConfig,
  designerNavSections,
  ecoPortalNavSections,
  editUserActionButton,
  environmentalProtectionPortalFishingCardRequestNavSections,
  environmentalProtectionPortalSimplifiedForestManagementPlanRequestNavSections,
  environmentalProtectionPortalTreesAndShrubsFellingRequestNavSections,
  environmentalProtectionPortalWaterEquipmentRequestNavSections,
  eServicesWebPortalLogoNavItemConfig,
  FontDropdownItemId,
  geodeticAndCartographicServiceDataRequestNavSections,
  getOfficeDepartmentsPortalDepartmentsListNavSections,
  getOfficeDepartmentsPortalRequestsListNavSections,
  getPlanningDepartmentRequestsListNavSections,
  getPrzpNavSections,
  getSpatialInformationPortalRequestsListNavSections,
  grantingRightsNavSections,
  hubNavbarConfig,
  landAndBuildingRegisterDataChangesNotificationRequestNavSections,
  landAndBuildingRegisterDataUpdateRequestNavSections,
  landAndBuildingRegisterDataUpdateUponEntitiesRequestNavSections,
  laneOccupancyBuildingObjectsPortalNavSections,
  laneOccupancyBuildingWorksPortalNavSections,
  laneOccupancyInfrastructureDevicesPortalNavSections,
  noAuthNavSections,
  nonAgriculturalLandUseRequestNavSections,
  notObjectedNotificationRightsObligationsTransferNavSections,
  officeDepartmentsAuthDropdownNavItemConfig,
  officeDepartmentsNavItemConfig,
  ownershipConversionFeeCalculationNavSections,
  ownershipConversionPortalNavSections,
  permanentManagementFeeDiscountDecisionGrantingRequestNavSections,
  planningDepartmentCertificateFromLocalSpatialDevelopmentPlanNavSections,
  planningDepartmentExtractFromLocalSpatialDevelopmentPlanNavSections,
  planningDepartmentExtractFromStudyNavSections,
  plotLandDevArchConstrProjSeparateDecisionNavSections,
  PortalTranslationDropdownItemId,
  presentationCommentsLandBuildingRegistryProjectRequestNavSections,
  propertyLeasePortalNavSections,
  remainingSessionTimeNavItemConfig,
  rightSectionWithoutInfoNavItemConfig,
  roadExitPortalNavSections,
  singleFamilyBuilding70ConstructionConversionNavSections,
  singleFamilyBuildingConstructionConversionNavSections,
  soilClassificationOfLandRequestNavSections,
  spatialInformationNavItemConfig,
  streetNamingPortalNavSections,
  supplementaryParcelPurchasePortalNavSections,
  surveyorNavSections,
  temporaryStructureBuildingPermitNavSections,
  userSettingsActionButton,
} from './dynamic-navbar.configuration';

export enum contrastMode {
  normal = 'normal',
  blackWhite = 'blackWhite',
  yellowBlack = 'yellowBlack',
  blackYellow = 'blackYellow',
}

export enum ContrastModeColor {
  default = '',
  black = 'black',
  white = 'white',
  yellow = '#ffc845',
  lightDark = '#231f20',
  lightYellow = '#ffc845',
}

@Component({
  selector: 'app-navbar-provider',
  templateUrl: './navbar-provider.component.html',
  styleUrls: ['./navbar-provider.component.scss'],
  standalone: false,
})
export class NavbarProviderComponent implements OnInit, OnDestroy {
  private isAlive = true;
  currentNavbarConfig: DynamicNavbarConfig;
  currentPzInitUrl = '';
  systemSession: SystemSession;
  isAuthenticatedOrGuest: boolean;
  isGuest: boolean;
  isPerunAuthenticated: boolean;
  mainPortalData: WebPortal;
  gkDynamicNavbarUiState: NavbarUiState;

  constructor(
    private timerService: TimerService,
    private sessionService: SessionService,
    private appTitleService: AppTitleService,
    private systemConfigBlobGuestService: SystemConfigBlobGuestService,
    private domSanitizer: DomSanitizer,
    private domRefService: DomRefService,
    private router: Router,
    public officeDepartmentsService: OfficeDepartmentsService,
    private colorsService: ColorsService,
    public webPortalService: WebPortalService,
    public gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private defaultConfigService: DefaultConfigService,
    private settingsService: SettingsService,
    @Inject('window') public window: Window,
  ) {}

  ngOnInit(): void {
    this.sessionService.fetchSystemLoggedUserData();
    this.subscribeToSystemSession();
    this.currentNavbarConfig = hubNavbarConfig;
    this.subscribeToRemainingSessionTime();
    this.subscribeToAppTitle();
    this.sessionService.fetchSystemSession();
    this.subscribeToWebHeaderLogo();
    this.subscribeToOfficeDepartments();
    this.restoreContrastModeFromLocalStorage();
    this.subscribeToWebPortalData();
    this.fetchMainPortalData();
    this.handleNavbarAction();
  }

  subscribeToSystemSession(): void {
    this.sessionService.systemSession
      .pipe(
        takeWhile(() => this.isAlive),
        filter((systemSession) => !!systemSession),
      )
      .subscribe((state) => {
        this.systemSession = state;
        this.isAuthenticatedOrGuest =
          this.systemSession.isAuthenticated() || this.systemSession.isGuest;
        this.isGuest = this.systemSession.isGuest;
        this.isPerunAuthenticated = this.systemSession.isPerunAuthenticated();
        this.handleSystemSessionChange();
      });
  }

  subscribeToAppTitle(): void {
    this.appTitleService.currentTitle
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((title) => {
        designerLogoImageTextNavItemConfig.textPart1 = title;
      });
  }

  subscribeToWebHeaderLogo(): void {
    this.systemConfigBlobGuestService
      .getSysConfigWebHeaderLogoData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webHeaderLogoBase64Data) => {
        if (webHeaderLogoBase64Data) {
          eServicesWebPortalLogoNavItemConfig.imgSrc =
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              `data:image/png;base64,${webHeaderLogoBase64Data}`,
            ) as string;
        }
      });
  }

  subscribeToCurrentPortalType(): void {
    this.appTitleService.currentPortalRoute
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalRoute) => this.handlePortalTypeChange(portalRoute));
  }

  hideOfficeDepartmentsHomeButtonWhenOnlyGeodesyDepartment(
    officeDepartments: OfficeDepartment[],
  ): void {
    officeDepartmentsNavItemConfig.show = officeDepartments.length > 1;
  }

  subscribeToOfficeDepartments(): void {
    this.officeDepartmentsService.officeDepartments
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((officeDepartments) =>
        this.hideOfficeDepartmentsHomeButtonWhenOnlyGeodesyDepartment(
          officeDepartments,
        ),
      );
  }

  subscribeToWebPortalData(): void {
    this.webPortalService
      .getWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webPortalData) =>
        this.maybeHideOtherCountiesButton(webPortalData),
      );
  }

  fetchMainPortalData(): void {
    this.webPortalService
      .getMainWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mainWebPortalData) => {
        this.mainPortalData = mainWebPortalData;
        this.subscribeToCurrentPortalType();
        this.maybeChangeOtherDistrictsLabel();
      });
  }

  maybeHideOtherCountiesButton(webPortalData: WebPortal[]): void {
    spatialInformationNavItemConfig.show =
      webPortalData.filter((webPortal) => webPortal.portalId > 99).length > 1;
  }

  maybeChangeOtherDistrictsLabel(): void {
    if (this.checkMainPortalDataIsBielskoOrZywiec()) {
      spatialInformationNavItemConfig.linkLabel =
        'Podbeskidzki Portal Informacji Przestrzennej';
    }
  }

  maybeShowEditUserButton(): void {
    this.defaultConfigService
      .getConfig()
      .pipe(
        takeWhile(() => this.isAlive),
        map((config) => config.isUserEditEnabled),
        filter(Boolean),
      )
      .subscribe(() => {
        editUserActionButton.show = true;
      });
  }

  handlePortalTypeChange(portalRoute: MainRoutes): void {
    switch (portalRoute) {
      case MainRoutes.Designer: {
        hubNavbarConfig.navSections = designerNavSections;
        this.currentPzInitUrl = PzInitRoutes.Designer;

        break;
      }
      case MainRoutes.Przp: {
        this.settingsService
          .getHubSettings()
          .pipe(takeWhile(() => this.isAlive))
          .subscribe((hubSettings) => {
            hubNavbarConfig.navSections = getPrzpNavSections(
              hubSettings.przpAgreementEnabled,
            );
            this.currentPzInitUrl = PzInitRoutes.Przp;
          });

        break;
      }
      case MainRoutes.GrantingRights: {
        hubNavbarConfig.navSections = grantingRightsNavSections;
        this.currentPzInitUrl = PzInitRoutes.GrantingRights;

        break;
      }
      case MainRoutes.EcoPortal: {
        hubNavbarConfig.navSections = ecoPortalNavSections;
        this.currentPzInitUrl = undefined;

        break;
      }
      case MainRoutes.RoadExitPortal: {
        hubNavbarConfig.navSections = roadExitPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.RoadExit;

        break;
      }
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse: {
        hubNavbarConfig.navSections = agriculturalLandUseRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse;

        break;
      }
      case MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse: {
        hubNavbarConfig.navSections = nonAgriculturalLandUseRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse;

        break;
      }
      case MainRoutes.LaneOccupancyPortalInfrastructureDevices: {
        hubNavbarConfig.navSections =
          laneOccupancyInfrastructureDevicesPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.LaneOccupancyInfrastructureDevices;

        break;
      }
      case MainRoutes.LaneOccupancyPortalBuildingObjects: {
        hubNavbarConfig.navSections =
          laneOccupancyBuildingObjectsPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.LaneOccupancyBuildingObjects;

        break;
      }
      case MainRoutes.BuildingPermitTransfer: {
        hubNavbarConfig.navSections = buildingPermitTransferPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.BuildingPermitTransfer;

        break;
      }
      case MainRoutes.PropertyLease: {
        hubNavbarConfig.navSections = propertyLeasePortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.PropertyLease;

        break;
      }
      case MainRoutes.SupplementaryParcelPurchase: {
        hubNavbarConfig.navSections =
          supplementaryParcelPurchasePortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.SupplementaryParcelPurchase;

        break;
      }
      case MainRoutes.LaneOccupancyPortalBuildingWorks: {
        hubNavbarConfig.navSections =
          laneOccupancyBuildingWorksPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.LaneOccupancyBuildingWorks;

        break;
      }
      case MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest: {
        hubNavbarConfig.navSections =
          environmentalProtectionPortalSimplifiedForestManagementPlanRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest;

        break;
      }
      case MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest: {
        hubNavbarConfig.navSections =
          environmentalProtectionPortalTreesAndShrubsFellingRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest;

        break;
      }
      case MainRoutes.EnvironmentalProtectionPortalFishingCardRequest: {
        hubNavbarConfig.navSections =
          environmentalProtectionPortalFishingCardRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.EnvironmentalProtectionPortalFishingCardRequest;

        break;
      }
      case MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest: {
        hubNavbarConfig.navSections =
          environmentalProtectionPortalWaterEquipmentRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest;

        break;
      }
      case MainRoutes.ConstructionPortalConstructionNotificationRequest: {
        hubNavbarConfig.navSections =
          constructionPortalConstructionNotificationNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ConstructionPortalConstructionNotificationRequest;

        break;
      }
      case MainRoutes.ConstructionPortalDemolitionNotificationRequest: {
        hubNavbarConfig.navSections =
          constructionPortalDemolitionNotificationNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ConstructionPortalDemolitionNotificationRequest;

        break;
      }
      case MainRoutes.ConstructionPortalPremiseIndependenceRequest: {
        hubNavbarConfig.navSections =
          constructionPortalPremiseIndependenceNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ConstructionPortalPremiseIndependenceRequest;

        break;
      }
      case MainRoutes.ConstructionPortalConstructionLogRequest: {
        hubNavbarConfig.navSections =
          constructionPortalConstructionLogNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.ConstructionPortalConstructionLogRequest;

        break;
      }
      case MainRoutes.OfficeDepartmentsPortal: {
        this.prepareOfficeDepartmentsNavSection();
        this.currentPzInitUrl = '';

        break;
      }
      case MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan: {
        hubNavbarConfig.navSections =
          planningDepartmentExtractFromLocalSpatialDevelopmentPlanNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest;

        break;
      }
      case MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan: {
        hubNavbarConfig.navSections =
          planningDepartmentCertificateFromLocalSpatialDevelopmentPlanNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest;

        break;
      }
      case MainRoutes.PlanningDepartmentExtractFromStudy: {
        hubNavbarConfig.navSections =
          planningDepartmentExtractFromStudyNavSections;
        this.currentPzInitUrl = PzInitRoutes.PlanningDepartmentExtractFromStudy;

        break;
      }
      case MainRoutes.AddressNumberRequestPortal: {
        hubNavbarConfig.navSections = addressNumberRequestPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.AddressNumberRequestPortal;

        break;
      }
      case MainRoutes.SurveyorPortal: {
        hubNavbarConfig.navSections = surveyorNavSections;
        this.currentPzInitUrl = PzInitRoutes.PzInitSystemUrl;

        break;
      }
      case MainRoutes.AppraiserPortal: {
        hubNavbarConfig.navSections = appraiserNavSections;
        this.currentPzInitUrl = PzInitRoutes.PzInitSystemUrl;

        break;
      }
      case MainRoutes.CoordinationMeetingPortal: {
        hubNavbarConfig.navSections = coordinationMeetingPortalNavSections;

        break;
      }

      case MainRoutes.BailiffPortal: {
        hubNavbarConfig.navSections = bailiffNavSections;
        this.currentPzInitUrl = PzInitRoutes.PzInitSystemUrl;

        break;
      }

      case MainRoutes.SpatialInformationPortal: {
        this.getTranslationForSpatialInformationVisibility()
          .pipe(takeWhile(() => this.isAlive))
          .subscribe((translateVisibilityConfig) => {
            hubNavbarConfig.navSections =
              getSpatialInformationPortalRequestsListNavSections(
                this.checkMainPortalDataIsBielskoOrZywiec(),
                translateVisibilityConfig,
              );
            this.currentPzInitUrl = '';
          });

        break;
      }
      case MainRoutes.OwnershipConversion: {
        hubNavbarConfig.navSections = ownershipConversionPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.OwnershipConversion;

        break;
      }

      case MainRoutes.OwnershipConversionFeeCalculationRequest: {
        hubNavbarConfig.navSections =
          ownershipConversionFeeCalculationNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.OwnershipConversionFeeCalculationRequest;

        break;
      }

      case MainRoutes.CommunalPremisePurchase: {
        hubNavbarConfig.navSections = communalPremisePortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.CommunalPremisePurchase;

        break;
      }

      case MainRoutes.StreetNaming: {
        hubNavbarConfig.navSections = streetNamingPortalNavSections;
        this.currentPzInitUrl = PzInitRoutes.StreetNaming;

        break;
      }

      case MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest: {
        hubNavbarConfig.navSections =
          landAndBuildingRegisterDataChangesNotificationRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.LandAndBuildingRegisterDataChangesNotificationRequest;

        break;
      }

      case MainRoutes.LandAndBuildingRegisterDataUpdateRequest: {
        hubNavbarConfig.navSections =
          landAndBuildingRegisterDataUpdateRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.LandAndBuildingRegisterDataUpdateRequest;

        break;
      }

      case MainRoutes.LandAndBuildingRegisterDataUpdateUponEntitiesRequest: {
        hubNavbarConfig.navSections =
          landAndBuildingRegisterDataUpdateUponEntitiesRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.LandAndBuildingRegisterDataUpdateUponEntitiesRequest;

        break;
      }

      case MainRoutes.PresentationCommentsLandBuildingRegistryProject: {
        hubNavbarConfig.navSections = this.isAuthenticatedOrGuest
          ? presentationCommentsLandBuildingRegistryProjectRequestNavSections
          : noAuthNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.PresentationCommentsLandBuildingRegistryProject;

        break;
      }

      case MainRoutes.AccessToInformationOnEnvironmentAndItsProtection: {
        hubNavbarConfig.navSections =
          accessToInformationOnEnvironmentAndItsProtectionNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.AccessToInformationOnEnvironmentAndItsProtection;

        break;
      }

      case MainRoutes.BuildingPermit: {
        hubNavbarConfig.navSections = buildingPermitNavSections;
        this.currentPzInitUrl = PzInitRoutes.BuildingPermit;

        break;
      }

      case MainRoutes.DemolitionPermit: {
        hubNavbarConfig.navSections = demolitionPermitNavSections;
        this.currentPzInitUrl = PzInitRoutes.DemolitionPermit;

        break;
      }

      case MainRoutes.BuildingOrPartUseChange: {
        hubNavbarConfig.navSections = buildingOrPartUseChangeNavSections;
        this.currentPzInitUrl = PzInitRoutes.BuildingOrPartUseChange;

        break;
      }

      case MainRoutes.TemporaryStructureBuildingPermit: {
        hubNavbarConfig.navSections =
          temporaryStructureBuildingPermitNavSections;
        this.currentPzInitUrl = PzInitRoutes.TemporaryStructureBuildingPermit;

        break;
      }

      case MainRoutes.BuildingPermitModificationDecision: {
        hubNavbarConfig.navSections =
          buildingPermitModificationDecisionNavSections;
        this.currentPzInitUrl = PzInitRoutes.BuildingPermitModificationDecision;

        break;
      }

      case MainRoutes.NotObjectedNotificationRightsObligationsTransfer: {
        hubNavbarConfig.navSections =
          notObjectedNotificationRightsObligationsTransferNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.NotObjectedNotificationRightsObligationsTransfer;

        break;
      }

      case MainRoutes.PlotLandDevArchConstrProjSeparateDecision: {
        hubNavbarConfig.navSections =
          plotLandDevArchConstrProjSeparateDecisionNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.PlotLandDevArchConstrProjSeparateDecision;

        break;
      }

      case MainRoutes.AdjacentPropertyNeedEnterDecision: {
        hubNavbarConfig.navSections =
          adjacentPropertyNeedEnterDecisionNavSections;
        this.currentPzInitUrl = PzInitRoutes.AdjacentPropertyNeedEnterDecision;

        break;
      }

      case MainRoutes.SingleFamilyBuilding70ConstructionConversion: {
        hubNavbarConfig.navSections =
          singleFamilyBuilding70ConstructionConversionNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.SingleFamilyBuilding70ConstructionConversion;

        break;
      }

      case MainRoutes.SingleFamilyBuildingConstructionConversion: {
        hubNavbarConfig.navSections =
          singleFamilyBuildingConstructionConversionNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.SingleFamilyBuildingConstructionConversion;

        break;
      }

      case MainRoutes.GeodeticAndCartographicServiceDataRequest: {
        hubNavbarConfig.navSections =
          geodeticAndCartographicServiceDataRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.GeodeticAndCartographicServiceDataRequest;

        break;
      }

      case MainRoutes.SoilClassificationOfLandRequest: {
        hubNavbarConfig.navSections =
          soilClassificationOfLandRequestNavSections;
        this.currentPzInitUrl = PzInitRoutes.SoilClassificationOfLandRequest;

        break;
      }

      case MainRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest: {
        hubNavbarConfig.navSections =
          permanentManagementFeeDiscountDecisionGrantingRequestNavSections;
        this.currentPzInitUrl =
          PzInitRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest;

        break;
      }
    }

    this.updateNavSectionsForSignInRoute();
  }

  updateNavSectionsForSignInRoute(): void {
    if (this.isSignInRoute()) {
      hubNavbarConfig.navSections[0] = new NavItemsSectionConfig([
        officeDepartmentsNavItemConfig,
      ]);
      hubNavbarConfig.navSections[2] = rightSectionWithoutInfoNavItemConfig;
    }
  }

  handleSystemSessionChange(): void {
    if (this.isPerunAuthenticated) {
      this.showUserSettingsButton();
    }
    if (this.isAuthenticatedOrGuest) {
      this.findProperAuthDropdownNavLabelAndSet();
      authDropdownNavItemConfig.dropdownTitle = 'USER';
    } else {
      this.resetAndHideAuthDropdownNav();
    }
  }

  showUserSettingsButton(): void {
    userSettingsActionButton.show = true;
  }

  resetAndHideAuthDropdownNav(): void {
    this.setAuthDropdownNavLabel('');
    this.setVisibilityOfAuthDropdownNav(false);
    userSettingsActionButton.show = false;
  }

  findProperAuthDropdownNavLabelAndSet(): void {
    if (this.systemSession.isPzAuthenticated()) {
      this.setAuthDropdownNavLabel(this.systemSession.pzLogin);
    } else {
      this.sessionService.loggedUserData
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((loggedUser) => {
          this.setAuthDropdownNavLabel(loggedUser.name);
        });
    }
    this.maybeShowEditUserButton();
  }

  setAuthDropdownNavLabel(label: string): void {
    authDropdownNavItemConfig.dropdownItemConfigs.find(
      (item): item is ActionButtonNavItemConfig =>
        isActionButtonNavItemConfigType(item) &&
        item.navItemId === AuthDropdownItemId.UserInfo,
    ).buttonLabel = label;

    officeDepartmentsAuthDropdownNavItemConfig.dropdownItemConfigs.find(
      (item): item is ActionButtonNavItemConfig => {
        return (
          isActionButtonNavItemConfigType(item) &&
          item.navItemId === AuthDropdownItemId.UserInfo
        );
      },
    ).buttonLabel = label;
  }

  setVisibilityOfAuthDropdownNav(visible: boolean): void {
    authDropdownNavItemConfig.show = visible;
    officeDepartmentsAuthDropdownNavItemConfig.show = visible;
  }

  subscribeToRemainingSessionTime(): void {
    this.timerService.sessionLifetimeString
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (sessionLifetimeString) =>
          (remainingSessionTimeNavItemConfig.text = sessionLifetimeString),
      );
  }

  handleNavbarAction(): void {
    this.gkDynamicNavbarUiStateStoreService.navbarUiState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((navbarUiState) => {
        const navAction = navbarUiState.navAction;

        if (_.includes(FontDropdownItemId, navAction.payload.navItemId)) {
          this.handleFontAction(
            navAction.payload.navItemId as FontDropdownItemId,
          );
        }
        if (_.includes(AuthDropdownItemId, navAction.payload.navItemId)) {
          this.handleAuthAction(
            navAction.payload.navItemId as AuthDropdownItemId,
          );
        }
        if (_.includes(ContrastDropdownItemId, navAction.payload.navItemId)) {
          this.handleContrastAction(
            navAction.payload.navItemId as ContrastDropdownItemId,
          );
        }
      });
  }

  handleFontAction(fontDropdownItemId: FontDropdownItemId): void {
    switch (fontDropdownItemId) {
      case FontDropdownItemId.Default:
        this.changeFont(16);
        break;
      case FontDropdownItemId.Large:
        this.changeFont(18);
        break;
      case FontDropdownItemId.ExtraLarge:
        this.changeFont(22);
        break;
      default:
        throw Error('Font change action not handled!');
    }
  }

  changeFont(size: number): void {
    this.domRefService.nativeDom.querySelector('html').style.fontSize =
      `${size}px`;
  }

  handleAuthAction(authDropdownItemId: AuthDropdownItemId): any {
    switch (authDropdownItemId) {
      case AuthDropdownItemId.Login:
        this.loginService.navigateToExternalLogin(this.currentPzInitUrl);
        break;
      case AuthDropdownItemId.Logout:
        this.sessionService
          .logOffSystemSession()
          .pipe(takeWhile(() => this.isAlive))
          .subscribe(() => {
            this.systemSession = undefined;
            this.isAuthenticatedOrGuest = undefined;
            this.prepareOfficeDepartmentsNavSection();
            this.resetAndHideAuthDropdownNav();
            this.window.location.href = window.location.origin;
          });

        break;
      case AuthDropdownItemId.Edit:
        this.window.open(`/pz/user-form`, '_blank');
        break;
      case AuthDropdownItemId.UserSettings:
        this.modalService.open(GkUserSettingsComponent, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          backdropClass: 'settings-modal-backdrop',
          windowClass: 'settings-modal-window',
        });
        break;
      default:
        throw Error('Auth action not handled!');
    }
  }

  checkMainPortalDataIsBielskoOrZywiec(): boolean {
    const ZYWIEC_WEB_PORTAL_ID = 100;
    const BIELSKO_WEB_PORTAL_ID = 104;

    return this.mainPortalData
      ? this.mainPortalData.portalId === ZYWIEC_WEB_PORTAL_ID ||
          this.mainPortalData.portalId === BIELSKO_WEB_PORTAL_ID
      : false;
  }

  isTranslationExistInPortalByLanguage(
    language: PortalTranslationDropdownItemId,
  ): Observable<boolean> {
    return this.webPortalService.getGeodesyWebPortals().pipe(
      switchMap((webPortals) => {
        switch (language) {
          case PortalTranslationDropdownItemId.EnglishTranslation:
            return of(webPortals.some((portal) => portal.nameEn));
          case PortalTranslationDropdownItemId.GermanTranslation:
            return of(webPortals.some((portal) => portal.nameDe));
          case PortalTranslationDropdownItemId.CzechTranslation:
            return of(webPortals.some((portal) => portal.nameCz));
          default:
            return of(false);
        }
      }),
    );
  }

  isTranslationExistInSpatialInformationPortalByLanguage(
    language: PortalTranslationDropdownItemId,
  ): Observable<boolean> {
    return this.webPortalService.getSpatialInformationWebPortals().pipe(
      switchMap((webPortals) => {
        switch (language) {
          case PortalTranslationDropdownItemId.EnglishTranslation:
            return of(webPortals.some((portal) => portal.descriptionEn));
          case PortalTranslationDropdownItemId.GermanTranslation:
            return of(webPortals.some((portal) => portal.descriptionDe));
          case PortalTranslationDropdownItemId.CzechTranslation:
            return of(webPortals.some((portal) => portal.descriptionCs));
          default:
            return of(false);
        }
      }),
    );
  }

  getTranslationVisibility(): Observable<TranslationVisibilityPortal> {
    return this.isTranslationExistInPortalByLanguage(
      PortalTranslationDropdownItemId.EnglishTranslation,
    ).pipe(
      withLatestFrom(
        this.isTranslationExistInPortalByLanguage(
          PortalTranslationDropdownItemId.GermanTranslation,
        ),
        this.isTranslationExistInPortalByLanguage(
          PortalTranslationDropdownItemId.CzechTranslation,
        ),
      ),
      map(([isVisibleEn, isVisibleGr, isVisibleCz]) => {
        return {
          isVisibleEn,
          isVisibleGr,
          isVisibleCz,
          isVisibleTranslate: isVisibleEn || isVisibleGr || isVisibleCz,
        };
      }),
    );
  }

  getTranslationForSpatialInformationVisibility(): Observable<TranslationVisibilityPortal> {
    return this.isTranslationExistInSpatialInformationPortalByLanguage(
      PortalTranslationDropdownItemId.EnglishTranslation,
    ).pipe(
      withLatestFrom(
        this.isTranslationExistInSpatialInformationPortalByLanguage(
          PortalTranslationDropdownItemId.GermanTranslation,
        ),
        this.isTranslationExistInSpatialInformationPortalByLanguage(
          PortalTranslationDropdownItemId.CzechTranslation,
        ),
      ),
      map(([isVisibleEn, isVisibleGr, isVisibleCz]) => {
        return {
          isVisibleEn,
          isVisibleGr,
          isVisibleCz,
          isVisibleTranslate: isVisibleEn || isVisibleGr || isVisibleCz,
        };
      }),
    );
  }

  prepareOfficeDepartmentsNavSection(): void {
    combineLatest([
      this.systemConfigBlobGuestService.getAccessibilityStatementHtmlContent(),
      this.systemConfigBlobGuestService.getAccessibilityMobileStatementHtmlContent(),
      this.getTranslationVisibility(),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ([desktopStatement, mobilestatement, translateVisibilityConfig]) => {
          if (
            this.router.url.includes(
              `${MainRoutes.OfficeDepartmentsPortal}/department/${DepartmentCode.Planning}`,
            )
          ) {
            hubNavbarConfig.navSections =
              getPlanningDepartmentRequestsListNavSections(
                this.isAuthenticatedOrGuest,
              );
          } else if (
            this.router.url.includes(
              `${MainRoutes.OfficeDepartmentsPortal}/department/`,
            ) ||
            this.router.url.includes(
              `${MainRoutes.OfficeDepartmentsPortal}/requests-list`,
            )
          ) {
            hubNavbarConfig.navSections =
              getOfficeDepartmentsPortalRequestsListNavSections(
                this.checkMainPortalDataIsBielskoOrZywiec(),
                translateVisibilityConfig,
                this.isGuest,
              );
          } else {
            hubNavbarConfig.navSections =
              getOfficeDepartmentsPortalDepartmentsListNavSections(
                this.isAuthenticatedOrGuest,
                this.isGuest,
                !!desktopStatement,
                !!mobilestatement,
              );
          }
        },
      );
  }

  handleContrastAction(contrastDropdownItemId: ContrastDropdownItemId): void {
    switch (contrastDropdownItemId) {
      case ContrastDropdownItemId.DefaultContrast:
        this.setDefaultContrast();
        break;
      case ContrastDropdownItemId.BlackWhiteContrast:
        this.setBlackWhiteContrast();
        break;
      case ContrastDropdownItemId.YellowBlackContrast:
        this.setYellowBlackContrast();
        break;
      case ContrastDropdownItemId.BlackYellowContrast:
        this.setBlackYellowContrast();
        break;
    }
  }

  setDefaultContrast(): void {
    this.changeAccessibilityColor(
      ContrastModeColor.default,
      ContrastModeColor.default,
      ContrastModeColor.default,
    );
    this.colorsService.defaultThemeSelected.next(true);
    localStorage.setItem('selectedContrast', contrastMode.normal);
  }

  setBlackWhiteContrast(): void {
    this.changeAccessibilityColor(
      ContrastModeColor.black,
      ContrastModeColor.white,
      ContrastModeColor.white,
    );
    this.colorsService.defaultThemeSelected.next(false);
    localStorage.setItem('selectedContrast', contrastMode.blackWhite);
  }

  setYellowBlackContrast(): void {
    this.changeAccessibilityColor(
      ContrastModeColor.yellow,
      ContrastModeColor.black,
      ContrastModeColor.black,
    );
    this.colorsService.defaultThemeSelected.next(false);
    localStorage.setItem('selectedContrast', contrastMode.yellowBlack);
  }

  setBlackYellowContrast(): void {
    this.changeAccessibilityColor(
      ContrastModeColor.black,
      ContrastModeColor.yellow,
      ContrastModeColor.yellow,
    );
    this.colorsService.defaultThemeSelected.next(false);
    localStorage.setItem('selectedContrast', contrastMode.blackYellow);
  }

  changeAccessibilityColor(
    primary: string,
    secondary: string,
    slave: string,
  ): void {
    document.documentElement.style.setProperty('--themePrimaryColor', primary);
    document.documentElement.style.setProperty(
      '--themeSecondaryColor',
      secondary,
    );
    document.documentElement.style.setProperty('--themeSlaveColor', slave);
  }

  restoreContrastModeFromLocalStorage(): void {
    const selectedContrast = localStorage.getItem('selectedContrast');
    switch (selectedContrast) {
      case contrastMode.normal:
        this.setDefaultContrast();
        break;
      case contrastMode.blackWhite:
        this.setBlackWhiteContrast();
        break;
      case contrastMode.yellowBlack:
        this.setYellowBlackContrast();
        break;
      case contrastMode.blackYellow:
        this.setBlackYellowContrast();
        break;
      default:
        this.setDefaultContrast();
    }
  }

  isSignInRoute(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.includes('sign-in');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
