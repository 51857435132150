<form
  class="k-form k-form-sm k-form-horizontal"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit()"
>
  <formly-form
    [form]="form"
    [fields]="formlyFormConfig.fields"
    [model]="formModel"
  ></formly-form>
  <gk-kendo-action-buttons
    *ngIf="!!freeServicesRequestSearchFormActionButtonsConfig"
    [buttonsConfigs]="freeServicesRequestSearchFormActionButtonsConfig"
  ></gk-kendo-action-buttons>
</form>
