import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormFieldType, FormFieldWrappers } from '@gk/gk-modules';
import { FreeServicesParcelSearchFormControlName } from './fsp-parcel-search-form.model';

@Injectable({
  providedIn: 'root',
})
export class FspParcelSearchFormService {
  getFormConfig(): {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  } {
    return {
      fields: [
        {
          wrappers: [FormFieldWrappers.FormField],
          key: FreeServicesParcelSearchFormControlName.Number,
          type: FormFieldType.KendoInput,
          props: {
            label: 'FREE_PORTAL_SERVICES.NUMBER',
          },
        },
        {
          wrappers: [FormFieldWrappers.FormField],
          key: FreeServicesParcelSearchFormControlName.Address,
          type: FormFieldType.KendoInput,
          props: {
            label: 'FREE_PORTAL_SERVICES.ADDRESS',
          },
        },
        {
          type: '#communities',
          key: FreeServicesParcelSearchFormControlName.Community,
        },
        {
          type: '#districts',
          key: FreeServicesParcelSearchFormControlName.District,
        },
      ],
    };
  }
}
