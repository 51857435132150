import { Injectable } from '@angular/core';
import { GridDataService } from '../../../../../../gk-kendo';
import { Bdot500DetailsGridDto } from './bdot500-details-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class Bdot500DetailsGridDataService extends GridDataService<Bdot500DetailsGridDto> {
  constructor() {
    super('api/mapa/bdot500/mzObiekty/search', 'POST');
  }
}
