import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemName,
  GridToolBarItem,
  UploadDocTypeName,
} from '@gk/gk-modules';
import { FSPRequestDocumentGridComponent } from './request-document-grid.component';

export const getRequestDocumentGridToolbarItems = (
  c: FSPRequestDocumentGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: GenericGridToolbarItemName.DownloadFile,
      payload: () => ({
        downloadUrl: '/api/interesant/przp/dokprzych/download',
      }),
    },
    {
      name: GenericGridToolbarItemName.AddFile,
      visible: () => c.requestListGridDataSelection?.CanAddAttachment,
      payload: () => ({
        uploadSaveUrl: '/api/interesant/przp/dokprzych/add',
        uploadDocTypeDict: UploadDocTypeName.Other,
        uploadData: {
          OwnerUuid: c.requestListGridDataSelection.ZamowUuid,
        },
      }),
    },
  ];
};

export const requestDocumentGridColumns: ColumnSetting[] = [
  {
    field: 'DataD',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.ADD_DATE',
    type: ColumnType.Date,
    width: 120,
  },
  {
    field: 'Nazwa',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.NAME',
    type: ColumnType.Text,
  },
  {
    field: 'Opis',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.DESCRIPTION',
    type: ColumnType.Text,
  },
  {
    field: 'Rozmiar',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.SIZE',
    type: ColumnType.Text,
    width: 120,
  },
];
