import { Injectable, TemplateRef } from '@angular/core';
import {
  DialogAction,
  DialogCloseResult,
  DialogRef,
  DialogResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import {
  ConfirmationConfig,
  ConfirmationConfigWithKeys,
  DialogConfirmState,
} from '../components/gk-confirm-window/gk-confirm-window.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class DialogManagerService {
  constructor(
    public dialogService: DialogService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
  ) {}

  private translateKey(key: string): Observable<string> {
    return this.translateService.get(key);
  }

  private translateConfirmationConfigKeys(
    config: ConfirmationConfigWithKeys,
  ): Observable<ConfirmationConfig> {
    const titleTranslation$ = this.translateKey(
      config.titleKey || 'CONFIRM_DOWNLOAD.CONFIRM',
    );
    const contentTranslation$ = this.translateKey(
      config.contentKey || 'CONFIRM_DOWNLOAD.ARE_YOU_SURE_TEXT',
    );
    const textYes$ = this.translateKey('CONFIRM_DOWNLOAD.YES');
    const textNo$ = this.translateKey('CONFIRM_DOWNLOAD.NO');
    const textOk$ = this.translateKey('CONFIRM_DOWNLOAD.OK');

    const width = this.deviceService.isMobile() ? '100%' : config.width || 450;

    return forkJoin([
      titleTranslation$,
      contentTranslation$,
      textYes$,
      textNo$,
      textOk$,
    ]).pipe(
      map(([title, content, textYes, textNo, textOk]) => ({
        ...config,
        title,
        content,
        width: width,
        height: config.height || 200,
        minWidth: config.minWidth,
        textYes,
        textNo,
        textOk,
      })),
    );
  }

  public showConfirmation(config: ConfirmationConfigWithKeys): void {
    this.openDialog(config, DialogConfirmState.confirmation);
  }

  public showAlert(config: ConfirmationConfigWithKeys): void {
    this.openDialog(config, DialogConfirmState.alert);
  }

  public showDialogWithComponent(config: ConfirmationConfigWithKeys): void {
    this.openDialog(config, DialogConfirmState.dialogComponent);
  }

  public showConfirmationWithComponent(
    config: ConfirmationConfigWithKeys,
  ): void {
    this.openDialog(config, DialogConfirmState.dialogComponentWithConfirmation);
  }

  private getDialogActions(
    state: DialogConfirmState,
    confirmConfig: ConfirmationConfig,
  ): DialogAction[] {
    switch (state) {
      case DialogConfirmState.alert:
        return [{ text: confirmConfig.textOk, themeColor: 'primary' }];
      case DialogConfirmState.confirmation:
        return [
          { text: confirmConfig.textYes, themeColor: 'primary' },
          { text: confirmConfig.textNo },
        ];
      case DialogConfirmState.dialogComponent:
        return [];
      case DialogConfirmState.dialogComponentWithConfirmation:
        return [
          { text: confirmConfig.textYes, themeColor: 'primary' },
          { text: confirmConfig.textNo },
        ];
      default:
        return [];
    }
  }

  private getContent(
    contentText: string,
    component: string | TemplateRef<any> | Function,
    state: DialogConfirmState,
  ): string | TemplateRef<any> | Function {
    switch (state) {
      case DialogConfirmState.alert:
        return component ? component : contentText;
      case DialogConfirmState.confirmation:
        return contentText;
      case DialogConfirmState.dialogComponent:
        return component;
      case DialogConfirmState.dialogComponentWithConfirmation:
        return component;
      default:
        return contentText;
    }
  }

  private openDialog(
    config: ConfirmationConfigWithKeys,
    state: DialogConfirmState,
  ): void {
    this.translateConfirmationConfigKeys(config).subscribe(
      (translatedConfig: ConfirmationConfig) => {
        const confirmConfig = translatedConfig;
        const dialogRef: DialogRef = this.dialogService.open({
          title: confirmConfig.title,
          content: this.getContent(
            confirmConfig.content,
            config.content,
            state,
          ),
          actions: this.getDialogActions(state, confirmConfig),
          width: confirmConfig.width,
          height: confirmConfig.height,
          minWidth: confirmConfig.minWidth,
          cssClass: this.getCssClass(confirmConfig.cssClass),
        });
        dialogRef.dialog.instance.themeColor = 'primary';

        dialogRef.result.subscribe((result: DialogResult) => {
          this.handleDialogResult(result, confirmConfig);
        });
      },
    );
  }

  private getCssClass(cssClass: string): string {
    return `k-dialog-wrapper-gk-confirm-window ${cssClass}`;
  }

  private handleDialogResult(
    result: DialogResult,
    confirmConfig: ConfirmationConfig,
  ): void {
    if (result instanceof DialogCloseResult) {
      return;
    }

    if ([confirmConfig.textYes, confirmConfig.textOk].includes(result.text)) {
      confirmConfig.confirmSuccessCallback?.();
    } else {
      confirmConfig.confirmCancelCallback?.();
    }
  }
}
