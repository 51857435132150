import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  CellClickEvent,
  GridComponent,
  PagerSettings,
  RowClassFn,
  ScrollMode,
  SelectableSettings,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { FilterableSettings } from '@progress/kendo-angular-grid/filtering/filterable';
import { FeatureCollection } from 'geojson';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import * as _ from 'lodash';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  takeWhile,
  tap,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GkDocumentPreviewComponent } from '../../gk-document-preview/gk-document-preview.component';
import { RequestType } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { RangeTypeValue } from '../../gk-map/configs';
import {
  MapAction,
  MapObjectTableActionType,
  MapPreviewModeState,
  MapViewActionType,
  Wkt,
} from '../../gk-map/models';
import { PolygonTopologyService } from '../../gk-map/services';
import { MapExtentUtils } from '../../gk-map/utils';
import { DownloadService } from '../../services';
import { DomRefService } from '../../services/dom-ref/dom-ref.service';
import { MapSettingsService } from '../../services/map-settings/map-settings.service';
import { BasicResponse } from '../../utils/basic-response/basic-response.model';
import {
  allowedExtensionsToPreview,
  FileExtension,
} from '../../utils/files/files.model';
import { saveFileByTemporaryLinkBase } from '../../utils/utils';
import { BaseDocumentationGridService } from '../base-documentation-grid/base-documentation-grid.service';
import { FileNamingPatternsComponent } from '../components/file-naming-patterns/file-naming-patterns.component';
import { ConfirmationConfigWithKeys } from '../components/gk-confirm-window/gk-confirm-window.model';
import { GkKendoDataBindingDirective } from '../gk-kendo-data-binding.directive';
import { GkKendoUploadService } from '../gk-kendo-upload';
import { DialogManagerService } from '../services/gk-dialog-manager.service';
import { GridDataService } from '../services/grid-data.service';
import { GkKendoWindowService } from '../services/kendo-window/gk-kendo-window/gk-kendo-window.service';
import {
  GkKendoWindowSettings,
  KendoWindowInstanceNames,
} from '../services/kendo-window/kendo-window.model';
import { GkKendoCommunicatorComponent } from './gk-kendo-communicator/gk-kendo-communicator.component';
import { GkKendoDocumentationGridWrapperComponent } from './gk-kendo-documentation-grid/gk-kendo-documentation-grid-wrapper.component';
import { GkKendoGridFooterConfig } from './gk-kendo-grid-footer/gk-kendo-grid-footer.model';
import {
  AbortRequestConfig,
  ColumnSetting,
  ColumnType,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  GenericGridToolbarItemAddFilePayload,
  GenericGridToolbarItemCommunicatorPayload,
  GenericGridToolbarItemDetailsPayload,
  GenericGridToolbarItemDocumentationPayload,
  GenericGridToolbarItemDownloadFilePayload,
  GenericGridToolbarItemMessagesListPayload,
  GenericGridToolbarItemPreviewFilePayload,
  GenericGridToolbarItemRemoveFilePayload,
  GenericGridToolbarItemShowGeometryPayload,
  GenericGridToolbarSaveGeometryInFilePayload,
  GkKendoGridDataResult,
  GkKendoGridFormControlValue,
  GkKendoGridItemKey,
  GridToolBarItem,
  KendoWindowWithItemSelectionCallbackResult,
  MIN_WIDTH_FOR_DATE_COLUMN,
} from './gk-kendo-grid.model';
import { GkKendoMapBase } from './gk-kendo-map-base';
import { GkKendoMessagesListGridDataService } from './gk-kendo-messages-list-grid/gk-kendo-messages-list-grid-data.service';
import { GkKendoMessagesListGridWrapperComponent } from './gk-kendo-messages-list-grid/gk-kendo-messages-list-grid-wrapper.component';

@Component({
  selector: 'gk-kendo-grid',
  templateUrl: './gk-kendo-grid.component.html',
  styleUrls: ['./gk-kendo-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GkKendoGridComponent<T extends object, U = void>
  extends GkKendoMapBase
  implements OnDestroy, AfterViewInit, ControlValueAccessor
{
  @ViewChild(GridComponent) public gridComponent: GridComponent;
  @Input() parentWindowInstanceName: KendoWindowInstanceNames;
  @Input() public selectable: SelectableSettings | boolean = {
    enabled: true,
    mode: 'single',
  };
  @Input() public columns: ColumnSetting[];
  @Input() public topGridToolbarItems: GridToolBarItem[] = [];

  protected selectedKeys: any[] = [];
  protected gkKendoUploadService = inject(GkKendoUploadService);
  protected dialogManagerService = inject(DialogManagerService);
  protected baseDocumentationGridService = inject(BaseDocumentationGridService);
  protected gkKendoWindowService = inject(GkKendoWindowService);
  protected downloadService = inject(DownloadService);
  protected mapSettingsService = inject(MapSettingsService);
  protected translateService = inject(TranslateService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected renderer = inject(Renderer2);
  protected deviceDetectorService = inject(DeviceDetectorService);
  protected polygonTopologyService = inject(PolygonTopologyService);
  protected domRefService = inject(DomRefService);
  protected gkKendoMessagesListGridDataService = inject(
    GkKendoMessagesListGridDataService,
  );
  protected reCaptchaV3Service = inject(ReCaptchaV3Service);
  private sanitizer = inject(DomSanitizer);

  protected columnType = ColumnType;
  protected firstRowSelected = false;
  protected unionResponseWithGridData = false;
  protected navigable = true;
  protected sortable = false;
  protected resizable = true;
  protected rowIndexColumn = false;
  protected pageable: boolean | PagerSettings = false;
  protected scrollable: ScrollMode;
  protected autoBind = false;
  protected pageSize: number;
  protected serverPagingSorting: boolean;
  protected bottomGridToolbarItems: GridToolBarItem[] = [];
  protected selectionKey: GkKendoGridItemKey<T, U>;
  protected footerConfig: GkKendoGridFooterConfig;
  protected height = '100%';
  protected width = '100%';
  protected filterable: FilterableSettings = false;
  protected abortConfig: AbortRequestConfig;

  @ViewChild(GkKendoDataBindingDirective)
  private dataBindingDirective: GkKendoDataBindingDirective<T, U>;
  private isFormControlTouched = false;

  constructor(@Optional() protected gridDataService: GridDataService<T, U>) {
    super();
    if (this.gridDataService) {
      this.gridDataService.componentInstance = this;
    }
  }

  protected get gridDataResult(): GkKendoGridDataResult<T, U> {
    return this.dataBindingDirective.gridDataResult;
  }

  public rebind(): void {
    this.dataBindingDirective.refreshGrid();
  }

  public removeSelectedGridItem(): void {
    this.dataBindingDirective.maybeEmitRemoveEvent();
  }

  public removeAllGridItems(): void {
    this.dataBindingDirective.emitRemoveAllGridItems();
  }

  public showMessageIfNoRowsSelected(): boolean {
    return this.dataBindingDirective.showMessageIfNoRowsSelected();
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  public onSelectionChange(e: SelectionEvent): void {
    if (e.shiftKey) {
      const startIndex = e.rangeStartRow?.index ?? 0;
      const endIndex = e.rangeEndRow.index;

      const normalizedStart = Math.min(startIndex, endIndex);
      const normalizedEnd = Math.max(startIndex, endIndex);
      this.selectedKeys = this.gridDataResult.data
        .slice(normalizedStart, normalizedEnd + 1)
        .map((item) => item[this.selectionKey]);
    } else if (e.ctrlKey) {
      const selectedKey = e?.selectedRows[0]?.dataItem[this.selectionKey];
      const deselectedKey = e?.deselectedRows[0]?.dataItem[this.selectionKey];

      if (selectedKey) {
        this.selectedKeys.push(selectedKey);
      } else {
        this.selectedKeys = this.selectedKeys.filter(
          (key) => key !== deselectedKey,
        );
      }
    }
  }

  public onCellClick(e: CellClickEvent): void {
    const { ctrlKey, metaKey, shiftKey } = e.originalEvent;
    const hasModifier = ctrlKey || metaKey || shiftKey;

    if (!hasModifier) {
      if (this.selectionKey) {
        this.selectedKeys = [e.dataItem[this.selectionKey]];
      } else {
        this.selectedKeys = [e.rowIndex];
      }
    }
  }

  public addFile(payload: GenericGridToolbarItemAddFilePayload): void {
    this.gkKendoUploadService.openUploadWindow({
      parentWindowInstanceName: this.parentWindowInstanceName,
      uploadSaveUrl: payload.uploadSaveUrl,
      uploadDocTypeDict: payload.uploadDocTypeDict,
      uploadData: payload.uploadData,
      onUploadButtonClick: payload.onUploadButtonClick,
      onUploadSuccess: (uploadEvent) => {
        this.rebind();
        payload.onUploadSuccess?.(uploadEvent);
      },
    });
  }

  public removeFile(payload: GenericGridToolbarItemRemoveFilePayload): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const urlStrings = this.matchUrlParamsWithGridSelectedItems(
      payload.removeUrl,
    );
    if (!urlStrings?.length) {
      console.error('No match url params with grid selected item');
      return;
    }

    this.dialogManagerService.showConfirmation({
      contentKey: 'Wybrane elementy zostaną usunięte. Czy chcesz kontynuować?',
      confirmSuccessCallback: (): void => {
        urlStrings.forEach((url) => {
          this.baseDocumentationGridService
            .deleteDocumentation(url)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(() => {
              this.rebind();
            });
        });
      },
    });
  }

  public downloadFile(
    payload: GenericGridToolbarItemDownloadFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const confirmConfig: ConfirmationConfigWithKeys = {
      titleKey: 'CONFIRM_DOWNLOAD.CONFIRM',
      contentKey: 'CONFIRM_DOWNLOAD.CONTENT',
      width: 300,
      height: 150,
      confirmSuccessCallback: () => {
        this.downloadService
          .downloadAndSaveFile(payload.downloadUrl, {
            FileUuids: this.getSelectedFileUuids(),
            KergId: payload.kergId,
            Origin: payload.origin || 'blob',
          })
          .add(() => {
            payload.successCallback?.();
          });
      },
    };
    this.dialogManagerService.showConfirmation(confirmConfig);
  }

  public showNamePatternFilesWindow(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: this.parentWindowInstanceName,
      windowInstanceName: KendoWindowInstanceNames.NamePatternsFile,
      title: 'NAME_PATTERN_FILES.TITLE',
      content: FileNamingPatternsComponent,
      height: 360,
      width: 420,
    });
    windowRef.content.instance.namingPatternsUrlKey =
      '/api/geodeta/slo/operat/kdokrodz';
  }

  public showDocumentPreviewWindow(
    payload: GenericGridToolbarItemPreviewFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const selectedFileUuids = this.getSelectedFileUuids();

    if (!payload.transIds && selectedFileUuids.length > 1) {
      this.showWarnPreviewAvailableForOnlyOneFile();
      return;
    }

    const selectedFileItem = this.gridDataService.$selection.value
      .selectedItems[0] as any;
    const selectedFileItemName = payload?.fileName || selectedFileItem.Nazwa;

    const fileExtension =
      this.getFileExtensionFromFileName(selectedFileItemName);
    if (!this.isAllowedFileExtension(fileExtension)) {
      this.showWarnUnsupportedExtensionToastr();
      return;
    }

    if (payload.transIds) {
      this.handleDocumentPreview(
        payload,
        selectedFileUuids,
        selectedFileItemName,
        fileExtension,
      );

      return;
    }
    this.dialogManagerService.showConfirmation({
      titleKey: 'PREVIEW_DOCUMENT.CONFIRMATION',
      contentKey: 'PREVIEW_DOCUMENT.PREVIEW_IS_EQUAL_DOWNLOAD',
      confirmSuccessCallback: (): void => {
        this.handleDocumentPreview(
          payload,
          selectedFileUuids,
          selectedFileItemName,
          fileExtension,
        );
      },
    });
  }

  public showGeomDxfOnMap(
    payload: GenericGridToolbarItemPreviewFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const selectedFileUuids = this.getSelectedFileUuids();

    if (selectedFileUuids.length > 1) {
      this.showWarnPreviewAvailableForOnlyOneFile();
      return;
    }

    const selectedFileItem = this.gridDataService.$selection.value
      .selectedItems[0] as any;
    const selectedFileItemName = payload?.fileName || selectedFileItem.Nazwa;

    const fileExtension =
      this.getFileExtensionFromFileName(selectedFileItemName);
    if (!this.isDxfExtension(fileExtension)) {
      this.showWarnUnsupportedExtensionToastr(
        'PREVIEW_DOCUMENT.INVALID_DXF_INFO',
      );

      return;
    }

    this.handleDxfPreview(selectedFileUuids);
  }

  public handleDxfPreview(selectedFileUuids: string[]): void {
    this.gridDataService
      .getPostRequest<FeatureCollection>(
        `/api/system/conversion/file/${selectedFileUuids[0]}/dxf/togeojson`,
        undefined,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((response) => {
        if (response) {
          this.showDxfOnMap(response);
        }
      });
  }

  private handleDocumentPreview(
    payload: GenericGridToolbarItemPreviewFilePayload,
    selectedFileUuids: string[],
    selectedFileItemName: string,
    fileExtension: string,
  ): void {
    const windowWidth = 800;
    const windowRef =
      this.gkKendoWindowService.getWindowInstanceByName(
        KendoWindowInstanceNames.DocumentPreview,
      ) ||
      this.gkKendoWindowService.open({
        parent: this,
        parentWindowInstanceName: this.parentWindowInstanceName,
        windowInstanceName: KendoWindowInstanceNames.DocumentPreview,
        title: selectedFileItemName,
        content: GkDocumentPreviewComponent,
        height: 600,
        width: windowWidth,
        left: window.innerWidth - windowWidth,
        cssClass: 'gk-document-preview-window',
      });

    const documentPreviewComponent = windowRef.content
      .instance as GkDocumentPreviewComponent;
    const requestBody = {
      FileUuids: selectedFileUuids[0],
      Podglad: true,
      ...(payload.kergId && { KergId: payload.kergId }),
      ...(payload.transIds && { TransIds: payload.transIds }),
    };

    switch (fileExtension) {
      case FileExtension.Pdf: {
        if (payload.requestType === RequestType.Post) {
          this.downloadService
            .downloadBlob(payload.url, requestBody, payload.requestType)
            .subscribe((file) => {
              documentPreviewComponent.pdfSrc = '';
              setTimeout(() => {
                documentPreviewComponent.pdfSrc = file.body;
              });
            });
        } else {
          this.reCaptchaV3Service
            .execute(payload.url)
            .pipe(
              takeWhile(() => this.isAlive),
              map((reCaptchaToken) => {
                return encodeURIComponent(
                  `${payload.url}?${new URLSearchParams({
                    ...(requestBody as any),
                    ApiToken: reCaptchaToken,
                  })}`,
                );
              }),
            )
            .subscribe((pdfSrc) => {
              documentPreviewComponent.pdfSrc = pdfSrc;
            });
        }
        break;
      }
      case FileExtension.Jpg:
      case FileExtension.Jpeg:
      case FileExtension.Png:
      case FileExtension.Gif:
      case FileExtension.Bmp:
      case FileExtension.Tif:
      case FileExtension.Tiff: {
        this.downloadService
          .downloadBlob(payload.url, requestBody, payload.requestType)
          .subscribe((file) => {
            documentPreviewComponent.imgSrc = URL.createObjectURL(file.body);
          });
        break;
      }
      case FileExtension.Txt: {
        this.downloadService
          .downloadBlob(payload.url, requestBody, payload.requestType)
          .subscribe((file) => {
            const fileReader = new FileReader();
            fileReader.onload = (event): void => {
              documentPreviewComponent.textSrc = event.target.result as string;
            };
            fileReader.readAsText(file.body);
          });
        break;
      }
    }
  }

  public onShowGeometryToolbarButtonClick(
    payload: GenericGridToolbarItemShowGeometryPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    if (payload.gridGeometryFieldName) {
      const selectedItem =
        this.gridDataService.$selection.value.selectedItems[0];
      const wktGeom =
        selectedItem[
          payload.gridGeometryFieldName as keyof typeof selectedItem
        ];

      if (!wktGeom) {
        this.toastr.error(this.translateService.instant('GK.MAP.NO_GEOMETRY'));
        return;
      }

      this.showGeomOnMap(wktGeom as string);
    } else if (payload.url && !payload.ajaxPayload) {
      const url = this.matchUrlParamsWithGridSelectedItems(payload.url)[0];

      this.getWktFromApi(url)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((geom) => {
          this.showGeomOnMap(geom);
        });
    } else if (payload.url && payload.ajaxPayload) {
      this.gridDataService
        .getPostRequest<Wkt[]>(payload.url, payload.ajaxPayload)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((response) => {
          if (response) {
            this.showGeomOnMap(response);
          } else {
            this.showNoGeometryToastr();
          }
        });
    }
  }

  public onDrawGeometryToolbarButtonClick(
    payload: GenericGridToolbarItemShowGeometryPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    if (payload.gridGeometryFieldName) {
      const selectedItem =
        this.gridDataService.$selection.value.selectedItems[0];
      const wktGeom =
        selectedItem[
          payload.gridGeometryFieldName as keyof typeof selectedItem
        ];

      if (!wktGeom) {
        this.toastr.error(this.translateService.instant('GK.MAP.NO_GEOMETRY'));
        return;
      }

      this.drawGeomOnMap(wktGeom as string);
    } else {
      const url = this.matchUrlParamsWithGridSelectedItems(payload.url)[0];

      this.getWktFromApi(url)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((geom) => {
          this.drawGeomOnMap(geom);
        });
    }
  }

  public registerOnChange(onChange: () => void): void {
    this.onFormControlValueChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onFormControlValueTouched = onTouched;
  }

  public writeValue(value: GkKendoGridFormControlValue<T, U>): void {
    if (value?.data) {
      const shouldUnionResponse = this.handleUnionResponseWithGridData();
      this.handleGridFormControlValue(value);
      this.subscribeToGridDataService(shouldUnionResponse); //@todo  fix multiple subscriptions
    }
  }

  public ngAfterViewInit(): void {
    this.subscribeToMapActions(); //@todo fix multiple subscriptions in grid instances
    this.subscribeToGridDataBoundAndSelection();
  }

  public saveRangeToFile(
    rangeType: RangeTypeValue,
    payload: GenericGridToolbarSaveGeometryInFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const geom = this.getRangesWktGeom(payload.gridGeometryFieldName)[0];
    if (!geom) {
      this.toastr.error(this.translateService.instant('GK.MAP.NO_GEOMETRY'));

      return;
    }
    switch (rangeType) {
      case RangeTypeValue.Wkt: {
        this.downloadWkt(geom);
        break;
      }
      case RangeTypeValue.Txt: {
        this.downloadTxt(geom);
        break;
      }
      case RangeTypeValue.Dxf: {
        this.downloadDxf(geom);
        break;
      }
      case RangeTypeValue.Gml: {
        this.downloadGml(geom);
        break;
      }
    }
  }

  public getRangesWktGeom(gridGeometryFieldName = ''): Wkt[] {
    if (gridGeometryFieldName) {
      return this.gridDataResult.data.map(
        (item) => item[gridGeometryFieldName as keyof typeof item],
      ) as Wkt[];
    }

    return undefined;
  }

  public onDetailsToolbarButtonClick(
    payload: GenericGridToolbarItemDetailsPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const windowConfig = {
      parent: this,
      parentWindowInstanceName: this.parentWindowInstanceName,
      windowInstanceName: payload.windowInstanceName,
      title:
        this.handleWindowTitle(payload.windowTitle) ||
        'GK_KENDO_GRID.DETAILS.TITLE',
      content: BaseDetailsComponent,
      width: 520,
      height: 470,
    };

    this.openKendoWindowWithItemSelectionCallback<
      BaseDetailsComponent<unknown>
    >(windowConfig, (windowResultCallback) => {
      if (typeof payload.windowTitle === 'function') {
        windowResultCallback.window.instance.title = this.handleWindowTitle(
          payload.windowTitle,
        );
      }

      windowResultCallback.content.parseCallback = payload.parseCallback;

      if (payload.url) {
        windowResultCallback.content.loadDetailsFromAPI(
          this.matchUrlParamsWithGridSelectedItems(payload.url)[0],
        );
      } else {
        windowResultCallback.content.loadDetailsWithData(
          this.gridDataService.$selection.value.selectedItems[0],
        );
      }
    });
  }

  public openDocumentationWindow(
    payload: GenericGridToolbarItemDocumentationPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const windowConfig = {
      parent: this,
      parentWindowInstanceName: this.parentWindowInstanceName,
      windowInstanceName: payload.windowInstanceName,
      title: payload.windowTitle
        ? payload.windowTitle
        : 'GK_KENDO_GRID.DOCUMENTS.TITLE',
      content: GkKendoDocumentationGridWrapperComponent,
      width: 800,
      height: 250,
    };

    const windowResult =
      this.openKendoWindowWithItemSelectionCallback<GkKendoDocumentationGridWrapperComponent>(
        windowConfig,
        (windowResultCallback) => {
          windowResultCallback.content.loadDocumentationFromAPI(
            this.matchUrlParamsWithGridSelectedItems(payload.url)[0],
          );
        },
      );

    windowResult.content.topGridToolbarItems = payload.gridToolBarItems;
  }

  public openMessagesListWindow(
    payload: GenericGridToolbarItemMessagesListPayload,
  ): void {
    const windowConfig = {
      parent: this,
      parentWindowInstanceName: this.parentWindowInstanceName,
      windowInstanceName: KendoWindowInstanceNames.MessagesList,
      title: 'NEW_MESSAGES_LIST.TITLE',
      content: GkKendoMessagesListGridWrapperComponent,
      width: 500,
      height: 300,
    };

    const windowResult =
      this.openKendoWindowWithItemSelectionCallback<GkKendoMessagesListGridWrapperComponent>(
        windowConfig,
      );
    windowResult.content.payload = payload;
  }

  public openCommunicator(
    payload: GenericGridToolbarItemCommunicatorPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const windowConfig = {
      parent: this,
      parentWindowInstanceName: this.parentWindowInstanceName,
      windowInstanceName: KendoWindowInstanceNames.Communicator,
      title:
        this.handleWindowTitle(payload.windowTitle) || 'COMMUNICATOR.TITLE',
      content: GkKendoCommunicatorComponent,
      width: 800,
      height: 600,
    };

    const windowResult =
      this.openKendoWindowWithItemSelectionCallback<GkKendoCommunicatorComponent>(
        windowConfig,
        (windowResultCallback) => {
          if (typeof payload.windowTitle === 'function') {
            windowResultCallback.window.instance.title = this.handleWindowTitle(
              payload.windowTitle,
            );
          }
          const selectedItem = windowResultCallback.selectedItems[0];
          const communicatorInstance = windowResultCallback.content;

          communicatorInstance.caseId =
            selectedItem[payload.caseIdKey as keyof typeof selectedItem];
          communicatorInstance.communicatorMessagesUrl =
            this.matchUrlParamsWithGridSelectedItems(payload.messagesUrl)[0];
          if (payload.recipientsUrl) {
            communicatorInstance.recipientsUrl =
              this.matchUrlParamsWithGridSelectedItems(
                payload.recipientsUrl,
              )[0];
            communicatorInstance.fetchRecipients();
          }
          communicatorInstance.fetchMessages();
        },
        false,
      );

    windowResult.content.sendMessageUrl = payload.sendMessageUrl;
  }

  protected abortRequest = (): void => {
    this.gridDataService.cancelRequest();
  };

  protected getColumnWidth(
    width: number | undefined,
    type: ColumnType,
  ): number {
    switch (type) {
      case ColumnType.Date:
        return !width || width < MIN_WIDTH_FOR_DATE_COLUMN
          ? MIN_WIDTH_FOR_DATE_COLUMN
          : width;
      default:
        return width || this.getDefaultMobileColumnWidth();
    }
  }

  protected isColumnHidden(columnField: string): Observable<boolean> {
    if (this.gridDataService) {
      return this.gridDataService?.$hiddenColumns.pipe(
        map((hiddenColumns) => hiddenColumns.indexOf(columnField) > -1),
      );
    } else {
      return of(false);
    }
  }

  protected drawGeomOnMap(wktGeom: string | string[]): void {
    const mapObjectsArray = (Array.isArray(wktGeom) ? wktGeom : [wktGeom]).map(
      (wktGeomItem) => ({ geom: wktGeomItem }),
    );
    const geometryExtent =
      MapExtentUtils.getMapExtentFromMapObjects(mapObjectsArray);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(true),
      ),
      // @todo fix geometry style
      // new MapAction(
      //   MapObjectTableActionType.MapGeometryStyleConfigChange,
      //   new MapGeometryStyleConfig(
      //     new MapFeatureStyle(Color.Transparent, Color.GeometryDraw, 2),
      //   ),
      // ),
      new MapAction(MapObjectTableActionType.Select, mapObjectsArray),
      new MapAction(MapViewActionType.ExtentToFitToChange, geometryExtent),
    ]);
  }

  protected markFormControlAsTouched(): void {
    if (!this.isFormControlTouched) {
      if (
        this.onFormControlValueTouched &&
        typeof this.onFormControlValueTouched === 'function'
      ) {
        this.onFormControlValueTouched();
      }
      this.isFormControlTouched = true;
    }
  }

  protected deactivateAllTools(): void {
    const toolTypes = this.getToolTypes(
      this.gkKendoGridMapService.$mapState.value.toolsState,
    );

    toolTypes.forEach((toolType) => {
      this.dispatchToolActivationChange(toolType, false);
      this.deactivateAllSources(toolType);
    });
  }

  protected handleGridToolbarPosition(): 'top' | 'bottom' | 'both' | undefined {
    if (
      this.topGridToolbarItems.length &&
      !this.bottomGridToolbarItems.length
    ) {
      return 'top';
    } else if (
      !this.topGridToolbarItems.length &&
      this.bottomGridToolbarItems.length
    ) {
      return 'bottom';
    } else if (
      this.topGridToolbarItems.length &&
      this.bottomGridToolbarItems.length
    ) {
      return 'both';
    } else {
      return undefined;
    }
  }

  protected checkIfMultipleSelectionMode(): boolean {
    if (this.selectable && typeof this.selectable !== 'boolean') {
      return this.selectable.mode === 'multiple';
    } else {
      return false;
    }
  }

  protected getRowHeight(): number | undefined {
    return this.scrollable === 'virtual' ? 20 : undefined;
  }

  protected rowCallback: RowClassFn = () => undefined;

  private getDefaultMobileColumnWidth(): number {
    if (this.deviceDetectorService.isMobile()) {
      return DEFAULT_MOBILE_COLUMN_WIDTH;
    }

    return undefined;
  }

  private isAllowedFileExtension(fileExtension: string): boolean {
    return allowedExtensionsToPreview.includes(fileExtension as FileExtension);
  }

  private isDxfExtension(fileExtension: string): boolean {
    return FileExtension.Dxf === (fileExtension as FileExtension);
  }

  private showWarnUnsupportedExtensionToastr(
    infoKey = 'PREVIEW_DOCUMENT.INVALID_EXTENSION_INFO',
  ): void {
    this.translateService
      .get(infoKey)
      .subscribe((text) => this.toastr.warning(text));
  }

  private getSelectedFileUuids(): string[] {
    return this.gridDataService.$selection.value.selectedItems.map(
      (selectedItem: { Uuid?: string }) => selectedItem.Uuid,
    );
  }

  private matchUrlParamsWithGridSelectedItems(
    url: string,
  ): string[] | undefined {
    const idFieldPattern = /\{([^{}]+)\}/; // Regular expression to match and extract the value inside {}
    const matches = url.match(idFieldPattern);

    if (!matches) {
      return undefined;
    }

    const selectedItems = this.gridDataService.$selection.value.selectedItems;
    if (selectedItems.length === 0) {
      return undefined;
    }

    const selectedGridItemKey = matches[1];
    if (!this.checkIfKeyExistInSelectedGridItem(selectedGridItemKey)) {
      return undefined;
    }

    return selectedItems.map((selectedItem) =>
      this.interpolateTemplateString(url, {
        [selectedGridItemKey]:
          selectedItem[selectedGridItemKey as keyof typeof selectedItem],
      }),
    );
  }

  private interpolateTemplateString(template: string, args: any): string {
    return Object.entries(args).reduce(
      (result, [arg, val]) => result.replace(`{${arg}}`, `${val}`),
      template,
    );
  }

  private getWktFromApi(url: string): Observable<Wkt | Wkt[]> {
    return this.gridDataService
      .getGetRequest<BasicResponse<Wkt> | Wkt | Wkt[]>(url)
      .pipe(
        map((response) => {
          if (_.isArray(response)) {
            return response;
          } else if (_.isString(response)) {
            return response;
          } else {
            return response?.Result;
          }
        }),
        tap((wkt) => {
          if (!wkt) {
            this.showNoGeometryToastr();
          }
        }),
        filter(Boolean),
      );
  }

  private showNoGeometryToastr(): void {
    this.toastr.warning(
      this.translateService.instant('GK.MAP.NO_GEOM_EXTENT_INFO'),
    );
  }

  private onFormControlValueChange: (
    value: GkKendoGridFormControlValue<T, U>,
  ) => void;

  private onFormControlValueTouched: () => void;

  private handleGridFormControlValue(
    value: GkKendoGridFormControlValue<T, U>,
  ): void {
    const dataAmount = value.data.length;
    const gridData = { data: value.data, total: dataAmount };
    this.gridDataService.next(gridData);
    this.gridDataService.$count.next(dataAmount);
  }

  private showWarnPreviewAvailableForOnlyOneFile(): void {
    this.translateService
      .get('PREVIEW_DOCUMENT.PREVIEW_FOR_ONLY_ONE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.warning(text));
  }

  private subscribeToGridDataBoundAndSelection(): void {
    combineLatest([
      this.gridDataService.$gridDataBound,
      this.gridDataService.$selection,
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([gridItems, selection]) => {
        if (
          typeof this.onFormControlValueChange === 'function' &&
          gridItems.length > 0
        ) {
          this.onFormControlValueChange({
            data: gridItems,
            selection: selection?.selectedItems,
          });
        }
      });
  }

  private getFileExtensionFromFileName(fileName: string): string {
    const parts = fileName.split('.');
    return parts[parts.length - 1].toLowerCase();
  }

  private checkIfKeyExistInSelectedGridItem(
    selectedGridItemKey: string,
  ): boolean {
    if (
      !(
        selectedGridItemKey in
        (this.gridDataService.$selection.value.selectedItems[0] as object)
      )
    ) {
      console.error(
        `No ${selectedGridItemKey} key in `,
        this.gridDataService.$selection.value.selectedItems[0],
      );
      return false;
    } else {
      return true;
    }
  }

  private handleUnionResponseWithGridData(): boolean {
    const shouldUnionResponse =
      this.dataBindingDirective?.unionResponseWithGridData;
    if (shouldUnionResponse) {
      this.dataBindingDirective.unionResponseWithGridData = false;
    }

    return shouldUnionResponse;
  }

  private subscribeToGridDataService(shouldUnionResponse: boolean): void {
    this.gridDataService.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      if (shouldUnionResponse) {
        this.dataBindingDirective.unionResponseWithGridData =
          shouldUnionResponse;
      }
    });
  }

  private downloadWkt(geom: string): void {
    saveFileByTemporaryLinkBase(
      this.domRefService.nativeDom,
      geom,
      this.translateService.instant('GK.MAP.WKT_FILE_NAME'),
    );
  }

  private downloadTxt(geom: string): void {
    this.polygonTopologyService
      .convertRangeToTxt(geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        saveFileByTemporaryLinkBase(
          this.domRefService.nativeDom,
          data,
          this.translateService.instant('GK.MAP.TXT_FILE_NAME'),
        );
      });
  }

  private downloadDxf(geom: string): void {
    this.polygonTopologyService
      .convertRangeToDxf(geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        saveFileByTemporaryLinkBase(
          this.domRefService.nativeDom,
          data,
          this.translateService.instant('GK.MAP.DXF_FILE_NAME'),
        );
      });
  }

  private downloadGml(geom: string): void {
    this.polygonTopologyService
      .convertRangeToGml(geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        saveFileByTemporaryLinkBase(
          this.domRefService.nativeDom,
          data,
          this.translateService.instant('GK.MAP.GML_FILE_NAME'),
        );
      });
  }

  private openKendoWindowWithItemSelectionCallback<C>(
    gkKendoWindowSettings: GkKendoWindowSettings,
    callback?: (
      windowResult: KendoWindowWithItemSelectionCallbackResult<C, T, U>,
    ) => void,
    autoClose = true,
  ): KendoWindowWithItemSelectionCallbackResult<C, T, U> {
    const windowRef = this.gkKendoWindowService.open(gkKendoWindowSettings);
    let selectionData = this.gridDataService.$selection.value;
    const windowResult = {
      window: windowRef.window,
      content: windowRef.content.instance as C,
      selectedItems: selectionData.selectedItems,
    };

    if (callback) {
      this.gridDataService.$selection
        .pipe(takeUntil(windowRef.result))
        .subscribe((selection) => {
          if (selection?.selectedItems?.length) {
            selectionData = selection;
            callback(windowResult);
          } else {
            if (autoClose) {
              windowRef.close();
            }
          }
        });
    }

    return windowResult;
  }

  private handleWindowTitle(
    windowTitle: string | (() => string) | undefined,
  ): string | undefined {
    if (typeof windowTitle === 'function') {
      return windowTitle();
    }
    return windowTitle;
  }

  protected getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
