<kendo-splitter orientation="vertical" class="h-100">
  <kendo-splitter-pane size="55%">
    <app-fsp-premisses-grid> </app-fsp-premisses-grid>
  </kendo-splitter-pane>
  <kendo-splitter-pane>
    <kendo-tabstrip class="h-100" [keepTabContent]="true">
      <kendo-tabstrip-tab
        title="{{ 'FREE_PORTAL_SERVICES.DOCS' | translate }}"
        [selected]="true"
      >
        <ng-template kendoTabContent>
          <app-fsp-premisses-documents-grid></app-fsp-premisses-documents-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="{{ 'FREE_PORTAL_SERVICES.OWNERS' | translate }}"
      >
        <ng-template kendoTabContent>
          <app-fsp-premisses-owners-grid></app-fsp-premisses-owners-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      )
    </kendo-tabstrip>
  </kendo-splitter-pane>
</kendo-splitter>
