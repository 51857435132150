import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ConfirmationConfigWithKeys,
  GkKendoGridComponent,
  GkKendoGridFooterConfig,
  KendoWindowInstanceNames,
} from '@gk/gk-modules';
import { FreeServicesRequestListGridDataService } from './free-services-request-list-grid-data.service';
import { FreeServicesRequestListGridDto } from './free-services-request-list-grid-data-model';
import {
  getFreeServicesRequestListGridToolbarItems,
  freeServicesRequestListGridColumns,
} from './free-services-request-list-grid-model';
import { RowClassFn, SelectableSettings } from '@progress/kendo-angular-grid';
import { FreeServicesRequestSearchFormComponent } from '../free-services-request-search-form/free-services-request-search-form.component';
import { FreeServicesRequestDetailsComponent } from '../free-services-request-details/free-services-request-details.component';
import { takeWhile } from 'rxjs/operators';
import { filter } from 'rxjs';
import { RequestStatuses } from '../../../appraiser-portal/request-list/request-list-grid/request-list-grid-data-model';
import { FspParcelsComponent } from '../free-services-parcel-request-grid/fsp-parcels.component';
import * as _ from 'lodash';
import {
  FreeServicesRequestSearchFormModel,
  freeServicesRequestSearchFormModel,
} from '../free-services-request-search-form/free-services-request-search-form.model';

@Component({
  selector: 'app-free-services-request-list-grid',
  templateUrl:
    '../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  styleUrl: './free-services-request-list-grid.component.scss',
  standalone: false,
  encapsulation: ViewEncapsulation.None,
})
export class FreeServicesRequestListGridComponent
  extends GkKendoGridComponent<FreeServicesRequestListGridDto>
  implements OnInit, AfterViewInit
{
  constructor(
    public freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {
    super(freeServicesRequestListGridDataService);
  }

  fspRequestSearchFormModel = _.cloneDeep(freeServicesRequestSearchFormModel);
  override topGridToolbarItems =
    getFreeServicesRequestListGridToolbarItems(this);
  override columns = freeServicesRequestListGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  override serverPagingSorting = true;
  override pageable = {
    pageSizes: [20, 50, 100],
  };
  override pageSize = 50;
  override footerConfig: GkKendoGridFooterConfig = {
    legend: [
      {
        text: 'FREE_PORTAL_SERVICES.LEGEND.CANCELED',
        class: 'archival-parcel-for-work',
      },
      {
        text: 'FREE_PORTAL_SERVICES.LEGEND.EXPIRED',
        class: 'current-parcel-for-work',
      },
    ],
  };
  override rowCallback: RowClassFn = (context) => {
    if (!context.dataItem.StatusActive) {
      return { 'request-cancelled-rejected': true };
    }
    if (!context.dataItem.CzasDostepuWgladActive) {
      return { 'request-expired': true };
    }

    return undefined;
  };
  isDisableCancelRequest = true;
  selectedRequest: FreeServicesRequestListGridDto;
  parcelRequestTitle: string;
  displayRequestArchive = false;

  ngOnInit(): void {
    this.handleRequestListGridSelected();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this['dataBindingDirective'].rebind = (): void => {
      this.toggleDataByRequestArchive();
    };
    this.toggleDataByRequestArchive();
  }

  fetchParcelsTitleTranslations(): void {
    this.translateService
      .get('FREE_PORTAL_SERVICES.PARCELS_FOR_REQUEST', {
        signature: this.selectedRequest?.Numer,
      })
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((title) => {
        this.parcelRequestTitle = title;
      });
  }

  openSearchRequestWindow(): void {
    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPRequestList,
      windowInstanceName: KendoWindowInstanceNames.FSPRequestSearch,
      title: 'FREE_PORTAL_SERVICES.SEARCH_BY_ATTR',
      content: FreeServicesRequestSearchFormComponent,
      width: 440,
    });
  }

  showDetailsWindow(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPRequestList,
      windowInstanceName: KendoWindowInstanceNames.FSPRequestDetails,
      title: 'Szczegóły zamówienia',
      content: FreeServicesRequestDetailsComponent,
      width: 580,
      height: 160,
    });
  }

  openParcelsForRequestListWindow(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPRequestList,
      windowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      title: this.parcelRequestTitle,
      content: FspParcelsComponent,
      width: 760,
      height: 540,
    });
  }

  handleRequestListGridSelected(): void {
    this.freeServicesRequestListGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedRequest = selection.selectedItems[0];
        this.isDisableCancelRequest =
          selection.selectedItems[0].Status !== RequestStatuses.Registered;
        this.fetchParcelsTitleTranslations();
      });
  }

  showConfirmationCancelRequest(): void {
    const confirmConfig: ConfirmationConfigWithKeys = {
      contentKey: 'M_APPRAISER.REQUESTS.CANCEL_REQUEST_CONFIRM',
      width: 300,
      height: 150,
      confirmSuccessCallback: (): void => {
        this.freeServicesRequestListGridDataService
          .cancelFreeServicesRequest(this.selectedRequest.ZamowUuid)
          .subscribe(() => {
            this.freeServicesRequestListGridDataService.rebind();
            this.toastr.success(
              `${this.translateService.instant(
                'M_APPRAISER.REQUESTS.CANCELLED_REQUEST',
              )} ${this.selectedRequest.Numer}`,
            );
          });
      },
    };
    this.dialogManagerService.showConfirmation(confirmConfig);
  }

  handleCancelRequest(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.showConfirmationCancelRequest();
  }

  onDisplayRequestArchiveChange(evt: Event): void {
    this.displayRequestArchive = evt as unknown as boolean;
    this.toggleDataByRequestArchive();
  }

  toggleDataByRequestArchive(): void {
    this.freeServicesRequestListGridDataService
      .queryByState({
        filter: FreeServicesRequestSearchFormModel.fromAppToApi(
          this.fspRequestSearchFormModel,
          !this.displayRequestArchive,
        ),
      })
      .subscribe();
  }
}
