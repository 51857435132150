<div kendoWindowContainer></div>
<div kendoDialogContainer></div>
@if (currentPortalRoute | async; as portal) {
  <ng-container
    gkTermsOfUseInitializer
    [currentPortalId]="portal?.portalId"
    [currentPortalName]="portal?.name"
  ></ng-container>
}

<router-outlet></router-outlet>
