import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { BuildingDetailsDto } from '../../../map-building-details/map-building-details.model';
import { BuildingDetailsGridDataService } from './building-details-grid-data.service';
import {
  buildingDetailsGridColumns,
  getBuildingDetailsGridToolbarItems,
} from './building-details-grid-model';

@Component({
  selector: 'gk-building-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class BuildingDetailsGridComponent extends GkKendoGridComponent<BuildingDetailsDto> {
  constructor(
    private buildingDetailsGridDataService: BuildingDetailsGridDataService,
  ) {
    super(buildingDetailsGridDataService);
  }

  override topGridToolbarItems = getBuildingDetailsGridToolbarItems(this);
  override columns = buildingDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  protected override firstRowSelected = true;
  private requestQuantity = 50;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);

    this.buildingDetailsGridDataService
      .queryByState(payload)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): State {
    return {
      filter: {
        filters: [{ field: 'Geom', operator: 'intersects', value: geometry }],
        logic: 'and',
      },
      take: this.requestQuantity,
    };
  }
}
