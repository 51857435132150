import { AfterViewInit, Component } from '@angular/core';
import { GkKendoGridComponent } from '@gk/gk-modules';
import { FspBorderPointsGridDataService } from './fsp-border-points-grid-data.service';
import { FspBorderPointsGridDto } from './fsp-border-points-grid-data-model';
import {
  getFspBorderPointsGridToolbarItems,
  fspBorderPointsGridColumns,
} from './fsp-border-points-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesParcelRequestGridDto } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data-model';
import { FreeServicesParcelRequestGridDataService } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';

@Component({
  selector: 'app-fsp-border-points-grid',
  templateUrl:
    '../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspBorderPointsGridComponent
  extends GkKendoGridComponent<FspBorderPointsGridDto>
  implements AfterViewInit
{
  constructor(
    public fspBorderPointsGridDataService: FspBorderPointsGridDataService,
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
  ) {
    super(fspBorderPointsGridDataService);
  }

  override topGridToolbarItems = getFspBorderPointsGridToolbarItems();
  override columns = fspBorderPointsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  selectedParcelGridItem: FreeServicesParcelRequestGridDto;

  override ngAfterViewInit(): void {
    this.subscribeToRequestListGridSelectedKeys();
  }

  subscribeToRequestListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelGridItem = selection.selectedRows[0].dataItem;
        this.fetchGridData();
      });
  }

  fetchGridData(): void {
    this.fspBorderPointsGridDataService.$loading.next(true);
    this.freeServicesParcelRequestGridDataService
      .fetchParcelDetailsFromApi(this.selectedParcelGridItem.KdzialkaUuid)
      .subscribe((data) => {
        this.fspBorderPointsGridDataService.$loading.next(false);
        this.fspBorderPointsGridDataService.next({
          data: data.PunktyGraniczne,
          total: data.PunktyGraniczne.length,
        });
      });
  }
}
