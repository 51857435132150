import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import {
  iwIconForward,
  iwIconUndo2,
} from '../../../assets/css/ewid-svg/svg-icons';
import { GridDataService } from '../../services/grid-data.service';
import { GkKendoGridComponent } from '../gk-kendo-grid.component';
import {
  GkKendoGridItem,
  GridToolBarItem,
  GridToolBarItemType,
} from '../gk-kendo-grid.model';

@Component({
  selector: 'gk-gk-kendo-grid-with-details',
  templateUrl: './gk-kendo-grid-with-details.component.html',
  standalone: false,
})
export class GkKendoGridWithDetailsComponent<T extends object, U = void>
  implements OnInit, OnDestroy
{
  protected gridComponent: Type<GkKendoGridComponent<T, U>>;
  protected detailsComponent: Type<BaseDetailsComponent<any>>;
  protected selectionTextKey = 'SELECTION_TEXT';
  protected noRecordsTextKey = 'NO_RECORDS_TEXT_KEY';

  protected gridToolbarOverflow = false;
  protected isAlive = true;
  protected selectionText = '';

  protected disabledNavigation = false;
  protected handleSelectedEnabled = false;

  constructor(
    protected gridDataService: GridDataService<T, U>,
    protected translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscribeToSelectionChanges();
    this.subscribeToCountChanges();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.clearSelection();
  }

  public subscribeToSelectionChanges(): void {
    this.gridDataService.$selectedKeys.next([]);
    this.gridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItem = selection?.selectedItems[0];
        if (!selectedItem) return;

        setTimeout(() => {
          this.updateSelectionText();
        });

        if (this.handleSelectedEnabled) {
          this.handleLandSelected(selectedItem);
        }
      });
  }

  public subscribeToCountChanges(): void {
    this.gridDataService.$count
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((count) => {
        this.disabledNavigation = count <= 1;
        this.updateSelectionText();
      });
  }

  protected updateSelectionText(): void {
    const totalCount = this.gridDataService.$count.getValue();
    const selectedNumberFormatted = (
      this.getCurrentSelectionIndex() + 1
    ).toString();

    const textKey =
      totalCount === 0 ? this.noRecordsTextKey : this.selectionTextKey;

    this.translateService
      .get(textKey, {
        selectedNumber: selectedNumberFormatted,
        totalCount: totalCount,
      })
      .subscribe((translatedText: string) => {
        this.selectionText = translatedText;
      });
  }

  protected handleLandSelected(gridItem: GkKendoGridItem<T, U>): void {
    console.warn(`${gridItem} handleLandSelected is not implemented`);
  }

  private clearSelection(): void {
    this.gridDataService.$selection.next({
      selectedItems: [],
      selectedKeys: [],
      selectedRows: [],
    });
    this.gridDataService.$count.next(0);
  }

  private getCurrentSelectionIndex(): number {
    const selectedKey =
      this.gridDataService.$selection.getValue()?.selectedKeys[0];
    return selectedKey ? Number(selectedKey) : 0;
  }

  private selectPreviousPosition(): void {
    const previousIndex = this.getCurrentSelectionIndex() - 1;
    if (previousIndex >= 0) {
      this.gridDataService.$selectedKeys.next([previousIndex]);
    }
  }

  private selectNextPosition(): void {
    const nextIndex = this.getCurrentSelectionIndex() + 1;
    if (nextIndex < this.gridDataService.$count.getValue()) {
      this.gridDataService.$selectedKeys.next([nextIndex]);
    }
  }

  getToolbarItems(): GridToolBarItem[] {
    return [
      {
        name: 'previous',
        svgIcon: iwIconUndo2,
        className: 'svg-icon-blue',
        callback: () => this.selectPreviousPosition(),
        disabled: this.disabledNavigation,
      },
      {
        name: 'currentChoosedItem',
        text: this.selectionText,
        type: GridToolBarItemType.Text,
      },
      {
        name: 'next',
        svgIcon: iwIconForward,
        className: 'svg-icon-blue',
        callback: () => this.selectNextPosition(),
        disabled: this.disabledNavigation,
      },
    ];
  }
}
