import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';
import { StreetAxisDetailsGridDto } from '../street-axis-details-grid/street-axis-details-grid-data-model';
export class StreetAxisDetails {
  static fromApiToApp(data: StreetAxisDetailsGridDto): BaseDetailSettings[] {
    return [
      {
        title: 'STREET_AXIS_DETAILS.CITY',
        value: data?.Miejscowosc,
        type: DetailType.Text,
      },
      {
        title: 'STREET_AXIS_DETAILS.STREET',
        value: data?.Ulica,
        type: DetailType.Text,
      },
      {
        title: 'STREET_AXIS_DETAILS.PARCELS',
        value: data?.Dzialki,
        type: DetailType.Text,
      },
    ];
  }
}
