import { Component } from '@angular/core';
import { FspParcelSearchFormService } from './fsp-parcel-search-form.service';
import { FormGroup } from '@angular/forms';
import {
  freeServicesParcelSearchFormActionButtonsConfig,
  freeServicesParcelSearchFormModel,
  FspParcelSearchFormModel,
} from './fsp-parcel-search-form.model';
import { GkWindowRef } from '@gk/gk-modules';
import * as _ from 'lodash';
import { FreeServicesParcelRequestGridDataService } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';
import { FreeServicesRequestListGridDataService } from '../../free-services-request-list-grid/free-services-request-list-grid-data.service';

@Component({
  selector: 'app-free-services-parcel-search-form',
  templateUrl: './fsp-parcel-search-form.component.html',
  standalone: false,
})
export class FreeServicesParcelSearchFormComponent {
  windowRef: GkWindowRef;
  form = new FormGroup({});
  formlyFormConfig = this.fspParcelSearchFormService.getFormConfig();
  formModel = _.cloneDeep(freeServicesParcelSearchFormModel);
  freeServicesParcelSearchFormActionButtonsConfig =
    freeServicesParcelSearchFormActionButtonsConfig;
  constructor(
    private fspParcelSearchFormService: FspParcelSearchFormService,
    private freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
    private freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {}

  clearForm(): void {
    this.form.reset();
  }

  onFormSubmit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this.freeServicesParcelRequestGridDataService
        .queryByState(
          {
            filter: FspParcelSearchFormModel.fromAppToApi(
              this.formModel,
              this.freeServicesRequestListGridDataService.$selection.value
                .selectedItems[0].ZamowUuid,
            ),
          },
          true,
          '/api/interesant/przp/dzialki/search',
        )
        .subscribe(() => {
          this.windowRef.close();
        });
    }
  }

  onCancel(): void {
    this.windowRef.close();
  }
}
