import { Injectable } from '@angular/core';
import { GridDataService } from '../../../../../../gk-kendo';
import { StreetAxisDetailsGridDto } from './street-axis-details-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class StreetAxisDetailsGridDataService extends GridDataService<StreetAxisDetailsGridDto> {
  constructor() {
    super('/api/mapa/osulicy/szczegoly/bygeom', 'POST');
  }
}
