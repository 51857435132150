import {
  AnyGeometryObject,
  GridActionButtonsConfig,
  iwIconCheckmark,
  iwIconCross,
} from '@gk/gk-modules';
import { FreeServicesRequestSearchFormComponent } from './free-services-request-search-form.component';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { FilterOperator } from '@progress/kendo-data-query/dist/npm/filtering/operators.enum';

export enum FreeServicesRequestSearchFormControlName {
  OfficeNumber = 'officeNumber',
  StartDateFrom = 'startDateFrom',
  StartDateTo = 'startDateTo',
  ExecDateFrom = 'execDateFrom',
  ExecDateTo = 'execDateTo',
  HasDocumentation = 'hasDocumentation',
  Status = 'status',
  SelectPoint = 'selectPoint',
}
export class FreeServicesRequestSearchFormModel {
  constructor(
    public officeNumber: string,
    public startDateFrom: string,
    public startDateTo: string,
    public execDateFrom: string,
    public execDateTo: string,
    public status: string,
    public hasDocumentation: string,
    public selectPoint: AnyGeometryObject,
  ) {}

  static getEmptyModel(): FreeServicesRequestSearchFormModel {
    return new this(null, null, null, null, null, null, null, null);
  }

  static fromAppToApi(
    data: FreeServicesRequestSearchFormModel,
    displayRequestArchive: boolean,
  ): CompositeFilterDescriptor {
    const filters = [
      {
        field: FreeServicesRequestSearchApiFields.Number,
        operator: FilterOperator.Contains,
        value: data.officeNumber,
        ignoreCase: true,
      },
      {
        field: FreeServicesRequestSearchApiFields.StartDate,
        operator: FilterOperator.GreaterThanOrEqual,
        value: data.startDateFrom,
      },
      {
        field: FreeServicesRequestSearchApiFields.StartDate,
        operator: FilterOperator.LessThanOrEqual,
        value: data.startDateTo,
      },
      {
        field: FreeServicesRequestSearchApiFields.Status,
        operator: FilterOperator.Contains,
        value: data.status,
        ignoreCase: true,
      },
      {
        field: FreeServicesRequestSearchApiFields.ExecDate,
        operator: FilterOperator.GreaterThanOrEqual,
        value: data.execDateFrom,
      },
      {
        field: FreeServicesRequestSearchApiFields.ExecDate,
        operator: FilterOperator.LessThanOrEqual,
        value: data.execDateTo,
      },
      {
        field: FreeServicesRequestSearchApiFields.Docs,
        operator: FilterOperator.EqualTo,
        value: data.hasDocumentation,
      },
      {
        field: FreeServicesRequestSearchApiFields.SelectRange,
        operator: 'Intersects',
        value: data.selectPoint?.geom,
      },
      ...(displayRequestArchive
        ? [
            {
              field: FreeServicesRequestSearchApiFields.TimeActive,
              operator: FilterOperator.EqualTo,
              value: true,
            },
            {
              field: FreeServicesRequestSearchApiFields.StatusActive,
              operator: FilterOperator.EqualTo,
              value: true,
            },
          ]
        : []),
    ];

    return {
      logic: 'and',
      filters: filters.filter(
        (element) => element.value !== undefined && element.value !== null,
      ),
    };
  }
}

export enum FreeServicesRequestSearchApiFields {
  Number = 'Numer',
  StartDate = 'DataRozpocz',
  Status = 'Status',
  ExecDate = 'DataZalatw',
  Docs = 'Dokumenty',
  TimeActive = 'CzasDostepuWgladActive',
  StatusActive = 'PrzpStatusActive',
  SelectRange = 'PrzpGeom',
}

export const freeServicesRequestSearchFormModel =
  FreeServicesRequestSearchFormModel.getEmptyModel();

export const freeServicesRequestSearchFormActionButtonsConfig: GridActionButtonsConfig =
  {
    actionsLayout: 'end',
    buttons: [
      {
        text: 'CLEAR_FILTER',
        iconClass: 'fa fa-eraser',
        click: () => FreeServicesRequestSearchFormComponent.prototype.clearForm,
      },
      {
        text: 'APPLY_FILTER',
        type: 'submit',
        svgIcon: iwIconCheckmark,
        className: 'svg-icon-color-info',
      },
      {
        text: 'DISMISS',
        svgIcon: iwIconCross,
        className: 'svg-icon-red',
        click: () => FreeServicesRequestSearchFormComponent.prototype.onCancel,
      },
    ],
  };
