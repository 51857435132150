import {
  iwIconDrawPoint,
  iwIconPodgladMapa,
  iwIconRysujWielobok,
} from '../../../../../assets/css/ewid-svg/svg-icons';
import {
  ColumnSetting,
  ColumnType,
  GenericToolbarItemName,
  GridToolBarItem,
} from '../../../../../gk-kendo';
import { AnyGeometryObject } from '../../../../models';
import { LandDetailsGridComponent } from './map-land-details-grid.component';

export const getLandDetailsGridToolbarItems = (
  c: LandDetailsGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: GenericToolbarItemName.GetPointGeometryFromMap,
      svgIcon: iwIconPodgladMapa,
      className: 'svg-icon-blue',
      title: 'LAND_DETAILS.ADD_LAND_FROM_MAP',
      text: 'LAND_DETAILS.ADD_LAND_FROM_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetPolygonGeometryFromMap,
      svgIcon: iwIconRysujWielobok,
      className: 'svg-icon-blue',
      title: 'LAND_DETAILS.SEARCH_BY_MARK_RANGE',
      text: 'LAND_DETAILS.SEARCH_BY_MARK_RANGE',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetLineGeometryFromMap,
      svgIcon: iwIconDrawPoint,
      className: 'svg-icon-blue',
      title: 'LAND_DETAILS.DRAW_LINE_ON_MAP',
      text: 'LAND_DETAILS.DRAW_LINE_ON_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
  ];
};

export const landDetailsGridColumns: ColumnSetting[] = [
  {
    field: 'Id',
    title: 'LAND_DETAILS.LANDS',
    type: ColumnType.Text,
  },
];
