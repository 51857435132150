import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoGridWithDetailsComponent } from '../../../../../gk-kendo';
import { StreetAxisDetailsDetailsComponent } from './street-axis-details-details/street-axis-details-details.component';
import { StreetAxisDetailsGridDto } from './street-axis-details-grid/street-axis-details-grid-data-model';
import { StreetAxisDetailsGridDataService } from './street-axis-details-grid/street-axis-details-grid-data.service';
import { StreetAxisDetailsGridComponent } from './street-axis-details-grid/street-axis-details-grid.component';

@Component({
  selector: 'gk-street-axis-details-wrapper',
  standalone: false,
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component.html',
})
export class StreetAxisDetailsWrapperComponent extends GkKendoGridWithDetailsComponent<StreetAxisDetailsGridDto> {
  override gridComponent = StreetAxisDetailsGridComponent;
  override detailsComponent = StreetAxisDetailsDetailsComponent;
  protected override selectionTextKey = 'STREET_AXIS_DETAILS.SELECTION_TEXT';
  protected override noRecordsTextKey = 'STREET_AXIS_DETAILS.NO_RECORDS_TEXT';

  constructor(
    streetAxisDetailsGridDataService: StreetAxisDetailsGridDataService,
    translateService: TranslateService,
  ) {
    super(streetAxisDetailsGridDataService, translateService);
  }
}
