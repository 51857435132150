import { Component, OnInit } from '@angular/core';
import { GkKendoGridComponent } from '@gk/gk-modules';
import { FspPremissesDocsGridDataService } from './fsp-premisses-docs-grid-data.service';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import {
  apDocumentsRequestGridColumns,
  getFspDocumentsRequestGridToolbarItems,
} from '../../../free-services-documents-grid/fsp-documents-grid-model';
import { FspDocumentsRequestGridDto } from '../../../free-services-documents-grid/fsp-documents-grid-data-model';
import { FspPremissesGridDataService } from '../fsp-premisses-grid/fsp-premisses-grid-data.service';

@Component({
  selector: 'app-fsp-premisses-documents-grid',
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspPremissesDocsGridComponent
  extends GkKendoGridComponent<FspDocumentsRequestGridDto>
  implements OnInit
{
  constructor(
    private fspPremissesDocsGridDataService: FspPremissesDocsGridDataService,
    private fspPremissesGridDataService: FspPremissesGridDataService,
  ) {
    super(fspPremissesDocsGridDataService);
  }

  override topGridToolbarItems = getFspDocumentsRequestGridToolbarItems();
  override columns = apDocumentsRequestGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  ngOnInit(): void {
    this.subscribePremissesGridSelected();
  }

  subscribePremissesGridSelected(): void {
    this.fspPremissesGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.fspPremissesDocsGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.fspPremissesDocsGridDataService.next({
          data: selection.selectedItems[0].Dokumenty,
          total: selection.selectedItems[0].Dokumenty.length,
        });
      });
  }
}
