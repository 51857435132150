import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { Bdot500DetailsWrapperService } from '../bdot500-details-wrapper.service';
import {
  Bdot500Details,
  Bdot500DetailsDto,
} from './bdot500-details-details.model';

@Component({
  selector: 'gk-bdot500-details-details',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
})
export class Bdot500DetailsDetailsComponent
  extends BaseDetailsComponent<Bdot500DetailsDto[]>
  implements OnInit
{
  override parseCallback = Bdot500Details.fromApiToApp;

  constructor(
    private bdot500DetailsWrapperService: Bdot500DetailsWrapperService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToLandDetailsChange();
  }

  subscribeToLandDetailsChange(): void {
    this.bdot500DetailsWrapperService.$bdot500Details
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((bdot500Details) => {
        this.loadDetailsWithData(bdot500Details);
      });
  }
}
