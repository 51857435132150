import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { RequestType } from '../models/request.model';

@Injectable()
export class BaseDetailsService {
  public $loading = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  getDetailsByUrl<T>(
    url: string,
    requestType: RequestType = RequestType.Get,
    body: unknown = null,
  ): Observable<T> {
    this.$loading.next(true);

    const request$ =
      requestType === 'POST'
        ? this.httpClient.post<T>(url, body)
        : this.httpClient.get<T>(url);

    return request$.pipe(
      tap(() => {
        this.$loading.next(false);
      }),
      catchError((err) => {
        this.$loading.next(false);

        return throwError(err);
      }),
    );
  }
}
