import { ColumnHeader } from '../../gk-components/table/table.model';
import {
  BasicSelectFieldConfig,
  CommunitySelectFieldConfig,
  ControlNetworkFieldConfig,
  CoordinateSystemFieldConfig,
  DistrictSelectFieldConfig,
  FieldConfig,
  FieldConfigType,
  FieldPermissionsKeysConfig,
  InputFieldConfig,
  InputType,
  LandAndMortgageRegisterDepartmentFieldConfig,
  LandParcelNumberFieldConfig,
  NestedGroupFieldConfig,
  PlacesTypeaheadFieldConfig,
  SectionFieldConfig,
  SectionTitleFieldConfig,
  SeparatorFieldConfig,
  SheetSelectFieldConfig,
  StreetsTypeaheadFieldConfig,
} from '../../gk-dynamic-form/gk-dynamic-form.model';
import { ControlNetworkType } from '../../gk-dynamic-form/services/control-network/control-network.model';
import { CoordinateSystemType } from '../../gk-dynamic-form/services/coordinate-system/coordinate-system.model';
import {
  ActionPrintConfig,
  AreaControlConfig,
  AreaUnit,
  ArrayControlConfig,
  ControlConfig,
  DynamicMapObjectControlConfig,
  ListDataConfig,
  ListType,
  NestedAccordionControlConfig,
  PersistDataConfig,
  PersistDataProperty,
  PrintControlConfig,
  RequestConfig,
  RequestType,
  TableControlConfig,
  TitleControlConfig,
  ValueType,
} from '../../gk-dynamic-list/gk-dynamic-list.model';
import { GkMapPrintConfig } from '../../gk-map-print/models/gk-map-print-config.model';
import {
  AttributesFormState,
  BaseSourceState,
  Color,
  DownloadRangeFile,
  FileState,
  IncreaseRangeByBuffer,
  LineStringState,
  MapFeatureStyle,
  MapGeometryStyleConfig,
  MapLabelOrPointStyle,
  MapObjectApiType,
  MapObjectTableState,
  MapSheetFormState,
  MapSheetFormValue,
  NamesFileLoadFormControls,
  OpenLayersGeometryType,
  PointState,
  PolygonState,
  PrintAttributesFormState,
  RangeFromAllParcels,
  SourceType,
  ToolsState,
  ToolState,
  ToolType,
} from '../models';

export const cadastralUnitFormFieldConfigs: FieldConfig[] = [
  new NestedGroupFieldConfig(
    'administrativeUnit',
    [
      new CommunitySelectFieldConfig(
        'community',
        'GK.DYNAMIC_FORM.CADASTRAL_UNIT',
        true,
        true,
      ),
      new DistrictSelectFieldConfig(
        'district',
        'GK.DYNAMIC_FORM.DISTRICT',
        true,
      ),
      new SheetSelectFieldConfig('sheet', 'GK.DYNAMIC_FORM.SHEET', true),
    ],
    undefined,
    undefined,
    undefined,
    true,
  ),
];

export const addressFormFieldConfigs: FieldConfig[] = [
  new SectionTitleFieldConfig('GK.DYNAMIC_FORM.ADDRESS'),
  new NestedGroupFieldConfig('address', [
    new PlacesTypeaheadFieldConfig('place', 'GK.DYNAMIC_FORM.PLACE'),
    new StreetsTypeaheadFieldConfig('street', 'GK.DYNAMIC_FORM.STREET'),
  ]),
  new NestedGroupFieldConfig('addressDetails', [
    new InputFieldConfig(
      'buildingNumber',
      'GK.DYNAMIC_FORM.BUILDING_NUMBER',
      InputType.Text,
      '',
      [],
      ['/'],
      'premisesNumber',
    ),
    new InputFieldConfig('premisesNumber', 'GK.DYNAMIC_FORM.PREMISES_NUMBER'),
    new InputFieldConfig('postalCode', 'GK.DYNAMIC_FORM.POSTAL_CODE'),
  ]),
];

export const landAndMortgageRegisterFormFieldConfigs: FieldConfig[] = [
  new SectionTitleFieldConfig(
    'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER',
    new FieldPermissionsKeysConfig(['personalData']),
  ),
  new NestedGroupFieldConfig(
    'landAndMortgageRegister',
    [
      new LandAndMortgageRegisterDepartmentFieldConfig(
        'landAndMortgageRegisterDepartment',
        'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER_DEPARTMENT',
      ),
      new InputFieldConfig(
        'landAndMortgageRegisterNumber',
        'GK.DYNAMIC_FORM.LAND_AND_MORTGAGE_REGISTER_NUMBER',
      ),
    ],
    undefined,
    new FieldPermissionsKeysConfig(['personalData']),
  ),
];

export const districtFormFieldConfigs: FieldConfig[] = [
  ...cadastralUnitFormFieldConfigs,
  new SeparatorFieldConfig(),
  ...addressFormFieldConfigs,
];

export const landParcelFormFieldConfigs: FieldConfig[] = [
  ...cadastralUnitFormFieldConfigs,
  new LandParcelNumberFieldConfig(),
  new InputFieldConfig(
    FieldConfigType.LandParcelId,
    'GK.DYNAMIC_FORM.LAND_PARCEL_ID',
  ),
  new SeparatorFieldConfig(),
  ...addressFormFieldConfigs,
  new SeparatorFieldConfig(new FieldPermissionsKeysConfig(['personalData'])),
  ...landAndMortgageRegisterFormFieldConfigs,
];

export const planningDepartmentLandParcelFormFieldConfigs: FieldConfig[] = [
  ...cadastralUnitFormFieldConfigs,
  new NestedGroupFieldConfig('landParcel', [
    new InputFieldConfig(
      'number',
      'GK.DYNAMIC_FORM.LAND_PARCEL_NUMBER',
      InputType.Text,
      '',
      [],
      [],
      'denominator',
    ),
  ]),
  new SeparatorFieldConfig(),
  ...addressFormFieldConfigs,
];

export const premisesFormFieldConfigs: FieldConfig[] = [
  new SectionTitleFieldConfig('GK.DYNAMIC_FORM.PREMISES_NUMBER'),
  ...cadastralUnitFormFieldConfigs,
  new NestedGroupFieldConfig('premises', [
    new InputFieldConfig('cadastralNumber', 'GK.DYNAMIC_FORM.CADASTRAL_NUMBER'),
    new InputFieldConfig(
      'buildingNumberInDistrict',
      'GK.DYNAMIC_FORM.BUILDING_NUMBER_IN_DISTRICT',
    ),
    new InputFieldConfig(
      'buildingNumberInLandParcel',
      'GK.DYNAMIC_FORM.BUILDING_NUMBER_IN_LAND_PARCEL',
    ),
  ]),
  new SeparatorFieldConfig(),
  ...addressFormFieldConfigs,
];

export const buildingFormFieldConfigs: FieldConfig[] = [
  new SectionTitleFieldConfig('GK.DYNAMIC_FORM.BUILDING_CADASTRAL_NUMBER'),
  ...cadastralUnitFormFieldConfigs,
  new NestedGroupFieldConfig('building', [
    new InputFieldConfig(
      'cadastralNumberFrom',
      'GK.DYNAMIC_FORM.FROM',
      InputType.Number,
    ),
    new InputFieldConfig(
      'cadastralNumberTo',
      'GK.DYNAMIC_FORM.TO',
      InputType.Number,
    ),
  ]),
  new SeparatorFieldConfig(),
  ...addressFormFieldConfigs,
];

export const controlPointsFormFieldConfigs: FieldConfig[] = [
  new SectionTitleFieldConfig('GK.DYNAMIC_FORM.CONTROL_POINTS_DETAILS'),
  new NestedGroupFieldConfig(
    'administrativeUnit',
    [
      new CommunitySelectFieldConfig(
        'community',
        'GK.DYNAMIC_FORM.CADASTRAL_UNIT',
        false,
        true,
      ),
    ],
    undefined,
    undefined,
    undefined,
    true,
  ),
  new NestedGroupFieldConfig('numbers', [
    new InputFieldConfig('number', 'GK.DYNAMIC_FORM.POINT_NUMBER'),
    new InputFieldConfig('oldNumber', 'GK.DYNAMIC_FORM.POINT_OLD_NUMBER'),
  ]),
  new SectionFieldConfig('section', 'GK.DYNAMIC_FORM.SECTION', true),
  new InputFieldConfig('name', 'GK.DYNAMIC_FORM.NAME'),
  new InputFieldConfig('cylinderNumber', 'GK.DYNAMIC_FORM.CYLINDER_NUMBER'),
  new NestedGroupFieldConfig('controlNetworks', [
    new ControlNetworkFieldConfig(
      'horizontal',
      'GK.DYNAMIC_FORM.HORIZONTAL_NETWORK',
      ControlNetworkType.Horizontal,
      true,
    ),
    new ControlNetworkFieldConfig(
      'vertical',
      'GK.DYNAMIC_FORM.VERTICAL_NETWORK',
      ControlNetworkType.Vertical,
      true,
    ),
  ]),
  new NestedGroupFieldConfig('coordinateSystems', [
    new CoordinateSystemFieldConfig(
      'horizontal',
      'GK.DYNAMIC_FORM.HORIZONTAL_COORDINATE_SYSTEM',
      CoordinateSystemType.Horizontal,
      true,
    ),
    new CoordinateSystemFieldConfig(
      'vertical',
      'GK.DYNAMIC_FORM.VERTICAL_COORDINATE_SYSTEM',
      CoordinateSystemType.Vertical,
      true,
    ),
  ]),
];

export enum RangeTypeValue {
  Wkt = 'wkt',
  Txt = 'txt',
  Dxf = 'dxf',
  Gml = 'gml',
}

export const getFileLoaderFormFieldConfigs = (
  extendedByDxfAndGml = false,
): FieldConfig[] => [
  new BasicSelectFieldConfig(
    NamesFileLoadFormControls.GeometryFormat,
    'GK.MAP.SELECT_GEOMETRY_FORMAT',
    [
      { value: RangeTypeValue.Wkt, name: 'WKT' },
      { value: RangeTypeValue.Txt, name: 'TXT' },
      ...(extendedByDxfAndGml
        ? [
            { value: RangeTypeValue.Dxf, name: 'DXF' },
            { value: RangeTypeValue.Gml, name: 'GML' },
          ]
        : []),
    ],
  ),
];

export const searchToolMapObjectTableState = new MapObjectTableState(
  [
    new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER', true),
    new ColumnHeader('districtName', 'GK.MAP.DISTRICT', true),
    new ColumnHeader('mapSheet', 'GK.MAP.SHEET', true),
  ],
  'GK.MAP.SELECT_FROM_RESULT',
  undefined,
  true,
  false,
  false,
  '15',
  [],
  'gk-map-icon-confirm',
  'GK.MAP.SELECT',
  'GK.MAP.SELECT',
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  false,
);

export const fitToolMapObjectTableState = new MapObjectTableState(
  [
    new ColumnHeader('mapObjectNumber', 'GK.MAP.REFERENCE_NUMBER', true),
    new ColumnHeader('districtName', 'GK.MAP.DISTRICT', true),
    new ColumnHeader('mapSheet', 'GK.MAP.SHEET', true),
  ],
  'GK.MAP.SELECT_FROM_RESULT',
  undefined,
  true,
  false,
  false,
  '15',
  [],
  'gk-map-icon-confirm',
  'GK.MAP.FIT',
  'GK.MAP.FIT',
);

export const defaultMapGeometryStyles = new MapGeometryStyleConfig(
  new MapFeatureStyle(Color.BrightBlueTransparent, Color.BrightBlueSolid, 2),
  new MapLabelOrPointStyle(
    Color.BrightBlueTransparent,
    Color.BrightBlueSolid,
    1,
    5,
  ),
);
export const mapSheetFormValue: MapSheetFormValue = {
  scale: {
    denominator: undefined,
    name: undefined,
    resolution: undefined,
    zoom: undefined,
  },
  paper: {
    orientation: undefined,
    name: undefined,
    size: undefined,
  },
  center: undefined,
};

export const dynamicMapObjectAttributesControlConfig =
  new DynamicMapObjectControlConfig();

export const baseAttributesPath = 'GK.MAP.GET_INFO_ATTRIBUTES';

export const attributesDynamicListConfig: ListDataConfig = new ListDataConfig(
  undefined,
  [
    new TitleControlConfig(
      `${baseAttributesPath}.LAND_PARCEL`,
      true,
      [],
      new PrintControlConfig(
        'Drukuj',
        '',
        false,
        new ActionPrintConfig(
          new RequestConfig(
            '`/api/interesant/przp/dzialka/${data.Uuid}/wydruk`',
            RequestType.Post,
            [],
            true,
            undefined,
            'blob',
            undefined,
            undefined,
            undefined,
            'response',
          ),
          'bi bi-printer',
          'label',
        ),
      ),
    ),
    new ControlConfig(
      `${baseAttributesPath}.REGISTRY_ID`,
      'ObrebId',
      true,
      ValueType.Text,
    ),
    new AreaControlConfig(
      `${baseAttributesPath}.REGISTRATION_AREA`,
      'PowierzchniaEwidText',
      true,
      undefined,
      undefined,
      'Dzialka.Arowa',
    ),
    new ControlConfig(
      `${baseAttributesPath}.IDENTIFIER`,
      'Identyfikator',
      true,
      ValueType.Text,
    ),
    new ControlConfig(
      `${baseAttributesPath}.DISTRICT_NAME`,
      'ObrebNazwa',
      true,
      ValueType.Text,
    ),
    new ControlConfig(
      `${baseAttributesPath}.NR_KW`,
      'NrKw',
      true,
      ValueType.Text,
    ),
    new ControlConfig(
      `${baseAttributesPath}.ADDRESS`,
      'Adres',
      true,
      ValueType.Text,
    ),
    new ControlConfig(
      `${baseAttributesPath}.REGISTRY_UNIT_NAME`,
      'JednostkaRejestrowaNazwa',
      true,
      ValueType.Text,
    ),
    new NestedAccordionControlConfig(
      `${baseAttributesPath}.BORDER_POINTS`,
      true,
      [],
      new ListDataConfig(undefined, [
        new TableControlConfig(
          `${baseAttributesPath}.DOCUMENTS`,
          false,
          'PunktyGraniczne',
          [],
          [
            new ControlConfig('Id', 'Identyfikator', false, ValueType.Text),
            new ControlConfig('X', 'WspX', false, ValueType.Text),
            new ControlConfig('Y', 'WspY', false, ValueType.Text),
            new ControlConfig('Błąd', 'Blad', false, ValueType.Text),
            new ControlConfig(
              'Pochodzenie',
              'Pochodzenie',
              false,
              ValueType.Text,
            ),
            new ControlConfig(
              'Stabilizacja',
              'Stabilizacja',
              false,
              ValueType.Text,
            ),
            new ControlConfig(
              'Operaty',
              'ListaOperatow',
              false,
              ValueType.Text,
            ),
            new ControlConfig('Ukł. wsp.', 'UkładNazwa', false, ValueType.Text),
          ],
          'small',
        ),
      ]),
      true,
    ),
    new NestedAccordionControlConfig(
      `${baseAttributesPath}.LAND_USES_AND_CLASSIFICATION_CONTOURS`,
      true,
      [],
      new ListDataConfig(undefined, [
        new TableControlConfig(
          `${baseAttributesPath}.LAND_USES_AND_CLASSIFICATION_CONTOURS`,
          false,
          'Klasouzytki',
          [],
          [
            new ControlConfig(
              `${baseAttributesPath}.LAND_USE_NAME`,
              'UzytekNazwa',
              false,
              ValueType.Text,
            ),
            new ControlConfig(
              `${baseAttributesPath}.CLASS`,
              'KlasaNazwa',
              false,
              ValueType.Text,
            ),
            new AreaControlConfig(
              `${baseAttributesPath}.AREA`,
              'Powierzchnia',
              false,
              undefined,
              undefined,
              'KdzialkaArowa',
            ),
          ],
          'small',
        ),
      ]),
      true,
    ),
    new NestedAccordionControlConfig(
      `${baseAttributesPath}.SUBJECT_DATA`,
      true,
      [],
      new ListDataConfig(undefined, [
        new TitleControlConfig(`${baseAttributesPath}.SUBJECT_DATA`, true, []),
        new ArrayControlConfig(
          `${baseAttributesPath}.SUBJECT_DATA`,
          'Wladajacy',
          false,
          [],
          new NestedAccordionControlConfig(
            `${baseAttributesPath}.SUBJECT_DATA`,
            true,
            [],
            new ListDataConfig(undefined, [
              new ControlConfig(
                `${baseAttributesPath}.NAME`,
                'OsobaNazwa',
                true,
                ValueType.Text,
              ),
              new ControlConfig(
                `${baseAttributesPath}.REGISTRY_GROUP_NAME`,
                'GrupaRejestrowaNazwa',
                true,
                ValueType.Text,
              ),
              new ControlConfig(
                `${baseAttributesPath}.SHARE`,
                'Udzial',
                true,
                undefined,
                undefined,
              ),
              new ControlConfig(
                `${baseAttributesPath}.OWNERSHIP_FORM`,
                'FormaWladaniaNazwa',
                true,
                undefined,
                undefined,
              ),
              new ControlConfig(
                `${baseAttributesPath}.REMARKS`,
                'Uwagi',
                true,
                undefined,
                undefined,
              ),
              new ControlConfig(
                `${baseAttributesPath}.ADDRESS`,
                'Adres',
                true,
                undefined,
                undefined,
              ),
              new ControlConfig(
                `${baseAttributesPath}.CORRESPONDENCE_ADDRESS`,
                'AdresDoKorespondencji',
                true,
                undefined,
                undefined,
              ),
            ]),
            true,
            'OsobaNazwa',
          ),
        ),
      ]),
      true,
      undefined,
      18,
    ),
    new NestedAccordionControlConfig(
      `${baseAttributesPath}.BUILDINGS`,
      true,
      [],
      new ListDataConfig(
        new RequestConfig(
          '`/api/interesant/przp/dzialka/${data.Uuid}/budynki`',
          RequestType.Get,
          [],
          true,
          undefined,
          undefined,
          undefined,
          'Buildings',
        ),
        [
          new TitleControlConfig(`${baseAttributesPath}.BUILDINGS`, true, []),
          new ArrayControlConfig(
            `${baseAttributesPath}.BUILDINGS`,
            'Buildings',
            false,
            [],
            new NestedAccordionControlConfig(
              `${baseAttributesPath}.BUILDINGS`,
              true,
              [],
              new ListDataConfig(undefined, [
                new ControlConfig(
                  `${baseAttributesPath}.IDENTIFIER`,
                  'Identyfikator',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.KST_BUILDING_TYPE`,
                  'RodzajKstNazwa',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.NUMBER_ABOVE_GROUND_FLOORS`,
                  'KondygnacjeNadziemne',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.NUMBER_UNDERGROUND_FLOORS`,
                  'KondygnacjePodziemne',
                  true,
                  ValueType.Text,
                ),
                new AreaControlConfig(
                  `${baseAttributesPath}.BUILDING_AREA`,
                  'PowierzchniaZabudowyM2',
                  true,
                  AreaUnit.SquareMeter,
                  undefined,
                ),
                new AreaControlConfig(
                  `${baseAttributesPath}.FLOOR_SURFACE`,
                  'PowierzchniaUzytkowaM2',
                  true,
                  AreaUnit.SquareMeter,
                  undefined,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.LAND_PARCEL_ID`,
                  'IdentyfikatorDzialki',
                  true,
                  ValueType.Text,
                ),
                new NestedAccordionControlConfig(
                  `${baseAttributesPath}.DOCUMENTS`,
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new TableControlConfig(
                      `${baseAttributesPath}.DOCUMENTS`,
                      false,
                      'Dokumenty',
                      [],
                      [
                        new ControlConfig(
                          'Dokument',
                          'NrDok',
                          false,
                          ValueType.Text,
                        ),
                        new ControlConfig(
                          'Rodzaj',
                          'Typ',
                          false,
                          ValueType.Text,
                        ),
                        new ControlConfig(
                          'Ważny od',
                          'DataOd',
                          false,
                          ValueType.DateTime,
                        ),
                        new ControlConfig(
                          'Ważny do',
                          'DataDo',
                          false,
                          ValueType.DateTime,
                        ),
                        new ControlConfig(
                          'Uwagi',
                          'Uwagi',
                          false,
                          ValueType.Text,
                        ),
                      ],
                      'small',
                    ),
                  ]),
                  true,
                ),
                new NestedAccordionControlConfig(
                  `${baseAttributesPath}.PERSONAL_DATA`,
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new ArrayControlConfig(
                      `${baseAttributesPath}.PERSONAL_DATA`,
                      'Wladajacy',
                      false,
                      ['Wladajacy'],
                      new NestedAccordionControlConfig(
                        `${baseAttributesPath}.PERSONAL_DATA`,
                        true,
                        [],
                        new ListDataConfig(undefined, [
                          new ControlConfig(
                            `${baseAttributesPath}.NAME_SURNAME`,
                            'OsobaNazwa',
                            true,
                            ValueType.Text,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.REGISTRY_GROUP_NAME`,
                            'GrupaRejestrowaNazwa',
                            true,
                            ValueType.Text,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.SHARE`,
                            'Udzial',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.OWNERSHIP_FORM`,
                            'FormaWladaniaNazwa',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.REMARKS`,
                            'Uwagi',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.ADDRESS`,
                            'Adres',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.CORRESPONDENCE_ADDRESS`,
                            'AdresDoKorespondencji',
                            true,
                            undefined,
                          ),
                        ]),
                        true,
                        'OsobaNazwa',
                        12,
                      ),
                    ),
                  ]),
                  true,
                  undefined,
                  20,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.NR_KW`,
                  'NrKw',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.ADDRESS`,
                  'Adres',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.REGISTRY_UNIT_NAME`,
                  'JednostkaRejestrowaNazwa',
                  true,
                  ValueType.Text,
                ),
                new PrintControlConfig(
                  'Drukuj',
                  '',
                  true,
                  new ActionPrintConfig(
                    new RequestConfig(
                      '`/api/interesant/przp/dzialka/${data.parcelUuid}/budynek/${data.Uuid}/wydruk`',
                      RequestType.Post,
                      [],
                      true,
                      undefined,
                      'blob',
                      undefined,
                      undefined,
                      undefined,
                      'response',
                    ),
                    'bi bi-printer',
                    'label',
                  ),
                ),
              ]),
              true,
              'Identyfikator',
              25,
              undefined,
            ),
          ),
        ],
        ListType.Accordion,
        undefined,
        new PersistDataConfig([new PersistDataProperty('Uuid', 'parcelUuid')]),
      ),
      true,
      undefined,
      20,
      undefined,
    ),
    new NestedAccordionControlConfig(
      `${baseAttributesPath}.PREMISES`,
      true,
      [],
      new ListDataConfig(
        new RequestConfig(
          '`/api/interesant/przp/dzialka/${data.Uuid}/lokale`',
          RequestType.Get,
          [],
          true,
          undefined,
          undefined,
          undefined,
          'Premises',
        ),
        [
          new TitleControlConfig(`${baseAttributesPath}.PREMISES`, true, []),
          new ArrayControlConfig(
            `${baseAttributesPath}.PREMISES`,
            'Premises',
            false,
            [],
            new NestedAccordionControlConfig(
              `${baseAttributesPath}.PREMISES`,
              true,
              [],
              new ListDataConfig(undefined, [
                new ControlConfig(
                  `${baseAttributesPath}.IDENTIFIER`,
                  'Identyfikator',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.SERIAL_NUMBER`,
                  'NrPorzadkowy',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.TYPE_OF`,
                  'SloFunLokNazwa',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.FLOOR`,
                  'Kondygnacja',
                  true,
                  ValueType.Text,
                ),
                new AreaControlConfig(
                  `${baseAttributesPath}.FLOOR_SURFACE`,
                  'PowUzytkowa',
                  true,
                  AreaUnit.SquareMeter,
                ),
                new AreaControlConfig(
                  `${baseAttributesPath}.FLOOR_SURFACE_ADDITIONAL`,
                  'PowPomieszczenPrzynaleznych',
                  true,
                  AreaUnit.SquareMeter,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.ASSOCIATED_ROOMS`,
                  'ListaPomieszczenPrzynaleznych',
                  true,
                  ValueType.Text,
                ),
                new NestedAccordionControlConfig(
                  `${baseAttributesPath}.DOCUMENTS`,
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new TableControlConfig(
                      `${baseAttributesPath}.DOCUMENTS`,
                      false,
                      'Dokumenty',
                      [],
                      [
                        new ControlConfig(
                          'Dokument',
                          'NrDok',
                          false,
                          ValueType.Text,
                        ),
                        new ControlConfig(
                          'Rodzaj',
                          'Typ',
                          false,
                          ValueType.Text,
                        ),
                        new ControlConfig(
                          'Ważny od',
                          'DataOd',
                          false,
                          ValueType.DateTime,
                        ),
                        new ControlConfig(
                          'Ważny do',
                          'DataDo',
                          false,
                          ValueType.DateTime,
                        ),
                        new ControlConfig(
                          'Uwagi',
                          'Uwagi',
                          false,
                          ValueType.Text,
                        ),
                      ],
                      'small',
                    ),
                  ]),
                  true,
                ),
                new NestedAccordionControlConfig(
                  `${baseAttributesPath}.PERSONAL_DATA`,
                  true,
                  [],
                  new ListDataConfig(undefined, [
                    new ArrayControlConfig(
                      `${baseAttributesPath}.PERSONAL_DATA`,
                      'Wladajacy',
                      false,
                      ['Wladajacy'],
                      new NestedAccordionControlConfig(
                        `${baseAttributesPath}.PERSONAL_DATA`,
                        true,
                        [],
                        new ListDataConfig(undefined, [
                          new ControlConfig(
                            `${baseAttributesPath}.NAME_SURNAME`,
                            'OsobaNazwa',
                            true,
                            ValueType.Text,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.REGISTRY_GROUP_NAME`,
                            'GrupaRejestrowaNazwa',
                            true,
                            ValueType.Text,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.SHARE`,
                            'Udzial',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.OWNERSHIP_FORM`,
                            'FormaWladaniaNazwa',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.REMARKS`,
                            'Uwagi',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.ADDRESS`,
                            'Adres',
                            true,
                            undefined,
                          ),
                          new ControlConfig(
                            `${baseAttributesPath}.CORRESPONDENCE_ADDRESS`,
                            'AdresDoKorespondencji',
                            true,
                            undefined,
                          ),
                        ]),
                        true,
                        'OsobaNazwa',
                        12,
                      ),
                    ),
                  ]),
                  true,
                  undefined,
                  20,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.NR_KW`,
                  'NrKw',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.ADDRESS`,
                  'Adres',
                  true,
                  ValueType.Text,
                ),
                new ControlConfig(
                  `${baseAttributesPath}.REGISTRY_UNIT_NAME`,
                  'JednostkaRejestrowaNazwa',
                  true,
                  ValueType.Text,
                ),
                new PrintControlConfig(
                  'Drukuj',
                  '',
                  true,
                  new ActionPrintConfig(
                    new RequestConfig(
                      '`/api/interesant/przp/dzialka/${data.parcelUuid}/lokal/${data.Uuid}/wydruk`',
                      RequestType.Post,
                      [],
                      true,
                      undefined,
                      'blob',
                      undefined,
                      undefined,
                      undefined,
                      'response',
                    ),
                    'bi bi-printer',
                    'label',
                  ),
                ),
              ]),
              true,
              'Identyfikator',
              30,
              undefined,
            ),
          ),
        ],
        ListType.Accordion,
        undefined,
        new PersistDataConfig([new PersistDataProperty('Uuid', 'parcelUuid')]),
      ),
      true,
    ),
  ],
  ListType.Accordion,
);

export const initialToolsState: ToolsState = {
  [ToolType.AnyPolygon]: {
    isActive: false,
    icon: 'gk-map-icon-draw-any-polygon',
    label: 'GK.MAP.SELECT_ANY_POLYGON',
    mapObjects: [],
    mapObjectApiType: MapObjectApiType.ExtentOrPolygon,
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.VioletTransparent, Color.VioletSolid, 2),
    ),
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.DRAW_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isAlertVisible: false,
      alert: 'GK.MAP.TOPOLOGY_ERROR',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.RangeFromAllParcels]: {
      isActive: false,
      icon: 'gk-map-icon-range-from-parcels',
      label: 'GK.MAP.RANGE_FROM_ALL_PARCELS',
      type: SourceType.RangeFromAllParcels,
    } as RangeFromAllParcels,
    [SourceType.IncreaseRangeByBuffer]: {
      isActive: false,
      icon: '',
      buttonText: 'GK.MAP.BUFFER',
      label: 'GK.MAP.INCREASE_RANGE_BY_BUFFER',
      type: SourceType.IncreaseRangeByBuffer,
    } as IncreaseRangeByBuffer,
    [SourceType.DownloadRangeFile]: {
      isActive: false,
      icon: 'fa fa-download tool-icon',
      label: 'GK.MAP.DOWNLOAD_RANGE_FILE',
      formHeader: 'GK.MAP.DOWNLOAD_RANGE_FILE',
      fieldConfigs: getFileLoaderFormFieldConfigs(),
    } as DownloadRangeFile,
    [SourceType.File]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_FROM_FILE',
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [OpenLayersGeometryType.Polygon],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.Building]: {
    isActive: false,
    icon: 'gk-map-icon-select-building',
    label: 'GK.MAP.SELECT_BUILDING',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.OrangeTransparent, Color.OrangeSolid, 2),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.SELECT_BY_LINE_STRING',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_LINE_STRING',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as LineStringState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.SELECT_BY_FORM',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.BUILDING_SEARCH',
      fieldConfigs: buildingFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: searchToolMapObjectTableState,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.ClassificationContour]: {
    isActive: false,
    icon: 'gk-map-icon-select-classification-contour',
    label: 'GK.MAP.SELECT_CLASSIFICATION',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.TurquoiseTransparent, Color.TurquoiseSolid, 2),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.SELECT_BY_LINE_STRING',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_LINE_STRING',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as LineStringState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.District]: {
    isActive: false,
    icon: 'gk-map-icon-select-district',
    label: 'GK.MAP.SELECT_DISTRICT',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.SandTransparent, Color.SandSolid, 2),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.SELECT_BY_LINE_STRING',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_LINE_STRING',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as LineStringState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.SELECT_BY_FORM',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.DISTRICT_SEARCH',
      fieldConfigs: districtFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: searchToolMapObjectTableState,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.LandParcel]: {
    isActive: false,
    icon: 'gk-map-icon-select-land-parcel',
    label: 'GK.MAP.SELECT_LAND_PARCEL',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.BlueTransparent, Color.BlueSolid, 2),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.SELECT_BY_LINE_STRING',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_LINE_STRING',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as LineStringState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.SELECT_BY_FORM',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.LAND_PARCEL_SEARCH',
      fieldConfigs: landParcelFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: {
        ...searchToolMapObjectTableState,
        rangeFromParcelsVisible: true,
      },
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
    [SourceType.SearchByIdFromFile]: {
      isActive: false,
      icon: 'fa fa-file-code-o',
      label: 'GK.MAP.LOAD_BY_ID_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_ID_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.LandUse]: {
    isActive: false,
    icon: 'gk-map-icon-select-land-use',
    label: 'GK.MAP.SELECT_LAND_USE',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.GreenTransparent, Color.GreenSolid, 2),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.SELECT_BY_LINE_STRING',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_LINE_STRING',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as LineStringState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.Premises]: {
    isActive: false,
    icon: 'gk-map-icon-select-premises',
    label: 'GK.MAP.SELECT_PREMISES',
    mapObjects: [],
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.SELECT_BY_FORM',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.PREMISES_SEARCH',
      fieldConfigs: premisesFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: searchToolMapObjectTableState,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
  },
  [ToolType.RectangularExtent]: {
    isActive: false,
    icon: 'gk-map-icon-draw-rectangular-extent',
    label: 'GK.MAP.SELECT_RECTANGULAR_EXTENT',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.VioletTransparent, Color.VioletSolid, 2),
    ),
    [SourceType.MapSheetForm]: {
      isActive: false,
      icon: 'gk-map-icon-draw-rectangle',
      label: 'GK.MAP.DEFINE_RECTANGULAR_EXTENT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DEFINE_RECTANGULAR_EXTENT',
      hasMapToolContainer: true,
      formHeader: 'GK.MAP.DEFINE_RECTANGULAR_EXTENT',
      formValue: mapSheetFormValue,
      isSelectionEnabled: true,
      isTranslationEnabled: true,
    } as MapSheetFormState,
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.FIT_TO_LAND_PARCELS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.FIT_TO_LAND_PARCELS',
      fieldConfigs: landParcelFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: fitToolMapObjectTableState,
      isEnabledRectangularExtentFit: true,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
  },
  [ToolType.Info]: {
    isActive: false,
    icon: 'gk-map-icon-get-info',
    label: 'GK.MAP.GET_INFO',
    mapObjects: [],
    isPopupVisible: false,
    isPopupLoaderVisible: false,
    attributesDynamicListConfig,
    requestedMapObjectsWithAttributes: [],
    previewedMapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(
        Color.BrightBlueTransparent,
        Color.BrightBlueSolid,
        2,
      ),
      new MapLabelOrPointStyle(
        Color.BrightBlueTransparent,
        Color.BrightBlueSolid,
        1,
        5,
      ),
    ),
    [SourceType.Point]: {
      isActive: false,
      icon: 'gk-map-icon-pick-point',
      label: 'GK.MAP.SELECT_BY_POINT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_PICK_POINT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PointState,
  },
  [ToolType.ControlPoint]: {
    isActive: false,
    icon: 'gk-map-icon-control-points',
    label: 'GK.MAP.SELECT_CONTROL_POINT',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      undefined,
      new MapLabelOrPointStyle(Color.RubyTransparent, Color.RubySolid, 1, 5),
    ),
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.AttributesForm]: {
      isActive: false,
      icon: 'gk-map-icon-form',
      label: 'GK.MAP.SELECT_BY_FORM',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      formHeader: 'GK.MAP.CONTROL_POINT_SEARCH',
      fieldConfigs: controlPointsFormFieldConfigs,
      mapObjects: [],
      mapObjectTableState: searchToolMapObjectTableState,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as AttributesFormState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.BoundaryPoint]: {
    isActive: false,
    icon: 'gk-map-icon-boundary-points',
    label: 'GK.MAP.SELECT_BOUNDARY_POINT',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      undefined,
      new MapLabelOrPointStyle(Color.BrownTransparent, Color.BrownSolid, 1, 5),
    ),
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.SELECT_BY_POLYGON',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_DRAW_POLYGON',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as PolygonState,
    [SourceType.SearchByRangeFromFile]: {
      isActive: false,
      icon: 'gk-map-icon-load-from-file',
      label: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      formHeader: 'GK.MAP.LOAD_BY_RANGE_FROM_FILE',
      tooltipMessage: 'GK.MAP.TOOLTIP_LOAD_BY_RANGE_FROM_FILE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      fieldConfigs: getFileLoaderFormFieldConfigs(),
      geometryTypes: [
        OpenLayersGeometryType.Polygon,
        OpenLayersGeometryType.MultiPolygon,
      ],
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as FileState,
  },
  [ToolType.Measurement]: {
    isActive: false,
    icon: 'gk-map-icon-measurement',
    label: 'GK.MAP.MEASUREMENTS',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.VioletTransparent, Color.VioletSolid, 2),
    ),
    interactionDrawStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(
        Color.WhiteTransparent,
        Color.BlackHalfTransparent,
        3,
        [10, 10],
      ),
    ),
    [SourceType.LineString]: {
      isActive: false,
      icon: 'gk-map-icon-draw-line-string',
      label: 'GK.MAP.LENGTH_MEASUREMENT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_LENGTH_MEASUREMENT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as BaseSourceState,
    [SourceType.Polygon]: {
      isActive: false,
      icon: 'gk-map-icon-draw-polygon',
      label: 'GK.MAP.AREA_MEASUREMENT',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.TOOLTIP_AREA_MEASUREMENT',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as BaseSourceState,
    [SourceType.Clear]: {
      isActive: false,
      icon: 'gk-map-icon-remove',
      label: 'GK.MAP.CLEAR_MEASUREMENTS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      isSelectionEnabled: false,
      isTranslationEnabled: false,
    } as BaseSourceState,
  },
  [ToolType.Print]: {
    isActive: false,
    icon: 'fa fa-print pb-0',
    label: 'GK.MAP.PRINT.PRINT_CURRENT_MAP_VIEW',
    mapObjects: [],
    mapGeometryStyleConfig: new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.VioletTransparent, Color.VioletSolid, 2),
    ),
    [SourceType.PrintAttributesForm]: {
      isActive: false,
      icon: 'fa fa-print pb-0',
      label: 'GK.MAP.PRINT.PRINT_CURRENT_MAP_VIEW',
      isTranslationEnabled: true,
      config: new GkMapPrintConfig(),
    } as PrintAttributesFormState,
    [SourceType.QuickPrint]: {
      isActive: false,
      icon: 'fa fa-picture-o pb-0',
      label: 'GK.MAP.PRINT.QUICK_PRINT_CURRENT_MAP_VIEW',
      isTranslationEnabled: true,
      config: new GkMapPrintConfig(),
    } as PrintAttributesFormState,
  },
  [ToolType.Dropdown]: {
    isActive: false,
    icon: 'fa fa-info-circle pb-0',
    label: 'GK.MAP.OBJECT_DETAILS_INFO.OBJECT_INFO',
    mapObjects: [],
    [SourceType.LandDetail]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.LAND_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    [SourceType.BuildingDetail]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.BUILDING_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    [SourceType.DevelopmentPlan]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.DEVELOPMENT_PLAN_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    // TODO: add land use details
    // [SourceType.LandUse]: {
    //   isActive: false,
    //   icon: 'fa fa-dot-circle-o pb-0',
    //   label: 'GK.MAP.OBJECT_DETAILS_INFO.LAND_USE_DETAILS',
    //   isTooltipVisible: false,
    //   isTooltipLoaderVisible: false,
    //   tooltipMessage: '',
    //   isSelectionEnabled: false,
    //   isTranslationEnabled: false,
    //   isAlertVisible: false,
    // } as BaseSourceState,
    // [SourceType.PlanningZones]: {
    //   isActive: false,
    //   icon: 'fa fa-dot-circle-o pb-0',
    //   label: 'GK.MAP.OBJECT_DETAILS_INFO.PLANNING_ZONES_DETAILS',
    //   isTooltipVisible: false,
    //   isTooltipLoaderVisible: false,
    //   tooltipMessage: '',
    //   isSelectionEnabled: false,
    //   isTranslationEnabled: false,
    //   isAlertVisible: false,
    // } as BaseSourceState,
    [SourceType.AddressPoint]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.ADDRESS_POINT_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    [SourceType.StreetAxis]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.STREET_AXIS_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    [SourceType.Bdot500Objects]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.BDOT500_OBJECT_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
    [SourceType.Osnowa]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.OBJECT_DETAILS_INFO.OSNOWA_DETAILS',
      isTooltipVisible: false,
      isTooltipLoaderVisible: false,
      tooltipMessage: '',
      isSelectionEnabled: false,
      isTranslationEnabled: false,
      isAlertVisible: false,
    } as BaseSourceState,
  },
  [ToolType.Enclave]: {
    isActive: false,
    icon: 'fa fa-dot-circle-o pb-0',
    label: 'GK.MAP.ADD_ENCLAVE.LABEL',
    mapObjects: [],
    [SourceType.AddEnclave]: {
      isActive: false,
      icon: 'fa fa-dot-circle-o pb-0',
      label: 'GK.MAP.ADD_ENCLAVE.ADD_ENCLAVE',
      isTooltipVisible: true,
      isTooltipLoaderVisible: false,
      tooltipMessage: 'GK.MAP.ADD_ENCLAVE.TOOLTIP_ADD_ENCLAVE',
    } as BaseSourceState,
  },
};

export const auxiliaryLandParcelToolState: ToolState = {
  buttonText: 'GK.MAP.TOOL_TYPE.LAND_PARCEL',
  isActive: false,
  icon: 'gk-map-icon-select-land-parcel',
  label: 'GK.MAP.SELECT_LAND_PARCEL',
  mapObjects: [],
  isAuxiliary: true,
  mapGeometryStyleConfig:
    initialToolsState[ToolType.LandParcel].mapGeometryStyleConfig,
  [SourceType.AttributesForm]:
    initialToolsState[ToolType.LandParcel][SourceType.AttributesForm],
  [SourceType.SearchByRangeFromFile]:
    initialToolsState[ToolType.LandParcel][SourceType.SearchByRangeFromFile],
  [SourceType.SearchByIdFromFile]:
    initialToolsState[ToolType.LandParcel][SourceType.SearchByIdFromFile],
};

export const auxiliaryBuildingToolState: ToolState = {
  buttonText: 'GK.MAP.TOOL_TYPE.BUILDING',
  isActive: false,
  icon: 'gk-map-icon-select-building',
  label: 'GK.MAP.SELECT_BUILDING',
  mapObjects: [],
  isAuxiliary: true,
  mapGeometryStyleConfig:
    initialToolsState[ToolType.Building].mapGeometryStyleConfig,
  [SourceType.AttributesForm]:
    initialToolsState[ToolType.Building][SourceType.AttributesForm],
  [SourceType.SearchByRangeFromFile]:
    initialToolsState[ToolType.Building][SourceType.SearchByRangeFromFile],
};

export const auxiliaryDistrictToolState: ToolState = {
  buttonText: 'GK.MAP.TOOL_TYPE.DISTRICT',
  isActive: false,
  icon: 'gk-map-icon-select-district',
  label: 'GK.MAP.SELECT_DISTRICT',
  mapObjects: [],
  isAuxiliary: true,
  mapGeometryStyleConfig:
    initialToolsState[ToolType.District].mapGeometryStyleConfig,
  [SourceType.AttributesForm]:
    initialToolsState[ToolType.District][SourceType.AttributesForm],
  [SourceType.SearchByRangeFromFile]:
    initialToolsState[ToolType.District][SourceType.SearchByRangeFromFile],
};

export const initialToolsStateWithButtonText: ToolsState = {
  [ToolType.AnyPolygon]: {
    ...initialToolsState[ToolType.AnyPolygon],
    buttonText: 'GK.MAP.TOOL_TYPE.RANGE',
  },
  [ToolType.Building]: {
    ...initialToolsState[ToolType.Building],
    buttonText: 'GK.MAP.TOOL_TYPE.BUILDING',
  },
  [ToolType.ClassificationContour]: {
    ...initialToolsState[ToolType.ClassificationContour],
    buttonText: 'GK.MAP.TOOL_TYPE.CLASSIFICATION_CONTOUR',
  },
  [ToolType.District]: {
    ...initialToolsState[ToolType.District],
    buttonText: 'GK.MAP.TOOL_TYPE.DISTRICT',
  },
  [ToolType.LandParcel]: {
    ...initialToolsState[ToolType.LandParcel],
    buttonText: 'GK.MAP.TOOL_TYPE.LAND_PARCEL',
  },
  [ToolType.LandUse]: {
    ...initialToolsState[ToolType.LandUse],
    buttonText: 'GK.MAP.TOOL_TYPE.LAND_USE',
  },
  [ToolType.Premises]: {
    ...initialToolsState[ToolType.Premises],
    buttonText: 'GK.MAP.TOOL_TYPE.PREMISES',
  },
  [ToolType.RectangularExtent]: {
    ...initialToolsState[ToolType.RectangularExtent],
    buttonText: 'GK.MAP.TOOL_TYPE.RECTANGULAR_EXTENT',
  },
  [ToolType.Dropdown]: {
    ...initialToolsState[ToolType.Dropdown],
    buttonText: 'GK.MAP.TOOL_TYPE.INFO',
  },
  [ToolType.Info]: {
    ...initialToolsState[ToolType.Info],
    buttonText: 'GK.MAP.TOOL_TYPE.INFO',
  },
  [ToolType.ControlPoint]: {
    ...initialToolsState[ToolType.ControlPoint],
    buttonText: 'GK.MAP.TOOL_TYPE.CONTROL_POINT',
  },
  [ToolType.BoundaryPoint]: {
    ...initialToolsState[ToolType.BoundaryPoint],
    buttonText: 'GK.MAP.TOOL_TYPE.BOUNDARY_POINT',
  },
  [ToolType.Measurement]: {
    ...initialToolsState[ToolType.Measurement],
    buttonText: 'GK.MAP.TOOL_TYPE.MEASUREMENT',
  },
  [ToolType.Print]: {
    ...initialToolsState[ToolType.Print],
    buttonText: 'GK.MAP.TOOL_TYPE.PRINT',
  },
  [ToolType.Enclave]: {
    ...initialToolsState[ToolType.Enclave],
    buttonText: 'GK.MAP.ADD_ENCLAVE.LABEL',
  },
};
