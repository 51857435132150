import { Injectable } from '@angular/core';
import { GridDataService } from '@gk/gk-modules';
import {
  FreeServicesParcelRequestGridDto,
  FspParcelDetailsAttributeDto,
} from './free-services-parcel-request-grid-data-model';
import { Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class FreeServicesParcelRequestGridDataService extends GridDataService<FreeServicesParcelRequestGridDto> {
  constructor(private httpClient: HttpClient) {
    super('/api/interesant/przp/dzialki/search', 'POST');
  }

  public fetchParcelDetailsFromApi(
    id: number,
  ): Observable<FspParcelDetailsAttributeDto> {
    return this.httpClient
      .get<FspParcelDetailsAttributeDto>(`/api/interesant/przp/dzialka/${id}`)
      .pipe(shareReplay(1));
  }
}
