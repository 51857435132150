import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { GeometryPostBody } from '../../../../../models/geometry.model';
import { StreetAxisDetailsGridDto } from './street-axis-details-grid-data-model';
import { StreetAxisDetailsGridDataService } from './street-axis-details-grid-data.service';
import {
  getStreetAxisDetailsGridToolbarItems,
  streetAxisDetailsGridColumns,
} from './street-axis-details-grid-model';

@Component({
  selector: 'gk-street-axis-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class StreetAxisDetailsGridComponent extends GkKendoGridComponent<StreetAxisDetailsGridDto> {
  constructor(
    private streetAxisDetailsGridDataService: StreetAxisDetailsGridDataService,
  ) {
    super(streetAxisDetailsGridDataService);
  }

  override topGridToolbarItems = getStreetAxisDetailsGridToolbarItems(this);
  override columns = streetAxisDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  protected override firstRowSelected = true;
  override sortable = true;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);
    this.streetAxisDetailsGridDataService
      .queryByState(payload as State)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): GeometryPostBody {
    return {
      Wkt: geometry,
    };
  }
}
