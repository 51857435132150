import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoGridWithDetailsComponent } from '../../../../../gk-kendo';
import { MapBuildingDetailsComponent } from '../../map-building-details/map-building-details.component';
import { BuildingDetailsDto } from '../../map-building-details/map-building-details.model';
import { BuildingDetailsGridDataService } from './building-details-grid/building-details-grid-data.service';
import { BuildingDetailsGridComponent } from './building-details-grid/building-details-grid.component';

@Component({
  selector: 'gk-building-details-wrapper',
  standalone: false,
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component.html',
})
export class BuildingDetailsWrapperComponent extends GkKendoGridWithDetailsComponent<BuildingDetailsDto> {
  override gridComponent = BuildingDetailsGridComponent;
  override detailsComponent = MapBuildingDetailsComponent;

  protected override handleSelectedEnabled = true;
  protected override selectionTextKey = 'BUILDING_DETAILS.SELECTION_TEXT';
  protected override noRecordsTextKey = 'BUILDING_DETAILS.NO_RECORDS_TEXT';

  constructor(
    buildingDetailsGridDataService: BuildingDetailsGridDataService,
    translateService: TranslateService,
  ) {
    super(buildingDetailsGridDataService, translateService);
  }
}
