<gk-scale-dropdown
  [scales]="generalScales"
  [allowedResolution]="mapState.viewState.resolution"
  (resolutionChange)="handleGeneralResolutionChange($event)"
></gk-scale-dropdown>
<gk-layers-tree-button
  [mapState]="mapState"
  (dispatch)="dispatch.emit($event)"
></gk-layers-tree-button>
<gk-clear-button
  class="me-2"
  [mapState]="mapState"
  (dispatch)="dispatch.emit($event)"
></gk-clear-button>
@for (toolType of mapState.toolbarState.toolTypes; track toolType) {
  <gk-map-tool
    [mapState]="mapState"
    [toolType]="toolType"
    (dispatch)="dispatch.emit($event)"
  ></gk-map-tool>
  @for (
    sourceType of getSourceTypes(mapState.toolsState[toolType]);
    track sourceType
  ) {
    @if (shouldShowMapToolContainer(toolType, sourceType)) {
      <gk-map-tool-container
        [mapState]="mapState"
        [toolType]="toolType"
        [sourceType]="sourceType"
        (dispatch)="dispatch.emit($event)"
      ></gk-map-tool-container>
    }
  }
}
