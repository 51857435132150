import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { MapLandDetailsWrapperService } from '../map-land-details-wrapper.service';
import { LandDetails, LandDetailsDto } from './map-land-details-details.model';

@Component({
  selector: 'gk-map-land-details-details',
  templateUrl:
    '../../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
  standalone: false,
})
export class MapLandDetailsDetailsComponent
  extends BaseDetailsComponent<LandDetailsDto>
  implements OnInit
{
  override parseCallback = LandDetails.fromApiToApp;

  constructor(
    private mapLandDetailsWrapperService: MapLandDetailsWrapperService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToLandDetailsChange();
  }

  subscribeToLandDetailsChange(): void {
    this.mapLandDetailsWrapperService.$landDetails
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((landDetails) => {
        this.loadDetailsWithData(landDetails);
      });
  }
}
