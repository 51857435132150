import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoGridWithDetailsComponent } from '../../../../../gk-kendo';
import { MatrixDetailsDetailsComponent } from './matrix-details-details/matrix-details-details.component';
import { MatrixDetailsGridDto } from './matrix-details-grid/matrix-details-grid-data-model';
import { MatrixDetailsGridDataService } from './matrix-details-grid/matrix-details-grid-data.service';
import { MatrixDetailsGridComponent } from './matrix-details-grid/matrix-details-grid.component';

@Component({
  selector: 'gk-matrix-details-wrapper',
  standalone: false,
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component.html',
})
export class MatrixDetailsWrapperComponent extends GkKendoGridWithDetailsComponent<MatrixDetailsGridDto> {
  override gridComponent = MatrixDetailsGridComponent;
  override detailsComponent = MatrixDetailsDetailsComponent;
  protected override selectionTextKey = 'MATRIX_DETAILS.SELECTION_TEXT';
  protected override noRecordsTextKey = 'MATRIX_DETAILS.NO_RECORDS_TEXT';

  constructor(
    matrixDetailsGridDataService: MatrixDetailsGridDataService,
    translateService: TranslateService,
  ) {
    super(matrixDetailsGridDataService, translateService);
  }
}
