import { ColumnSetting, ColumnType, GridToolBarItem } from '@gk/gk-modules';

export const getFspClassUseGridToolbarItems = (): GridToolBarItem[] => {
  return [];
};

export const fspClassUseGridColumns: ColumnSetting[] = [
  {
    field: 'UzytekNazwa',
    title: 'Użytek',
    type: ColumnType.Text,
  },
  {
    field: 'KlasaNazwa',
    title: 'Klasa',
    type: ColumnType.Text,
  },
  {
    field: 'Powierzchnia',
    title: 'Powierzchnia',
    type: ColumnType.Text,
  },
];
