@if (
  (mapState.toolsState[toolType].isActive || isToolsExpandedMode()) &&
  toolType !== ToolType.Dropdown
) {
  <div
    class="btn-group"
    role="group"
    [attr.aria-label]="mapState.toolsState[toolType].label | translate"
  >
    <button
      class="btn btn-sm"
      type="button"
      [ngClass]="[
        mapState.toolsState[toolType].isActive
          ? 'btn-color-from-db-active'
          : 'btn-color-from-db',
        mapState.toolsState[toolType].buttonText
          ? ''
          : mapState.toolsState[toolType].icon,
      ]"
      [title]="mapState.toolsState[toolType].label | translate"
      [attr.aria-label]="mapState.toolsState[toolType].label | translate"
      (click)="handleToolActivation()"
    >
      {{ mapState.toolsState[toolType].buttonText | translate }}
    </button>
    @for (sourceType of sourceTypes; track sourceType; let lastItem = $last) {
      @if (!getCurrentSourceType(toolType, sourceType)) {
        <button
          class="btn btn-sm"
          [ngClass]="[
            getSourceTypeButtonClass(sourceType),
            lastItem ? 'me-2' : '',
          ]"
          type="button"
          [title]="mapState.toolsState[toolType][sourceType].label | translate"
          [attr.aria-label]="
            mapState.toolsState[toolType][sourceType].label | translate
          "
          (click)="handleSourceActivation(sourceType)"
        ></button>
      }
      @if (
        areLandParcelAndRangeDefinedInToolTypesConfiguration() &&
        getCurrentSourceType(toolType, sourceType) ===
          sourceTypeEnum.RangeFromAllParcels
      ) {
        <gk-range-from-all-parcels-button
          #rangeFromAllParcelsButtonWrapperTemplate
          [buttonClass]="
            [getSourceTypeButtonClass(sourceType), lastItem ? 'me-2' : ''].join(
              ' '
            )
          "
          [mapState]="mapState"
          (dispatch)="dispatch.emit($event)"
        ></gk-range-from-all-parcels-button>
        <ng-container
          *ngTemplateOutlet="
            rangeFromAllParcelsButtonWrapperTemplate.buttonTemplate
          "
        ></ng-container>
      }
      @if (
        getCurrentSourceType(toolType, sourceType) ===
        sourceTypeEnum.IncreaseRangeByBuffer
      ) {
        <gk-increase-range-by-buffer-button
          #increaseRangeByBufferButtonWrapperTemplate
          [buttonClass]="
            [getSourceTypeButtonClass(sourceType), lastItem ? 'me-2' : ''].join(
              ' '
            )
          "
          [buttonText]="getCurrentButtonText(toolType, sourceType)"
          [toolType]="toolType"
          [sourceType]="sourceType"
          [mapState]="mapState"
          (dispatch)="dispatch.emit($event)"
        ></gk-increase-range-by-buffer-button>
      }
    }

    @if (
      shouldShowToolMemoryManagementButtonsForDataDerivedTools() &&
      isToolMemoryManagementActive
    ) {
      <button
        class="btn btn-outline-info btn-sm fa fa-level-up bg-white text-info"
        type="button"
        [title]="'GK.MAP.LOAD_FROM_TOOL_MEMORY' | translate"
        [attr.aria-label]="'GK.MAP.LOAD_FROM_TOOL_MEMORY' | translate"
        (click)="loadToTable()"
      ></button>
      <button
        class="btn btn-outline-danger btn-sm gk-map-icon-remove bg-white text-danger"
        type="button"
        [title]="'GK.MAP.ERASE_TOOL_MEMORY' | translate"
        [attr.aria-label]="'GK.MAP.ERASE_TOOL_MEMORY' | translate"
        (click)="eraseToolMemory()"
      ></button>
    }
  </div>
} @else if (toolType === ToolType.Dropdown) {
  <div
    class="btn-group"
    ngbDropdown
    [display]="'dynamic'"
    [placement]="['bottom-end', 'bottom-start']"
    role="group"
  >
    <button
      type="button"
      class="btn btn-sm dropdown-toggle"
      [ngClass]="[
        mapState.toolsState[toolType].isActive
          ? 'btn-color-from-db-active'
          : 'btn-color-from-db',
        mapState.toolsState[toolType].buttonText
          ? ''
          : mapState.toolsState[toolType].icon,
      ]"
      ngbDropdownToggle
      [title]="mapState.toolsState[toolType].label | translate"
      [attr.aria-label]="mapState.toolsState[toolType].label | translate"
      role="button"
    >
      @if (
        mapState.toolsState[toolType].buttonText &&
        mapState.toolsState[toolType].icon
      ) {
        <i class="fa fa-info" aria-hidden="true"></i>
      }
    </button>

    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
      @for (sourceType of sourceTypes; track sourceType) {
        <button
          class="dropdown-item d-flex align-items-center gap-2"
          type="button"
          [title]="mapState.toolsState[toolType][sourceType].label | translate"
          (click)="handleSourceActivation(sourceType)"
        >
          <span>{{
            mapState.toolsState[toolType][sourceType].label | translate
          }}</span>
        </button>
      }
    </div>
  </div>
} @else {
  <button
    class="btn btn-outline-color-from-db btn-sm bg-white text-secondary"
    type="button"
    [ngClass]="mapState.toolsState[toolType].icon"
    [title]="mapState.toolsState[toolType].label | translate"
    [attr.aria-label]="mapState.toolsState[toolType].label | translate"
    (click)="handleToolActivation()"
  ></button>
}
