import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  ApiUploadDocType,
  UploadDocType,
} from './gk-kendo-upload-doc-type-dictionary.model';
import { UploadDocTypeName } from '../gk-kendo-upload.model';
import { ApiResponse } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class GkKendoUploadDocTypeDictionaryService {
  private cache: { [key in UploadDocTypeName]?: Subject<UploadDocType[]> } = {};

  constructor(private httpClient: HttpClient) {}

  getDocTypes(docTypeName: UploadDocTypeName): Observable<UploadDocType[]> {
    if (!this.cache[docTypeName]) {
      this.cache[docTypeName] = new Subject<UploadDocType[]>();
      const url = this.getUrlForDocType(docTypeName);
      this.fetchDocTypes(url).subscribe((data) => {
        this.cache[docTypeName]!.next(data);
        this.cache[docTypeName]!.complete();
      });
    }

    return this.cache[docTypeName]!.asObservable();
  }

  private getUrlForDocType(docTypeName: UploadDocTypeName): string {
    switch (docTypeName) {
      case UploadDocTypeName.Default:
        return '/api/eod/slo/kdokrodz/portal';
      case UploadDocTypeName.WorkReports:
        return '/api/geodeta/slo/operat/kdokrodz';
      case UploadDocTypeName.Work:
        return '/api/geodeta/slo/praca/kdokrodz';
      case UploadDocTypeName.FeeConfirmation:
        return '/api/eod/slo/kdokrodz/potwierdzeniezaplaty';
      case UploadDocTypeName.CoordinationMeetingPortalCase:
        return '/api/zudp/branzysta/sprawy/slo/kdokrodz';
      default:
        throw new Error(`Unknown doc type: ${docTypeName}`);
    }
  }

  private fetchDocTypes(url: string): Observable<UploadDocType[]> {
    return this.httpClient.get<ApiResponse<ApiUploadDocType[]>>(url).pipe(
      map((res) => res.Response),
      map((apiFields) =>
        apiFields.map((apiField) => UploadDocType.fromApiToApp(apiField)),
      ),
    );
  }
}
