import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithoutTime',
})
export class DateWithoutTime implements PipeTransform {
  private static readonly API_DATE_TIME_REGEX =
    /[0-9]{4}-[0-9]{2}-[0-9]{2}[A-Z]{1}[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{7}/;

  transform(date: string | undefined): string | undefined {
    if (!date) {
      return undefined;
    }
    return this.isValidApiDateTimeFormat(date) ? date.split('T')[0] : date;
  }

  private isValidApiDateTimeFormat(dateTime: string): boolean {
    return (
      dateTime.length === 27 &&
      DateWithoutTime.API_DATE_TIME_REGEX.test(dateTime)
    );
  }
}
