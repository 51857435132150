import { Component, OnInit } from '@angular/core';
import { GkKendoGridComponent } from '@gk/gk-modules';
import { FspSubjectRequestGridDataService } from './fsp-subject-request-grid-data.service';
import {
  fspSubjectRequestGridColumns,
  getFspDocumentsRequestGridToolbarItems,
} from './fsp-subject-request-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesRequestListGridDataService } from '../free-services-request-list-grid/free-services-request-list-grid-data.service';
import { FspSubjectRequestGridDto } from './fsp-subject-request-grid-data-model';

@Component({
  selector: 'app-fsp-subject-request-grid',
  templateUrl:
    '../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspSubjectRequestGridComponent
  extends GkKendoGridComponent<FspSubjectRequestGridDto>
  implements OnInit
{
  constructor(
    private fspSubjectRequestGridDataService: FspSubjectRequestGridDataService,
    private requestListGridDataService: FreeServicesRequestListGridDataService,
  ) {
    super(fspSubjectRequestGridDataService);
  }

  override topGridToolbarItems = getFspDocumentsRequestGridToolbarItems();
  override columns = fspSubjectRequestGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  ngOnInit(): void {
    this.subscribeRequestListUuidSelected();
  }

  subscribeRequestListUuidSelected(): void {
    this.requestListGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.fspSubjectRequestGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((request) => {
        const uuid = request.selectedRows[0].dataItem.ZamowUuid;
        this.fetchDocumentationsGridData(uuid);
      });
  }

  fetchDocumentationsGridData(uuid: string): void {
    this.fspSubjectRequestGridDataService.queryByUrlParams(
      `/api/interesant/przp/zamowienie/${uuid}/pozycje`,
    );
  }
}
