import {
  Community,
  District,
  GridActionButtonsConfig,
  iwIconCheckmark,
  iwIconCross,
} from '@gk/gk-modules';
import { FreeServicesParcelSearchFormComponent } from './fsp-parcel-search-form.component';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { FilterOperator } from '@progress/kendo-data-query/dist/npm/filtering/operators.enum';

export enum FreeServicesParcelSearchFormControlName {
  Number = 'number',
  Community = 'community',
  District = 'district',
  Address = 'address',
}
export class FspParcelSearchFormModel {
  constructor(
    public number: string,
    public community: Community,
    public district: District,
    public address: string,
  ) {}

  static getEmptyModel(): FspParcelSearchFormModel {
    return new this(null, null, null, null);
  }

  static fromAppToApi(
    data: FspParcelSearchFormModel,
    uuid: string,
  ): CompositeFilterDescriptor {
    return {
      logic: 'and',
      filters: [
        {
          field: FreeServicesParcelSearchApiFields.Uuid,
          operator: 'eq',
          value: uuid,
        },
        {
          field: FreeServicesParcelSearchApiFields.Number,
          operator: FilterOperator.Contains,
          value: data.number,
          ignoreCase: true,
        },
        {
          field: FreeServicesParcelSearchApiFields.Community,
          operator: FilterOperator.EqualTo,
          value: data.community?.id,
          ignoreCase: true,
        },
        {
          field: FreeServicesParcelSearchApiFields.District,
          operator: FilterOperator.EqualTo,
          value: data.district?.id,
          ignoreCase: true,
        },
        {
          field: FreeServicesParcelSearchApiFields.Address,
          operator: FilterOperator.Contains,
          value: data.address,
          ignoreCase: true,
        },
      ].filter(
        (element) => element.value !== undefined && element.value !== null,
      ),
    };
  }
}

export enum FreeServicesParcelSearchApiFields {
  Number = 'Numer',
  Community = 'GminaId',
  District = 'ObrebId',
  Address = 'Adres',
  Uuid = 'ZamowUuid',
}

export const freeServicesParcelSearchFormModel =
  FspParcelSearchFormModel.getEmptyModel();

export const freeServicesParcelSearchFormActionButtonsConfig: GridActionButtonsConfig =
  {
    actionsLayout: 'end',
    buttons: [
      {
        text: 'CLEAR_FILTER',
        iconClass: 'fa fa-eraser',
        click: () => FreeServicesParcelSearchFormComponent.prototype.clearForm,
      },
      {
        text: 'APPLY_FILTER',
        type: 'submit',
        svgIcon: iwIconCheckmark,
        className: 'svg-icon-color-info',
      },
      {
        text: 'DISMISS',
        svgIcon: iwIconCross,
        className: 'svg-icon-red',
        click: () => FreeServicesParcelSearchFormComponent.prototype.onCancel,
      },
    ],
  };
