import { Component, OnInit } from '@angular/core';
import { GkKendoGridComponent } from '@gk/gk-modules';
import { FspBuildingsDocsGridDataService } from './fsp-buildings-docs-grid-data.service';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import {
  apDocumentsRequestGridColumns,
  getFspDocumentsRequestGridToolbarItems,
} from '../../../free-services-documents-grid/fsp-documents-grid-model';
import { FspDocumentsRequestGridDto } from '../../../free-services-documents-grid/fsp-documents-grid-data-model';
import { FspBuildingsGridDataService } from '../fsp-buildings-register-grid/fsp-buildings-grid-data.service';

@Component({
  selector: 'app-fsp-buildings-documents-grid',
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspBuildingsDocsGridComponent
  extends GkKendoGridComponent<FspDocumentsRequestGridDto>
  implements OnInit
{
  constructor(
    private fspBuildingsDocsGridDataService: FspBuildingsDocsGridDataService,
    private fspBuildingsGridDataService: FspBuildingsGridDataService,
  ) {
    super(fspBuildingsDocsGridDataService);
  }

  override topGridToolbarItems = getFspDocumentsRequestGridToolbarItems();
  override columns = apDocumentsRequestGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  ngOnInit(): void {
    this.subscribeBuildingsGridSelected();
  }

  subscribeBuildingsGridSelected(): void {
    this.fspBuildingsGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.fspBuildingsDocsGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.fspBuildingsDocsGridDataService.next({
          data: selection.selectedItems[0].Dokumenty,
          total: selection.selectedItems[0].Dokumenty.length,
        });
      });
  }
}
