import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { Bdot500DetailsGridDto } from './bdot500-details-grid-data-model';
import { Bdot500DetailsGridDataService } from './bdot500-details-grid-data.service';
import {
  bdot500DetailsGridColumns,
  getBdot500DetailsGridToolbarItems,
} from './bdot500-details-grid-model';

@Component({
  selector: 'gk-bdot500-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class Bdot500DetailsGridComponent extends GkKendoGridComponent<Bdot500DetailsGridDto> {
  constructor(
    private bdot500DetailsGridDataService: Bdot500DetailsGridDataService,
  ) {
    super(bdot500DetailsGridDataService);
  }

  override topGridToolbarItems = getBdot500DetailsGridToolbarItems(this);
  override columns = bdot500DetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  protected override firstRowSelected = true;
  private requestQuantity = 50;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);

    this.bdot500DetailsGridDataService
      .queryByState(payload)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): State {
    return {
      filter: {
        filters: [{ field: 'Geom', operator: 'intersects', value: geometry }],
        logic: 'and',
      },
      take: this.requestQuantity,
      sort: [{ field: 'ObiektId', dir: 'asc' }],
    };
  }
}
