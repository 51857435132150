import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoGridWithDetailsComponent } from '../../../../../gk-kendo';
import { MapAddressPointDetailsDetailsComponent } from './map-address-point-details-details/map-address-point-details-details.component';
import { AdressPointDetailsGridDto } from './map-address-point-details-grid/map-address-point-details-grid-data-model';
import { MapPointsDetailsGridDataService } from './map-address-point-details-grid/map-address-point-details-grid-data.service';
import { MapAddressPointDetailsGridComponent } from './map-address-point-details-grid/map-address-point-details-grid.component';

@Component({
  selector: 'gk-map-address-point-details-wrapper',
  standalone: false,
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component.html',
})
export class MapAddressPointDetailsWrapperComponent extends GkKendoGridWithDetailsComponent<AdressPointDetailsGridDto> {
  override gridComponent = MapAddressPointDetailsGridComponent;
  override detailsComponent = MapAddressPointDetailsDetailsComponent;

  protected override selectionTextKey = 'ADDRESS_POINT.SELECTION_TEXT';
  protected override noRecordsTextKey = 'ADDRESS_POINT.NO_RECORDS_TEXT';

  constructor(
    mapPointsDetailsGridDataService: MapPointsDetailsGridDataService,
    translateService: TranslateService,
  ) {
    super(mapPointsDetailsGridDataService, translateService);
  }
}
