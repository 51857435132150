import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';
import { DevelopmentPlanDetailsGridDto } from '../development-plan-details-grid-data-model';
export class DevelopmentPlanDetails {
  static fromApiToApp(
    data: DevelopmentPlanDetailsGridDto,
  ): BaseDetailSettings[] {
    return [
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.PLAN_NUMBER',
        value: data?.Numer,
        type: DetailType.Numeric,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.RESOLUTION_NUMBER',
        value: data?.NrUchwaly,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.RESOLUTION_DATE',
        value: data?.ZDnia,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.JOURNAL_NUMBER',
        value: data?.DzUrzNr,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.JOURNAL_POSITION',
        value: data?.DzUrzPoz,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.JOURNAL_DATE',
        value: data?.DzUrzData,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.FULL_NAME',
        value: data?.NazwaP,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.SHORT_NAME',
        value: data?.NazwaP,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.REGARDING',
        value: data?.WSprawie,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.VALID_FROM',
        value: data?.WaznaOd,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.VALID_TO',
        value: data?.WaznaDo,
        type: DetailType.Text,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.RESOLUTION_TEXT_LOCATION',
        value: data?.LinkUchw,
        type: DetailType.Html,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.GRAPHIC_ATTACHMENT_LOCATION',
        value: data?.LinkZgr,
        type: DetailType.Html,
      },
      {
        title: 'DEVELOPMENT_PLAN_DETAILS.AREA_DESCRIPTION',
        value: data?.Typ,
        type: DetailType.Text,
      },
    ];
  }
}
