import {
  ActionButtonNavItemConfig,
  AudioDescriptionToggleButtonNavItemConfig,
  BsColor,
  contrastDropdownNavItemConfig,
  DepartmentCode,
  DropdownNavItemConfig,
  DropdownNavItemId,
  DynamicNavbarConfig,
  GeneralNavConfig,
  LinkNavItemConfig,
  LogoImageNavItemConfig,
  LogoImageTextNavItemConfig,
  NavItemsSectionConfig,
  ShowInfoServiceConfig,
  SimpleTextNavItemConfig,
  TypeOfAccessibilityStatement,
} from '@gk/gk-modules';
import { MainRoutes } from '../../guards/guard.models';
import { FreeServicesCaseType } from '../../modules/free-services-portal/free-services-portal-routing.module';
import { TranslationVisibilityPortal } from '../../services/web-portal/web-portal.model';

export enum DesignerLinkId {
  NewRequestLink = 'designer_new-request-link',
  RequestsListLink = 'designer_requests-list-link',
  MapLink = 'designer_map-link',
  PaymentsLink = 'designer_payments-link',
}

export enum ManualSection {
  Geodesy = 'dla-geodetow',
  Appraiser = 'dla-rzeczoznawcow',
  Coordination = 'dla-narady-koordynacyjnej',
  Bailiff = 'dla-komornikow',
}

export enum EnvironmentalProtectionPortalLinkId {
  FishingCardRequestLink = 'environmental_protection_portal-fishing-card-request-link',
  WaterEquipmentRequestLink = 'environmental_protection_portal-water-equipment-request-link',
  TreesAndShrubsFellingRequestLink = 'environmental_protection_portal-trees-and-shrubs-felling-request-link',
  SimplifiedForestManagementPlanRequestLink = 'environmental_protection-simplified-forest-management-plan-request-link',
  PaymentsLink = 'environmental_protection_portal_payments-link',
  RequestsListLink = 'environmental_protection_portal_requests-list-link',
}

export enum ConstructionPortalLinkId {
  ConstructionNotificationRequestLink = 'construction_portal-construction-notification-request-link',
  PremiseIndependenceRequestLink = 'construction_portal-demolition-notification-request-link',
  ConstructionLogRequestLink = 'construction_portal-construction-log-request-link',
  RequestsListLink = 'construction_portal_requests-list-link',
}

export enum PlanningDepartmentLinkId {
  ExtractFromLocalSpatialDevelopmentPlanRequestLink = 'planning_department_extract-from-local-spatial-development-plan-request-link',
  RequestsListLink = 'planning_department_requests-list-link',
}

export enum OfficeDepartmentsPortalLinkId {
  OfficeDepartmentsLink = 'office_departments_portal-home-return-link',
  RequestsListLink = 'office_departments_portal_requests-list-link',
}

export enum SpatialInformationPortal {
  Home = 'spatial_information_portal-home-link',
}

export enum AddressNumberRequestPortalLinkId {
  NewRequestLink = 'address_number_request_portal_new-request-link',
  RequestsListLink = 'address_number_request_portal-list-link',
}

export enum SurveyorLinkId {
  NewRequestLink = 'surveyor_new-request-link',
  RequestsListLink = 'surveyor_requests-list-link',
  MapLink = 'surveyor_map-link',
  PaymentsLink = 'surveyor_payments-link',
  FilesToDownloadLink = 'surveyor_files-to-download-link',
}

export enum AppraiserLinkId {
  RequestsListLink = 'appraiser_requests-list-link',
  AppraiserDropdownGroupLink = 'appraiser_dropdown-group-link',
  SearchTransationLink = 'appraiser_search-transaction-link',
  InsightCollectionDocLink = 'appraiser_insight-collection-doc-link',
  InsightHistoryLink = 'appraiser_insight-history-link',
}

export enum GrantingRightsLLinkId {
  NewRequestLink = 'granting-rights_new-request-link',
  RequestsListLink = 'granting-rights-list-link',
}

export const officeDepartmentsNavItemConfig = new LinkNavItemConfig(
  OfficeDepartmentsPortalLinkId.OfficeDepartmentsLink,
  true,
  'Strona główna',
  '/office-departments-portal/departments',
  'home',
  true,
  true,
);

export const officeDepartmentsNavItemConfigIconOnly = new LinkNavItemConfig(
  OfficeDepartmentsPortalLinkId.OfficeDepartmentsLink,
  true,
  'Strona główna',
  '/office-departments-portal/departments',
  'home',
  false,
  true,
  [],
  '_self',
  '',
  false,
  true,
);

export const getOfficeDepartmentsNavItemConfigManual = (
  manualSectionItem: ManualSection,
): LinkNavItemConfig => {
  return new LinkNavItemConfig(
    OfficeDepartmentsPortalLinkId.OfficeDepartmentsLink,
    true,
    'Instrukcja portalu',
    `/dokuweb/e-uslugi/${manualSectionItem}/`,
    'book',
    false,
    true,
    [],
    '_self',
    '',
    true,
    true,
  );
};

export const officeDepartmentsNavItemConfigFaq = new LinkNavItemConfig(
  OfficeDepartmentsPortalLinkId.OfficeDepartmentsLink,
  true,
  'Najczęściej zadawane pytania',
  '/dokuweb/faq/faq/',
  'question-circle',
  false,
  true,
  [],
  '_self',
  '',
  true,
  true,
);

export const getRightSectionInfoConfig = (
  manualSectionItem: ManualSection,
): LinkNavItemConfig[] => {
  return [
    officeDepartmentsNavItemConfigIconOnly,
    getOfficeDepartmentsNavItemConfigManual(manualSectionItem),
    officeDepartmentsNavItemConfigFaq,
  ];
};

export const grantingRightsLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    GrantingRightsLLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    '/granting-rights-portal/new-grant',
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    GrantingRightsLLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/granting-rights-portal/requests-list`,
    'list',
    true,
    true,
  ),
]);

export const spatialInformationNavItemConfig = new LinkNavItemConfig(
  SpatialInformationPortal.Home,
  false,
  'Inne powiaty',
  '/spatial-information-portal',
  'home',
  true,
  true,
);

export enum LandUseRequestLinkId {
  NewRequestLink = 'new-land-use-portal_requests-list-link',
  RequestsListLink = 'land-use-portal_requests-list-link',
}

export const agriculturalLandUseRequestLinksSection = new NavItemsSectionConfig(
  [
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LandUseRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      '/excluding-land-from-agricultural-production-agricultural-land-use/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      LandUseRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/excluding-land-from-agricultural-production-agricultural-land-use/requests-list',
      'list',
      true,
      true,
    ),
  ],
);

export const nonAgriculturalLandUseRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LandUseRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      'excluding-land-from-agricultural-production-non-agricultural-land-use/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      LandUseRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/excluding-land-from-agricultural-production-non-agricultural-land-use/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const environmentalProtectionPortalSimplifiedForestManagementPlanRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.SimplifiedForestManagementPlanRequestLink,
      true,
      'Nowy wniosek',
      '/environmental-protection-portal-simplified-forest-management-plan-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/environmental-protection-portal-simplified-forest-management-plan-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const environmentalProtectionPortalFishingCardRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.FishingCardRequestLink,
      true,
      'Nowy wniosek',
      '/environmental-protection-portal-fishing-card-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/environmental-protection-portal-fishing-card-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const environmentalProtectionPortalWaterEquipmentRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.WaterEquipmentRequestLink,
      true,
      'Nowy wniosek',
      '/environmental-protection-portal-water-equipment-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/environmental-protection-portal-water-equipment-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const environmentalProtectionPortalTreesAndShrubsFellingRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.TreesAndShrubsFellingRequestLink,
      true,
      'Nowy wniosek',
      '/environmental-protection-portal-trees-and-shrubs-felling/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      EnvironmentalProtectionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/environmental-protection-portal-trees-and-shrubs-felling/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const designerLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    DesignerLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    '/designer-portal/new-designer-request',
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    DesignerLinkId.RequestsListLink,
    true,
    'Lista spraw',
    '/designer-portal/requests-list',
    'list',
    true,
    true,
  ),
  new LinkNavItemConfig(
    DesignerLinkId.MapLink,
    true,
    'Mapa',
    '/designer-portal/map',
    'map',
    true,
    true,
  ),
  new LinkNavItemConfig(
    DesignerLinkId.PaymentsLink,
    true,
    'Płatności',
    '/designer-portal/payments',
    'money',
    true,
    true,
  ),
]);

export const surveyorLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    SurveyorLinkId.RequestsListLink,
    true,
    'Lista prac',
    undefined,
    'list',
    true,
    true,
  ),
  new LinkNavItemConfig(
    SurveyorLinkId.FilesToDownloadLink,
    true,
    'Pliki do pobrania',
    undefined,
    'download',
    true,
    true,
  ),
]);

export const appraiserLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    AppraiserLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    undefined,
    'list',
    true,
    true,
  ),
  new DropdownNavItemConfig(
    AppraiserLinkId.AppraiserDropdownGroupLink,
    true,
    'Rzeczoznawca',
    undefined,
    [
      new ActionButtonNavItemConfig(
        AppraiserLinkId.SearchTransationLink,
        true,
        'APPRAISER_NAVBAR.SEARCH',
        'APPRAISER_NAVBAR.SEARCH',
        true,
      ),
      new ActionButtonNavItemConfig(
        AppraiserLinkId.InsightCollectionDocLink,
        true,
        'APPRAISER_NAVBAR.INSIGHT',
        'APPRAISER_NAVBAR.INSIGHT',
        true,
      ),
      new ActionButtonNavItemConfig(
        AppraiserLinkId.InsightHistoryLink,
        true,
        'APPRAISER_NAVBAR.HISTORY',
        'APPRAISER_NAVBAR.HISTORY',
        true,
      ),
    ],
    false,
    true,
    '',
    false,
    'pt-0 vertical-mobile-orientation',
  ),
]);

export enum CoordinationMeetingPortalLinkId {
  RequestsListLink = 'coordination-meeting-portal_requests-list-link',
}

export enum BailiffPortalLinkId {
  MainWindowLink = 'bailiff-portal_main-window-link',
  RequestsListLink = 'bailiff-portal_requests-list-link',
  ActiveSearchLink = 'bailiff-portal_active-search-link',
}

export const coordinationMeetingLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    CoordinationMeetingPortalLinkId.RequestsListLink,
    true,
    'Lista spraw',
    undefined,
    'list',
    true,
    true,
  ),
]);

export const bailiffPortalLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    BailiffPortalLinkId.MainWindowLink,
    true,
    'Portal Komornika',
    undefined,
    'list',
    true,
    true,
  ),
  new LinkNavItemConfig(
    BailiffPortalLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    undefined,
    'list',
    true,
    true,
  ),

  new LinkNavItemConfig(
    BailiffPortalLinkId.ActiveSearchLink,
    true,
    'Aktywne wyszukiwanie',
    undefined,
    'list',
    true,
    true,
  ),
]);

export enum PrzpLinkId {
  NewRequestLink = 'przp_new-request-link',
  NewAgreementLink = 'przp_new-agreement-link',
  RequestsListLink = 'przp_requests-list-link',
}

export const getPrzpLinksSection = (
  przpAgreementEnabled: boolean,
): NavItemsSectionConfig =>
  new NavItemsSectionConfig(
    [
      new LinkNavItemConfig(
        PrzpLinkId.NewRequestLink,
        true,
        'Nowy wniosek',
        `/free-services-portal/case-request/${FreeServicesCaseType.Request}`,
        'plus',
        true,
        true,
      ),
      przpAgreementEnabled
        ? new LinkNavItemConfig(
            PrzpLinkId.NewAgreementLink,
            true,
            'Nowe porozumienie',
            `/free-services-portal/case-agreement/${FreeServicesCaseType.Agreement}`,
            'plus',
            true,
            true,
          )
        : null,
      new LinkNavItemConfig(
        PrzpLinkId.RequestsListLink,
        true,
        'Lista spraw',
        '/free-services-portal/request-list',
        'list',
        true,
        true,
      ),
    ].filter(Boolean),
  );

export enum EcoPortalLinkId {
  SearchDocumentLink = 'eco-portal_search-document-link',
  NewsletterLink = 'eco-portal_newsletter',
}

export const ecoPortalLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    EcoPortalLinkId.SearchDocumentLink,
    true,
    'Wyszukaj dokument',
    '/eco-portal/search-document',
    'search',
    true,
    true,
  ),
  new LinkNavItemConfig(
    EcoPortalLinkId.NewsletterLink,
    true,
    'Newsletter',
    '/eco-portal/newsletter',
    'envelope',
    true,
    true,
  ),
]);

export enum BuildingPermitTransferPortalLinkId {
  RequestsListLink = 'building-permit-transfer-portal_requests-list',
}

export const buildingPermitTransferPortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      BuildingPermitTransferPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/building-permit-transfer/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export enum PropertyLeasePortalLinkId {
  NewRequestLink = 'property-lease-portal_new-request',
  RequestsListLink = 'property-lease-portal_requests-list',
}

export const propertyLeasePortalLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    PropertyLeasePortalLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.PropertyLease}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    PropertyLeasePortalLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.PropertyLease}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum SupplementaryParcelPurchasePortalLinkId {
  NewRequestLink = 'supplementary-parcel-purchase-portal_new-request',
  RequestsListLink = 'supplementary-parcel-purchase-portal_requests-list',
}

export const supplementaryParcelPurchasePortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      SupplementaryParcelPurchasePortalLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.SupplementaryParcelPurchase}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      SupplementaryParcelPurchasePortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.SupplementaryParcelPurchase}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum OwnershipConversionPortalLinkId {
  NewRequestLink = 'ownership-conversion-portal_new-request',
  RequestsListLink = 'ownership-conversion-portal_requests-list',
}

export const ownershipConversionPortalLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    OwnershipConversionPortalLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.OwnershipConversion}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    OwnershipConversionPortalLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.OwnershipConversion}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum OwnershipConversionFeeCalculationLinkId {
  NewRequestLink = 'ownership-conversion-fee-calculation-new-request',
  RequestsListLink = 'ownership-conversion-fee-calculation-requests-list',
}

export const ownershipConversionFeeCalculationLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      OwnershipConversionFeeCalculationLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.OwnershipConversionFeeCalculationRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      OwnershipConversionFeeCalculationLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.OwnershipConversionFeeCalculationRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum CommunalPremisePurchasePortalLinkId {
  NewRequestLink = 'communal-premise-purchase-portal_new-request',
  RequestsListLink = 'communal-premise-purchase-portal_requests-list',
}

export const communalPremisePurchasePortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      CommunalPremisePurchasePortalLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.CommunalPremisePurchase}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      CommunalPremisePurchasePortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.CommunalPremisePurchase}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum RoadExitPortalLinkId {
  RequestsListLink = 'road-exit-portal_requests-list-link',
}

export const roadExitPortalLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    RoadExitPortalLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    '/road-exit-portal/requests-list',
    'list',
    true,
    true,
  ),
]);

export enum LaneOccupancyPortalLinkId {
  InfrastructureDevicesRequestsListLink = 'lane-occupancy-portal_infrastructure-devices_requests-list-link',
  BuildingObjectsRequestsListLink = 'lane-occupancy-portal_building_objects_requests-list-link',
  BuildingWorksRequestsListLink = 'lane-occupancy-portal_building_works_requests-list-link',
}

export const laneOccupancyInfrastructureDevicesPortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LaneOccupancyPortalLinkId.InfrastructureDevicesRequestsListLink,
      true,
      'Moje wnioski',
      '/lane-occupancy-infrastructure-devices/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const laneOccupancyBuildingObjectsPortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LaneOccupancyPortalLinkId.BuildingObjectsRequestsListLink,
      true,
      'Moje wnioski',
      '/lane-occupancy-building-objects/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const laneOccupancyBuildingWorksPortalLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LaneOccupancyPortalLinkId.BuildingWorksRequestsListLink,
      true,
      'Moje wnioski',
      '/lane-occupancy-building-works/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const constructionPortalConstructionNotificationLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      ConstructionPortalLinkId.ConstructionNotificationRequestLink,
      true,
      'Nowy wniosek',
      '/construction-portal-construction-notification-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      ConstructionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/construction-portal-construction-notification-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export const constructionPortalDemolitionNotificationLinksSection =
  new NavItemsSectionConfig([officeDepartmentsNavItemConfig]);

export const constructionPortalPremiseIndependenceLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      ConstructionPortalLinkId.PremiseIndependenceRequestLink,
      true,
      'Nowy wniosek',
      '/construction-portal-premise-independence-notification-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      ConstructionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/construction-portal-premise-independence-notification-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

const getDropdownNavItemConfig = (
  regularDeclarationVisible: boolean,
  mobileDeclarationVisible: boolean,
) =>
  new DropdownNavItemConfig(
    'dropdown-links',
    true,
    'Deklaracje dostępności',
    'Deklaracje dostępności',
    [
      regularDeclarationVisible
        ? new LinkNavItemConfig(
            OfficeDepartmentsPortalLinkId.RequestsListLink,
            true,
            'portalu internetowego systemu do obsługi PZGiK',
            `${TypeOfAccessibilityStatement.Regular}`,
            '',
            true,
            true,
            undefined,
            '_blank',
            'w-100 rounded-0',
          )
        : null,
      mobileDeclarationVisible
        ? new LinkNavItemConfig(
            OfficeDepartmentsPortalLinkId.RequestsListLink,
            true,
            'aplikacji mobilnej',
            `${TypeOfAccessibilityStatement.Mobile}`,
            '',
            true,
            true,
            undefined,
            '_blank',
            'w-100 rounded-0',
          )
        : null,
    ].filter(Boolean),
    false,
    true,
    'universal-access',
  );

export const officeDepartmentsPortalAuthLinksSection = (
  regularDeclarationVisible: boolean,
  mobileDeclarationVisible: boolean,
) =>
  new NavItemsSectionConfig([
    getDropdownNavItemConfig(
      regularDeclarationVisible,
      mobileDeclarationVisible,
    ),
  ]);

export const officeDepartmentsPortalNoAuthLinksSection = (
  regularDeclarationVisible: boolean,
  mobileDeclarationVisible: boolean,
) =>
  new NavItemsSectionConfig([
    getDropdownNavItemConfig(
      regularDeclarationVisible,
      mobileDeclarationVisible,
    ),
  ]);

export const officeDepartmentsPortalRequestsListAuthLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    spatialInformationNavItemConfig,
    new LinkNavItemConfig(
      OfficeDepartmentsPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/office-departments-portal/requests-list',
      'list',
      true,
      true,
    ),
  ]);

export enum StreetNamingPortalLinkId {
  NewRequestLink = 'street-naming-portal_new-request',
  RequestsListLink = 'street-naming-portal_requests-list',
}

export const streetNamingPortalLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    StreetNamingPortalLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.StreetNaming}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    StreetNamingPortalLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.StreetNaming}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum LandAndBuildingRegisterDataChangesNotificationLinkId {
  NewRequestLink = 'land-and-building-register-data-changes-notification_new-request',
  RequestsListLink = 'land-and-building-register-data-changes-notification_requests-list',
}

export const landAndBuildingRegisterDataChangesNotificationRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataChangesNotificationLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataChangesNotificationLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum LandAndBuildingRegisterDataUpdateRequestLinkId {
  NewRequestLink = 'land-and-building-register-data-update-request_new-request',
  RequestsListLink = 'land-and-building-register-data-update-request_requests-list',
}

export const landAndBuildingRegisterDataUpdateRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataUpdateRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.LandAndBuildingRegisterDataUpdateRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataUpdateRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.LandAndBuildingRegisterDataUpdateRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum LandAndBuildingRegisterDataUpdateUponEntitiesRequestLinkId {
  NewRequestLink = 'land-and-building-register-data-update-upon-entities-request_new-request',
  RequestsListLink = 'land-and-building-register-data-update-upon-entities-request_requests-list',
}

export const landAndBuildingRegisterDataUpdateUponEntitiesRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataUpdateUponEntitiesRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.LandAndBuildingRegisterDataUpdateUponEntitiesRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      LandAndBuildingRegisterDataUpdateUponEntitiesRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.LandAndBuildingRegisterDataUpdateUponEntitiesRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum SoilClassificationOfLandLinkId {
  NewRequestLink = 'soil-classification-of-land_new-request',
  RequestsListLink = 'soil-classification-of-land_requests-list',
}

export const soilClassificationOfLandRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      SoilClassificationOfLandLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.SoilClassificationOfLandRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      SoilClassificationOfLandLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.SoilClassificationOfLandRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum PresentationCommentsLandBuildingRegistryProjectLinkId {
  NewRequestLink = 'presentation-comments-land-building-registry-project_new-request',
  RequestsListLink = 'presentation-comments-land-building-registry-project-list',
}

export const presentationCommentsLandBuildingRegistryProjectRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      PresentationCommentsLandBuildingRegistryProjectLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.PresentationCommentsLandBuildingRegistryProject}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      PresentationCommentsLandBuildingRegistryProjectLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PresentationCommentsLandBuildingRegistryProject}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum AccessToInformationOnEnvironmentAndItsProtectionLinkId {
  NewRequestLink = 'access-to-information-on-environment-and-its-protection_new-request',
  RequestsListLink = 'access-to-information-on-environment-and-its-protection_requests-list',
}

export const accessToInformationOnEnvironmentAndItsProtectionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      AccessToInformationOnEnvironmentAndItsProtectionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.AccessToInformationOnEnvironmentAndItsProtection}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      AccessToInformationOnEnvironmentAndItsProtectionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.AccessToInformationOnEnvironmentAndItsProtection}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum BuildingPermitLinkId {
  NewRequestLink = 'building-permit_new-request',
  RequestsListLink = 'building-permit_requests-list',
}

export const buildingPermitLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    BuildingPermitLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.BuildingPermit}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    BuildingPermitLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.BuildingPermit}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum DemolitionPermitLinkId {
  NewRequestLink = 'building-permit_new-request',
  RequestsListLink = 'building-permit_requests-list',
}

export const demolitionPermitLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    DemolitionPermitLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.DemolitionPermit}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    DemolitionPermitLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.DemolitionPermit}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum BuildingOrPartUseChangeLinkId {
  NewRequestLink = 'building-permit_new-request',
  RequestsListLink = 'building-permit_requests-list',
}

export const buildingOrPartUseChangeLinksSection = new NavItemsSectionConfig([
  officeDepartmentsNavItemConfig,
  new LinkNavItemConfig(
    BuildingOrPartUseChangeLinkId.NewRequestLink,
    true,
    'Nowy wniosek',
    `/${MainRoutes.BuildingOrPartUseChange}/new-request`,
    'plus',
    true,
    true,
  ),
  new LinkNavItemConfig(
    BuildingOrPartUseChangeLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.BuildingOrPartUseChange}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export enum TemporaryStructureBuildingPermitLinkId {
  NewRequestLink = 'temporary-structure-building-permit_new-request',
  RequestsListLink = 'temporary-structure-building-permit_requests-list',
}

export const temporaryStructureBuildingPermitLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      TemporaryStructureBuildingPermitLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.TemporaryStructureBuildingPermit}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      TemporaryStructureBuildingPermitLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.TemporaryStructureBuildingPermit}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum BuildingPermitModificationDecisionLinkId {
  NewRequestLink = 'building-permit-modification-decision_new-request',
  RequestsListLink = 'building-permit-modification-decision_requests-list',
}

export const buildingPermitModificationDecisionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      BuildingPermitModificationDecisionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.BuildingPermitModificationDecision}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      BuildingPermitModificationDecisionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.BuildingPermitModificationDecision}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum NotObjectedNotificationRightsObligationsTransferLinkId {
  NewRequestLink = 'not-objected-notification-rights-obligations-transfer_new-request',
  RequestsListLink = 'not-objected-notification-rights-obligations-transfer_requests-list',
}

export const notObjectedNotificationRightsObligationsTransferLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      NotObjectedNotificationRightsObligationsTransferLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.NotObjectedNotificationRightsObligationsTransfer}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      NotObjectedNotificationRightsObligationsTransferLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.NotObjectedNotificationRightsObligationsTransfer}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum PlotLandDevArchConstrProjSeparateDecisionLinkId {
  NewRequestLink = 'plot-land-dev-arch-constr-proj-separate-decision_new-request',
  RequestsListLink = 'plot-land-dev-arch-constr-proj-separate-decision_requests-list',
}

export const plotLandDevArchConstrProjSeparateDecisionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      PlotLandDevArchConstrProjSeparateDecisionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.PlotLandDevArchConstrProjSeparateDecision}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      PlotLandDevArchConstrProjSeparateDecisionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PlotLandDevArchConstrProjSeparateDecision}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum AdjacentPropertyNeedEnterDecisionLinkId {
  NewRequestLink = 'adjacent-property-need-enter-decision_new-request',
  RequestsListLink = 'adjacent-property-need-enter-decision_requests-list',
}

export const adjacentPropertyNeedEnterDecisionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      AdjacentPropertyNeedEnterDecisionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.AdjacentPropertyNeedEnterDecision}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      AdjacentPropertyNeedEnterDecisionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.AdjacentPropertyNeedEnterDecision}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum SingleFamilyBuilding70ConstructionConversionLinkId {
  NewRequestLink = 'single-family-building-70-construction-conversion_new-request',
  RequestsListLink = 'single-family-building-70-construction-conversion_requests-list',
}

export const singleFamilyBuilding70ConstructionConversionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      SingleFamilyBuilding70ConstructionConversionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.SingleFamilyBuilding70ConstructionConversion}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      SingleFamilyBuilding70ConstructionConversionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.SingleFamilyBuilding70ConstructionConversion}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum SingleFamilyBuildingConstructionConversionLinkId {
  NewRequestLink = 'single-family-building-construction-conversion_new-request',
  RequestsListLink = 'single-family-building-construction-conversion_requests-list',
}

export const singleFamilyBuildingConstructionConversionLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      SingleFamilyBuildingConstructionConversionLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.SingleFamilyBuildingConstructionConversion}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      SingleFamilyBuildingConstructionConversionLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.SingleFamilyBuildingConstructionConversion}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum GeodeticAndCartographicServiceDataRequestLinkId {
  NewRequestLink = 'geodetic-and-cartographic-service-data-request_new-request',
  RequestsListLink = 'geodetic-and-cartographic-service-data-request_requests-list',
}

export const geodeticAndCartographicServiceDataRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      GeodeticAndCartographicServiceDataRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.GeodeticAndCartographicServiceDataRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      GeodeticAndCartographicServiceDataRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.GeodeticAndCartographicServiceDataRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export const spatialInformationPortalNavItemConfig = new NavItemsSectionConfig(
  [],
);

export const officeDepartmentsPortalRequestsListNoAuthLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    spatialInformationNavItemConfig,
  ]);

export const addressNumberRequestPortalLinksSection = new NavItemsSectionConfig(
  [
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      AddressNumberRequestPortalLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      '/address-number-request-portal/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      AddressNumberRequestPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/address-number-request-portal/requests-list',
      'list',
      true,
      true,
    ),
  ],
);

export const getOfficeDepartmentsPortalDepartmentsListNavSections = (
  isAuthenticated: boolean,
  isGuestUserSession: boolean,
  regularDeclarationVisible: boolean,
  mobileDeclarationVisible: boolean,
): NavItemsSectionConfig[] => {
  const shouldIncludeDeclarationSections =
    regularDeclarationVisible || mobileDeclarationVisible;

  const authSection = shouldIncludeDeclarationSections
    ? officeDepartmentsPortalAuthLinksSection(
        regularDeclarationVisible,
        mobileDeclarationVisible,
      )
    : null;

  const noAuthSection = shouldIncludeDeclarationSections
    ? officeDepartmentsPortalNoAuthLinksSection(
        regularDeclarationVisible,
        mobileDeclarationVisible,
      )
    : null;

  return isAuthenticated
    ? [
        ...(authSection ? [authSection] : []),
        eServicesLogoSection,
        getOfficeDepartmentsRightSectionDependingOnGuestSession(
          isGuestUserSession,
        ),
      ]
    : [
        ...(noAuthSection ? [noAuthSection] : []),
        eServicesLogoSection,
        officeDepartmentsRightSection,
      ];
};

export const getOfficeDepartmentsPortalRequestsListNavSections = (
  isAudioDescriptionVisible: boolean,
  translateVisibleKeys: TranslationVisibilityPortal,
  isGuestSession: boolean,
): NavItemsSectionConfig[] => [
  officeDepartmentsPortalRequestsListNoAuthLinksSection,
  eServicesLogoSection,
  isAudioDescriptionVisible
    ? officeDepartmentsRightSectionWithAudioDescription(
        translateVisibleKeys,
        isGuestSession,
      )
    : getOfficeDepartmentsRightSectionWithTranslation(
        translateVisibleKeys,
        isGuestSession,
      ),
];

export const getSpatialInformationPortalRequestsListNavSections = (
  isAudioDescriptionAndTranslationsVisible: boolean,
  translateVisibleKeys: TranslationVisibilityPortal,
) => {
  return [
    spatialInformationPortalNavItemConfig,
    eServicesLogoSection,
    isAudioDescriptionAndTranslationsVisible
      ? spatialInformationPortalRightSectionWithoutAuthWithAudioDescriptionAndTranslations(
          translateVisibleKeys,
        )
      : rightSectionWithoutAuth,
  ];
};

export const planningDepartmentAuthLinksSection = new NavItemsSectionConfig([
  new LinkNavItemConfig(
    PlanningDepartmentLinkId.RequestsListLink,
    true,
    'Moje wnioski',
    `/${MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan}/requests-list`,
    'list',
    true,
    true,
  ),
]);

export const getPlanningDepartmentRequestsListNavSections = (
  isAuthenticated: boolean,
) =>
  isAuthenticated
    ? [
        planningDepartmentAuthLinksSection,
        eServicesLogoSection,
        officeDepartmentsRightSection,
      ]
    : [eServicesLogoSection, officeDepartmentsRightSection];

export const constructionPortalConstructionLogLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      ConstructionPortalLinkId.ConstructionLogRequestLink,
      true,
      'Nowy wniosek',
      '/construction-portal-construction-log-request/new-request',
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      ConstructionPortalLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      '/construction-portal-construction-log-request/requests-list',
      'list',
      true,
      true,
    ),
  ]);

const planningDepartmentRoute = `/${MainRoutes.Department}/${DepartmentCode.Planning}`;

export const planningDepartmentExtractFromLocalSpatialDevelopmentPlanLinksSection =
  new NavItemsSectionConfig([
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.ExtractFromLocalSpatialDevelopmentPlanRequestLink,
      true,
      'Nowy wniosek',
      planningDepartmentRoute,
      'plus',
      true,
      true,
      { newRequestFromPlanningDepartmentMode: true },
    ),
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export const planningDepartmentCertificateFromLocalSpatialDevelopmentPlanLinksSection =
  new NavItemsSectionConfig([
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.ExtractFromLocalSpatialDevelopmentPlanRequestLink,
      true,
      'Nowy wniosek',
      planningDepartmentRoute,
      'plus',
      true,
      true,
      { newRequestFromPlanningDepartmentMode: true },
    ),
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export const planningDepartmentExtractFromStudyLinksSection =
  new NavItemsSectionConfig([
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.ExtractFromLocalSpatialDevelopmentPlanRequestLink,
      true,
      'Nowy wniosek',
      planningDepartmentRoute,
      'plus',
      true,
      true,
      { newRequestFromPlanningDepartmentMode: true },
    ),
    new LinkNavItemConfig(
      PlanningDepartmentLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PlanningDepartmentExtractFromStudy}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum PermanentManagementFeeDiscountDecisionGrantingRequestLinkId {
  NewRequestLink = 'permanent-management-fee-discount-decision-granting-request_new-request',
  RequestsListLink = 'permanent-management-fee-discount-decision-granting-request_requests-list',
}

export const permanentManagementFeeDiscountDecisionGrantingRequestLinksSection =
  new NavItemsSectionConfig([
    officeDepartmentsNavItemConfig,
    new LinkNavItemConfig(
      PermanentManagementFeeDiscountDecisionGrantingRequestLinkId.NewRequestLink,
      true,
      'Nowy wniosek',
      `/${MainRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest}/new-request`,
      'plus',
      true,
      true,
    ),
    new LinkNavItemConfig(
      PermanentManagementFeeDiscountDecisionGrantingRequestLinkId.RequestsListLink,
      true,
      'Moje wnioski',
      `/${MainRoutes.PermanentManagementFeeDiscountDecisionGrantingRequest}/requests-list`,
      'list',
      true,
      true,
    ),
  ]);

export enum FontDropdownItemId {
  Default = 'font_default',
  Large = 'font_large',
  ExtraLarge = 'font_extra-large',
}
export const fontDropdownId = 'font_dropdown';
export const fontNavItemConfig = [
  new DropdownNavItemConfig(
    fontDropdownId,
    true,
    'SHOP_NAVBAR.FONT.LABEL_AA',
    'SHOP_NAVBAR.FONT.LABEL_TITLE',
    [
      new ActionButtonNavItemConfig(
        FontDropdownItemId.Default,
        true,
        'SHOP_NAVBAR.FONT.DEFAULT_A',
        'SHOP_NAVBAR.FONT.DEFAULT_TITLE',
        true,
      ),
      new ActionButtonNavItemConfig(
        FontDropdownItemId.Large,
        true,
        'SHOP_NAVBAR.FONT.LARGE_A',
        'SHOP_NAVBAR.FONT.LARGE_TITLE',
        true,
      ),
      new ActionButtonNavItemConfig(
        FontDropdownItemId.ExtraLarge,
        true,
        'SHOP_NAVBAR.FONT.EXTRA_LARGE_A',
        'SHOP_NAVBAR.FONT.EXTRA_LARGE_TITLE',
        true,
      ),
    ],
    false,
    undefined,
    '',
    false,
    'dropdown-menu--min-width-content',
  ),
];
export const fontNavItemConfig_ = [
  new ActionButtonNavItemConfig(
    FontDropdownItemId.Default,
    true,
    'A',
    'Domyślna',
    false,
  ),
  new ActionButtonNavItemConfig(
    FontDropdownItemId.Large,
    true,
    'A+',
    'Duża',
    false,
  ),
  new ActionButtonNavItemConfig(
    FontDropdownItemId.ExtraLarge,
    true,
    'A++',
    'Bardzo duża',
    false,
  ),
];

export enum AuthDropdownItemId {
  Edit = 'auth_edit_data',
  Login = 'auth_login',
  Logout = 'auth_logout',
  SystemLogout = 'auth_system_logout',
  UserSettings = 'auth_user_settings',
  UserInfo = 'user_info',
}

export enum PortalTranslationDropdownItemId {
  PolishTranslation = 'polish_translation',
  EnglishTranslation = 'english_translation',
  GermanTranslation = 'german_translation',
  CzechTranslation = 'czech_translation',
}

export const authDropdownId = 'auth_dropdown';

export const userSettingsActionButton = new ActionButtonNavItemConfig(
  AuthDropdownItemId.UserSettings,
  false,
  'Ustawienia',
  '',
  true,
);

export const userInfoActionButton = new ActionButtonNavItemConfig(
  AuthDropdownItemId.UserInfo,
  true,
  'Nazwa Użytkownika',
  'Nazwa Użytkownika',
  false,
  'user-o fa-sm',
  true,
  true,
  false,
  undefined,
  'user-info-btn-item w-100 d-flex flex-column align-items-center',
);

export const editUserActionButton = new ActionButtonNavItemConfig(
  AuthDropdownItemId.Edit,
  false,
  'Edytuj dane',
  '',
  true,
);

export const authNavItemsForLoggedInUser = [
  userInfoActionButton,
  editUserActionButton,
  userSettingsActionButton,
  new ActionButtonNavItemConfig(
    AuthDropdownItemId.Logout,
    true,
    'Wyloguj się',
    '',
    true,
  ),
];

export const officeDepartmentsAuthNavItemsForLoggedInUser = [
  userInfoActionButton,
  userSettingsActionButton,
  new ActionButtonNavItemConfig(
    AuthDropdownItemId.Logout,
    true,
    'Wyloguj się',
    'Wyloguj się',
    true,
  ),
];

export const authDropdownNavItemConfig = new DropdownNavItemConfig(
  authDropdownId,
  true,
  '',
  '',
  authNavItemsForLoggedInUser,
  false,
  true,
  'user',
  false,
  'pt-0 vertical-mobile-orientation',
);

export const officeDepartmentsAuthDropdownNavItemConfig =
  new DropdownNavItemConfig(
    authDropdownId,
    true,
    '',
    '',
    officeDepartmentsAuthNavItemsForLoggedInUser,
    false,
    true,
    'user',
    false,
    'pt-0 vertical-mobile-orientation',
  );

export const getLanguageTranslationDropdownNavItemConfig = (
  translateVisibleKeys: TranslationVisibilityPortal,
) =>
  new DropdownNavItemConfig(
    DropdownNavItemId.TranslationDropdown,
    translateVisibleKeys.isVisibleTranslate,
    '',
    'NAVBAR.TRANSLATION.LABEL_TITLE',
    [
      new ActionButtonNavItemConfig(
        PortalTranslationDropdownItemId.PolishTranslation,
        true,
        'NAVBAR.TRANSLATION.POLISH',
        'NAVBAR.TRANSLATION.POLISH',
        true,
        '',
        false,
        false,
        false,
        '',
        '',
        'assets/flags/pl.png',
      ),
      new ActionButtonNavItemConfig(
        PortalTranslationDropdownItemId.EnglishTranslation,
        translateVisibleKeys.isVisibleEn,
        'NAVBAR.TRANSLATION.ENGLISH',
        'NAVBAR.TRANSLATION.ENGLISH',
        true,
        '',
        false,
        false,
        false,
        '',
        '',
        'assets/flags/gb.png',
      ),
      new ActionButtonNavItemConfig(
        PortalTranslationDropdownItemId.GermanTranslation,
        translateVisibleKeys.isVisibleGr,
        'NAVBAR.TRANSLATION.GERMAN',
        'NAVBAR.TRANSLATION.GERMAN',
        true,
        '',
        false,
        false,
        false,
        '',
        '',
        'assets/flags/gr.png',
      ),
      new ActionButtonNavItemConfig(
        PortalTranslationDropdownItemId.CzechTranslation,
        translateVisibleKeys.isVisibleCz,
        'NAVBAR.TRANSLATION.CZECH',
        'NAVBAR.TRANSLATION.CZECH',
        true,
        '',
        false,
        false,
        false,
        '',
        '',
        'assets/flags/cz.png',
      ),
    ],
    false,
    undefined,
    '',
    true,
    'dropdown-menu--min-width-content',
  );

export const navbarShowInfoServiceConfig = new ShowInfoServiceConfig(
  'info-service-button',
  true,
  'NAVBAR.INFO_SERVICE.LABEL',
  'NAVBAR.INFO_SERVICE.LABEL',
  'info-circle fa-lg',
);

export const remainingSessionTimeNavItemId = 'remaining-session-time';
export const remainingSessionTimeNavItemConfig = new SimpleTextNavItemConfig(
  remainingSessionTimeNavItemId,
  true,
  '',
  'Pozostały czas do końca sesji',
);

export enum NavbarItemId {
  Navbar = 'navbar',
  MapLink = 'map-link',
  LogoImageText = 'logo-image-text',
}

export const designerLogoImageTextNavItemConfig =
  new LogoImageTextNavItemConfig(NavbarItemId.LogoImageText, true, 'Portal');

export const logoSection = new NavItemsSectionConfig(
  [designerLogoImageTextNavItemConfig],
  false,
);

export const appraiserLogoImageTextNavItemConfig =
  new LogoImageTextNavItemConfig(
    NavbarItemId.LogoImageText,
    true,
    'mRzeczoznawca',
  );

export const appraiserLogoSection = new NavItemsSectionConfig(
  [appraiserLogoImageTextNavItemConfig],
  false,
);

export const eServicesWebPortalLogoNavItemConfig = new LogoImageNavItemConfig(
  NavbarItemId.LogoImageText,
  true,
);

export const eServicesLogoSection = new NavItemsSectionConfig(
  [eServicesWebPortalLogoNavItemConfig],
  false,
);

export const coordinationMeetingPortalLogoImageTextNavItemConfig =
  new LogoImageTextNavItemConfig(
    NavbarItemId.LogoImageText,
    true,
    'mKoordynator',
  );

export const bailiffPortalLogoImageTextNavItemConfig =
  new LogoImageTextNavItemConfig(NavbarItemId.LogoImageText, true, 'mKomornik');

export const coordinationMeetingPortalLogoSection = new NavItemsSectionConfig(
  [coordinationMeetingPortalLogoImageTextNavItemConfig],
  false,
);

export const bailiffPortalLogoSection = new NavItemsSectionConfig(
  [bailiffPortalLogoImageTextNavItemConfig],
  false,
);

export const rightSectionWithoutInfoNavItemConfig = new NavItemsSectionConfig(
  [
    ...fontNavItemConfig,
    contrastDropdownNavItemConfig,
    remainingSessionTimeNavItemConfig,
    authDropdownNavItemConfig,
  ],
  true,
);

export const rightSection = new NavItemsSectionConfig(
  [
    ...fontNavItemConfig,
    contrastDropdownNavItemConfig,
    remainingSessionTimeNavItemConfig,
    authDropdownNavItemConfig,
  ],
  true,
);

export const getNewPortalRightSection = (
  manualSectionItem: ManualSection,
): NavItemsSectionConfig => {
  return new NavItemsSectionConfig(
    [
      ...getRightSectionInfoConfig(manualSectionItem),
      ...fontNavItemConfig,
      contrastDropdownNavItemConfig,
      remainingSessionTimeNavItemConfig,
      authDropdownNavItemConfig,
    ],
    true,
  );
};

export const officeDepartmentsRightSection = new NavItemsSectionConfig(
  [
    ...fontNavItemConfig,
    contrastDropdownNavItemConfig,
    officeDepartmentsAuthDropdownNavItemConfig,
    navbarShowInfoServiceConfig,
  ],
  true,
);

export const officeDepartmentsRightSectionWithoutAuthDropdown =
  new NavItemsSectionConfig(
    [
      ...fontNavItemConfig,
      contrastDropdownNavItemConfig,
      navbarShowInfoServiceConfig,
    ],
    true,
  );

export const getOfficeDepartmentsRightSectionDependingOnGuestSession = (
  isGuestSession = false,
): NavItemsSectionConfig => {
  return !isGuestSession
    ? rightSectionWithoutInfoNavItemConfig
    : officeDepartmentsRightSectionWithoutAuthDropdown;
};

export const getOfficeDepartmentsRightSectionWithTranslation = (
  translateVisibleKeys: TranslationVisibilityPortal,
  isGuestSession: boolean,
) =>
  new NavItemsSectionConfig(
    [
      ...fontNavItemConfig,
      contrastDropdownNavItemConfig,
      ...(!isGuestSession ? [officeDepartmentsAuthDropdownNavItemConfig] : []),
      getLanguageTranslationDropdownNavItemConfig(translateVisibleKeys),
      navbarShowInfoServiceConfig,
    ],
    true,
  );

export const officeDepartmentsRightSectionWithAudioDescription = (
  translateVisibleKeys: TranslationVisibilityPortal,
  isGuestSession: boolean,
) =>
  new NavItemsSectionConfig(
    [
      ...fontNavItemConfig,
      contrastDropdownNavItemConfig,
      ...(!isGuestSession ? [officeDepartmentsAuthDropdownNavItemConfig] : []),
      getLanguageTranslationDropdownNavItemConfig(translateVisibleKeys),
      navbarShowInfoServiceConfig,
      new AudioDescriptionToggleButtonNavItemConfig(
        'pok-portal-audio-description',
        true,
        'NAVBAR.AUDIO_DESCRIPTION.LABEL',
        'NAVBAR.AUDIO_DESCRIPTION.LABEL',
        'assets/audio/pok_portal.mp3',
        false,
      ),
    ],
    true,
  );

export const rightSectionWithoutAuth = new NavItemsSectionConfig(
  [...fontNavItemConfig, contrastDropdownNavItemConfig],
  true,
);

export const spatialInformationPortalRightSectionWithoutAuthWithAudioDescriptionAndTranslations =
  (translateVisibleKeys: TranslationVisibilityPortal) =>
    new NavItemsSectionConfig(
      [
        ...fontNavItemConfig,
        contrastDropdownNavItemConfig,
        getLanguageTranslationDropdownNavItemConfig(translateVisibleKeys),
        new AudioDescriptionToggleButtonNavItemConfig(
          'e-services-portal-audio-description',
          true,
          'NAVBAR.AUDIO_DESCRIPTION.LABEL',
          'NAVBAR.AUDIO_DESCRIPTION.LABEL',
          'assets/audio/e_services_portal.mp3',
          false,
        ),
      ],
      true,
    );

export const getPrzpNavSections = (
  przpAgreementEnabled: boolean,
): NavItemsSectionConfig[] => [
  getPrzpLinksSection(przpAgreementEnabled),
  logoSection,
  rightSection,
];

export const designerNavSections = [
  designerLinksSection,
  logoSection,
  rightSection,
];

export const surveyorNavSections = [
  surveyorLinksSection,
  logoSection,
  getNewPortalRightSection(ManualSection.Geodesy),
];

export const appraiserNavSections = [
  appraiserLinksSection,
  appraiserLogoSection,
  getNewPortalRightSection(ManualSection.Appraiser),
];

export const coordinationMeetingPortalNavSections = [
  coordinationMeetingLinksSection,
  coordinationMeetingPortalLogoSection,
  getNewPortalRightSection(ManualSection.Coordination),
];

export const bailiffNavSections = [
  bailiffPortalLinksSection,
  bailiffPortalLogoSection,
  getNewPortalRightSection(ManualSection.Bailiff),
];

export const grantingRightsNavSections = [
  grantingRightsLinksSection,
  logoSection,
  rightSection,
];

export const ecoPortalNavSections = [
  ecoPortalLinksSection,
  logoSection,
  rightSectionWithoutAuth,
];

export const buildingPermitTransferPortalNavSections = [
  buildingPermitTransferPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const propertyLeasePortalNavSections = [
  propertyLeasePortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const supplementaryParcelPurchasePortalNavSections = [
  supplementaryParcelPurchasePortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const ownershipConversionPortalNavSections = [
  ownershipConversionPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const ownershipConversionFeeCalculationNavSections = [
  ownershipConversionFeeCalculationLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const communalPremisePortalNavSections = [
  communalPremisePurchasePortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const roadExitPortalNavSections = [
  roadExitPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const constructionPortalConstructionNotificationNavSections = [
  constructionPortalConstructionNotificationLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const constructionPortalDemolitionNotificationNavSections = [
  constructionPortalDemolitionNotificationLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const constructionPortalPremiseIndependenceNavSections = [
  constructionPortalPremiseIndependenceLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const constructionPortalConstructionLogNavSections = [
  constructionPortalConstructionLogLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const laneOccupancyInfrastructureDevicesPortalNavSections = [
  laneOccupancyInfrastructureDevicesPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const laneOccupancyBuildingObjectsPortalNavSections = [
  laneOccupancyBuildingObjectsPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const laneOccupancyBuildingWorksPortalNavSections = [
  laneOccupancyBuildingWorksPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const landAndBuildingRegisterDataChangesNotificationRequestNavSections =
  [
    landAndBuildingRegisterDataChangesNotificationRequestLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const landAndBuildingRegisterDataUpdateRequestNavSections = [
  landAndBuildingRegisterDataUpdateRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const landAndBuildingRegisterDataUpdateUponEntitiesRequestNavSections = [
  landAndBuildingRegisterDataUpdateUponEntitiesRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const soilClassificationOfLandRequestNavSections = [
  soilClassificationOfLandRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const presentationCommentsLandBuildingRegistryProjectRequestNavSections =
  [
    presentationCommentsLandBuildingRegistryProjectRequestLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const accessToInformationOnEnvironmentAndItsProtectionNavSections = [
  accessToInformationOnEnvironmentAndItsProtectionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const buildingPermitNavSections = [
  buildingPermitLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const buildingOrPartUseChangeNavSections = [
  buildingOrPartUseChangeLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const temporaryStructureBuildingPermitNavSections = [
  temporaryStructureBuildingPermitLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const buildingPermitModificationDecisionNavSections = [
  buildingPermitModificationDecisionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const notObjectedNotificationRightsObligationsTransferNavSections = [
  notObjectedNotificationRightsObligationsTransferLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const plotLandDevArchConstrProjSeparateDecisionNavSections = [
  plotLandDevArchConstrProjSeparateDecisionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const adjacentPropertyNeedEnterDecisionNavSections = [
  adjacentPropertyNeedEnterDecisionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const singleFamilyBuilding70ConstructionConversionNavSections = [
  singleFamilyBuilding70ConstructionConversionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const singleFamilyBuildingConstructionConversionNavSections = [
  singleFamilyBuildingConstructionConversionLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const geodeticAndCartographicServiceDataRequestNavSections = [
  geodeticAndCartographicServiceDataRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const demolitionPermitNavSections = [
  demolitionPermitLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const nonAgriculturalLandUseRequestNavSections = [
  nonAgriculturalLandUseRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const agriculturalLandUseRequestNavSections = [
  agriculturalLandUseRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const environmentalProtectionPortalSimplifiedForestManagementPlanRequestNavSections =
  [
    environmentalProtectionPortalSimplifiedForestManagementPlanRequestLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const environmentalProtectionPortalFishingCardRequestNavSections = [
  environmentalProtectionPortalFishingCardRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const environmentalProtectionPortalWaterEquipmentRequestNavSections = [
  environmentalProtectionPortalWaterEquipmentRequestLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const environmentalProtectionPortalTreesAndShrubsFellingRequestNavSections =
  [
    environmentalProtectionPortalTreesAndShrubsFellingRequestLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const addressNumberRequestPortalNavSections = [
  addressNumberRequestPortalLinksSection,
  logoSection,
  rightSection,
];

export const undeterminedPortalNavSections = [
  new NavItemsSectionConfig([
    new LogoImageTextNavItemConfig(NavbarItemId.LogoImageText, true, 'Portal'),
  ]),
  rightSection,
];

export const hubNavbarConfig = new DynamicNavbarConfig(
  new GeneralNavConfig(NavbarItemId.Navbar, BsColor.ColorFromDb),
  undeterminedPortalNavSections,
);

export const planningDepartmentExtractFromLocalSpatialDevelopmentPlanNavSections =
  [
    planningDepartmentExtractFromLocalSpatialDevelopmentPlanLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const planningDepartmentCertificateFromLocalSpatialDevelopmentPlanNavSections =
  [
    planningDepartmentCertificateFromLocalSpatialDevelopmentPlanLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const planningDepartmentExtractFromStudyNavSections = [
  planningDepartmentExtractFromStudyLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const streetNamingPortalNavSections = [
  streetNamingPortalLinksSection,
  eServicesLogoSection,
  rightSection,
];

export const permanentManagementFeeDiscountDecisionGrantingRequestNavSections =
  [
    permanentManagementFeeDiscountDecisionGrantingRequestLinksSection,
    eServicesLogoSection,
    rightSection,
  ];

export const noAuthNavSections = [
  new NavItemsSectionConfig([officeDepartmentsNavItemConfig]),
  eServicesLogoSection,
  officeDepartmentsRightSection,
];
