import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { BuildingDetailsGridDataService } from '../map-object-info/building-details-wrapper/building-details-grid/building-details-grid-data.service';
import {
  BuildingDetails,
  BuildingDetailsDto,
} from './map-building-details.model';

@Component({
  selector: 'gk-map-building-details',
  templateUrl:
    '../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
  standalone: false,
})
export class MapBuildingDetailsComponent
  extends BaseDetailsComponent<BuildingDetailsDto>
  implements OnInit
{
  override parseCallback = BuildingDetails.fromApiToApp;

  constructor(
    private buildingDetailsGridDataService: BuildingDetailsGridDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAddressPointChange();
  }

  private subscribeToAddressPointChange(): void {
    this.buildingDetailsGridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItem = selection?.selectedItems[0];
        if (selectedItem) {
          this.loadDetailsWithData(selectedItem);
        }
      });
  }

  loadData(data: BuildingDetailsDto): void {
    this.loadDetailsWithData(data);
  }
}
