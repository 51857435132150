import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemName,
  GridToolBarItem,
} from '@gk/gk-modules';
import { FspBuildingsGridComponent } from './fsp-buildings-grid.component';

export const getFspBuildingsGridToolbarItems = (
  c: FspBuildingsGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: 'print',
      iconClass: 'fa fa-print',
      title: 'FREE_PORTAL_SERVICES.PRINT',
      callback: () => c.downloadReport(),
    },
    {
      name: GenericGridToolbarItemName.ShowGeometry,
      payload: () => ({
        url: `/api/interesant/przp/dzialka/${c.selectedParcelGridItem.KdzialkaUuid}/budynek/{Uuid}/geom`,
      }),
    },
  ];
};

export const fspBuildingsGridColumns: ColumnSetting[] = [
  {
    field: 'RodzajKstNazwa',
    title: 'Rodzaj wg KŚT',
    type: ColumnType.Text,
    width: 150,
  },
  {
    field: 'NrKw',
    title: 'Nr KW',
    type: ColumnType.Text,
    width: 180,
  },
  {
    field: 'Adres',
    title: 'Adres',
    type: ColumnType.Text,
    width: 210,
  },
  {
    field: 'KondygnacjeNadziemne',
    title: 'Kond nad.',
    type: ColumnType.Text,
    width: 80,
  },
  {
    field: 'KondygnacjePodziemne',
    title: 'Kond pod.',
    type: ColumnType.Text,
    width: 80,
  },
  {
    field: 'PowierzchniaZabudowyM2',
    title: 'Pow. zab. [m2]',
    type: ColumnType.Text,
  },
];
