import { ColumnSetting, ColumnType, GridToolBarItem } from '@gk/gk-modules';

export const getFspDocumentsRequestGridToolbarItems = (): GridToolBarItem[] => {
  return [];
};

export const fspSubjectRequestGridColumns: ColumnSetting[] = [
  {
    field: 'PozycjeZamowienia',
    title: 'Przedmiot zamówienia',
    type: ColumnType.Text,
    width: 320,
  },
  {
    field: 'IleJedn',
    title: 'Ilość jedn.',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'Uwagi',
    title: 'Uwagi',
    type: ColumnType.Text,
  },
];
