import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemName,
  GridToolBarItem,
  iwIconColorBud,
  iwIconColorKlu,
  iwIconColorLo,
  iwIconPkt,
  iwIconPlus,
} from '@gk/gk-modules';
import { FreeServicesParcelRequestGridComponent } from './free-services-parcel-request-grid.component';

export const getFreeServicesParcelRequestGridToolbarItems = (
  c: FreeServicesParcelRequestGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: 'searchParcels',
      svgIcon: iwIconPlus,
      className: 'svg-icon-blue',
      title: 'FREE_PORTAL_SERVICES.SEARCH',
      text: 'FREE_PORTAL_SERVICES.SEARCH',
      showText: 'overflow',
      callback: () => c.openSearchParcelsWindow(),
    },
    {
      name: 'borderPoints',
      svgIcon: iwIconPkt,
      title: 'FREE_PORTAL_SERVICES.BORDER_POINTS',
      callback: () => c.handleOpenBorderPoints(),
    },
    {
      name: 'classUse',
      svgIcon: iwIconColorKlu,
      title: 'FREE_PORTAL_SERVICES.CLASS_USE',
      callback: () => c.handleOpenClassUse(),
    },
    {
      name: 'budilding',
      svgIcon: iwIconColorBud,
      title: 'FREE_PORTAL_SERVICES.BUILDINGS',
      callback: () => c.handleOpenBuildings(),
    },
    {
      name: 'premisses',
      svgIcon: iwIconColorLo,
      title: 'FREE_PORTAL_SERVICES.PREMISSES',
      callback: () => c.handleOpenPremisses(),
    },
    {
      name: GenericGridToolbarItemName.ShowGeometry,
      payload: () => ({
        url: '/api/interesant/przp/dzialka/{KdzialkaUuid}/geom',
      }),
    },
    {
      name: 'print',
      iconClass: 'fa fa-print',
      title: 'FREE_PORTAL_SERVICES.PRINT',
      callback: () => c.downloadReport(),
    },
  ];
};

export const freeServicesParcelRequestGridColumns: ColumnSetting[] = [
  {
    field: 'Obreb',
    title: 'FREE_PORTAL_SERVICES.DISTRICT',
    type: ColumnType.Text,
  },
  {
    field: 'Adres',
    title: 'FREE_PORTAL_SERVICES.ADDRESS',
    type: ColumnType.Text,
  },
  {
    field: 'Numer',
    title: 'FREE_PORTAL_SERVICES.NUMBER',
    type: ColumnType.Text,
    width: 100,
  },
];
