export enum RequestType {
  Post = 'POST',
  Get = 'GET',
  Delete = 'DELETE',
}

export interface AbortRequestConfig {
  isAbortRequest: boolean;
  callback?: () => void;
}
