import { AfterViewInit, Component } from '@angular/core';
import { GkKendoGridComponent, KlasouzytekResponseDto } from '@gk/gk-modules';
import { FspClassUseGridDataService } from './fsp-class-use-grid-data.service';
import {
  getFspClassUseGridToolbarItems,
  fspClassUseGridColumns,
} from './fsp-class-use-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesParcelRequestGridDto } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data-model';
import { FreeServicesParcelRequestGridDataService } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';

@Component({
  selector: 'app-fsp-class-use-grid',
  templateUrl:
    '../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspClassUseGridComponent
  extends GkKendoGridComponent<KlasouzytekResponseDto>
  implements AfterViewInit
{
  constructor(
    public fspClassUseGridDataService: FspClassUseGridDataService,
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
  ) {
    super(fspClassUseGridDataService);
  }

  override topGridToolbarItems = getFspClassUseGridToolbarItems();
  override columns = fspClassUseGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  selectedParcelGridItem: FreeServicesParcelRequestGridDto;

  override ngAfterViewInit(): void {
    this.subscribeToRequestListGridSelectedKeys();
  }

  subscribeToRequestListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelGridItem = selection.selectedRows[0].dataItem;
        this.fetchGridData();
      });
  }

  fetchGridData(): void {
    this.fspClassUseGridDataService.$loading.next(true);
    this.freeServicesParcelRequestGridDataService
      .fetchParcelDetailsFromApi(this.selectedParcelGridItem.KdzialkaUuid)
      .subscribe((data) => {
        this.fspClassUseGridDataService.$loading.next(false);
        this.fspClassUseGridDataService.next({
          data: data.Klasouzytki,
          total: data.Klasouzytki.length,
        });
      });
  }
}
