import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemName,
  GridToolBarItem,
} from '@gk/gk-modules';

export const getFspDocumentsRequestGridToolbarItems = (): GridToolBarItem[] => {
  return [
    {
      name: GenericGridToolbarItemName.DownloadFile,
      payload: () => ({
        downloadUrl: '/api/interesant/edok/download',
      }),
    },
  ];
};

export const apDocumentsRequestGridColumns: ColumnSetting[] = [
  {
    field: 'DataD',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.ADD_DATE',
    type: ColumnType.Date,
    width: 120,
  },
  {
    field: 'Nazwa',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.NAME',
    type: ColumnType.Text,
  },
  {
    field: 'Opis',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.DESCRIPTION',
    type: ColumnType.Text,
  },
  {
    field: 'Rozmiar',
    title: 'M_APPRAISER.REQUESTS.COLUMNS.SIZE',
    type: ColumnType.Text,
    width: 120,
  },
];
