export interface DraftJsonBody {
  Type: string;
  Value: string;
}

export enum DraftType {
  NewOriginalRequestSurveyorPortal = 'mgeodeta',
  TransactionGridDataAppraiserPortal = 'mrzeczoznawca',
  BailiffPortalOrderRequest = 'mkomornik',
}
