import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';
import { AdressPointDetailsGridDto } from '../map-address-point-details-grid/map-address-point-details-grid-data-model';
export class AdressPointDetails {
  static fromApiToApp(data: AdressPointDetailsGridDto): BaseDetailSettings[] {
    return [
      {
        title: 'ADDRESS_POINT.PROVINCE',
        value: data?.Wojewodztwo,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.COUNTY',
        value: data?.Powiat,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.COMMUNE',
        value: data?.Gmina,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.AUXILIARY_UNIT',
        value: data?.Gmina,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.AUXILIARY_UNIT_NAME',
        value: data?.NazwaJednPomoc,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.LOCALITY',
        value: data?.Miejscowosc,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.STREET',
        value: data?.Ulica,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.HOUSE_NUMBER',
        value: data?.NumerPorz,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.POSTAL_OFFICE',
        value: data?.Poczta,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.LAND',
        value: data?.Dzialka,
        type: DetailType.Text,
      },
      {
        title: 'ADDRESS_POINT.BUILDINGS',
        value: data?.Budynki,
        type: DetailType.Text,
      },
    ];
  }
}
