import { Component } from '@angular/core';
import { FormlyFieldKendoSelectComponent } from './select.type';

@Component({
  selector: 'formly-field-kendo-multiselect',
  template: `
    <kendo-multiselect
      [size]="'small'"
      [ngClass]="{ required: props.required }"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="handleOptions(field.props.options) | async"
      [textField]="props.labelProp"
      [valueField]="props.valueProp"
      [valuePrimitive]="props.valuePrimitive ?? true"
      [placeholder]="props.placeholder! | translate"
    ></kendo-multiselect>
  `,
  standalone: false,
})
export class FormlyFieldKendoMultiSelectComponent extends FormlyFieldKendoSelectComponent {}
