import { AfterViewInit, Component } from '@angular/core';
import { GkKendoGridComponent, RequestType } from '@gk/gk-modules';
import { FspPremissesGridDataService } from './fsp-premisses-grid-data.service';
import { FspPremissesGridDto } from './fsp-premisses-grid-data-model';
import {
  fspPremissesGridColumns,
  getFspPremissesGridToolbarItems,
} from './fsp-premisses-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { FreeServicesParcelRequestGridDto } from '../../fsp-parcel-request-grid/free-services-parcel-request-grid-data-model';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesParcelRequestGridDataService } from '../../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';

@Component({
  selector: 'app-fsp-premisses-grid',
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspPremissesGridComponent
  extends GkKendoGridComponent<FspPremissesGridDto>
  implements AfterViewInit
{
  constructor(
    public fspPremissesGridDataService: FspPremissesGridDataService,
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
  ) {
    super(fspPremissesGridDataService);
  }

  override topGridToolbarItems = getFspPremissesGridToolbarItems(this);
  override columns = fspPremissesGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  selectedParcelGridItem: FreeServicesParcelRequestGridDto;
  selectedPremissesGridItem: FspPremissesGridDto;

  override ngAfterViewInit(): void {
    this.subscribeToRequestListGridSelectedKeys();
    this.subscribeToPremissesGridSelectedKeys();
  }

  subscribeToRequestListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelGridItem = selection.selectedRows[0].dataItem;
        this.fetchGridData();
      });
  }

  subscribeToPremissesGridSelectedKeys(): void {
    this.fspPremissesGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedPremissesGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedPremissesGridItem = selection.selectedRows[0].dataItem;
      });
  }

  fetchGridData(): void {
    this.fspPremissesGridDataService
      .queryByState(
        null,
        null,
        `/api/interesant/przp/dzialka/${this.selectedParcelGridItem.KdzialkaUuid}/lokale`,
      )
      .subscribe((response) => {
        if (!response.data.length) {
          this.toastr.warning(
            this.translateService.instant('FREE_PORTAL_SERVICES.NO_PREMISSES'),
          );
        }
      });
  }

  downloadReport(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    this.downloadService.downloadAndSaveFile(
      `/api/interesant/przp/dzialka/${this.selectedParcelGridItem.KdzialkaUuid}/lokal/${this.selectedPremissesGridItem.Uuid}/wydruk`,
      {},
      RequestType.Post,
    );
  }
}
