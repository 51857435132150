import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetailsService } from './base-details/base-details.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingPanelComponent } from './loading-panel/loading-panel.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@NgModule({
  imports: [CommonModule, TranslateModule, IndicatorsModule],
  declarations: [LoadingPanelComponent],
  exports: [LoadingPanelComponent],
  providers: [BaseDetailsService],
})
export class KendoAngularExtensionsModule {}
