import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, takeWhile } from 'rxjs';
import { KendoAngularExtensionsModule } from '../kendo-angular-extensions.module';
import { RequestType } from '../models/request.model';
import { GkWindowRef } from '../services/kendo-window/kendo-window.model';
import { DateWithoutTime } from '../utils/date-without-time/date-without-time';
import { BaseDetailSettings, DetailType } from './base-details.model';
import { BaseDetailsService } from './base-details.service';

@Component({
  selector: 'kendo-base-details',
  templateUrl: './base-details.component.html',
  imports: [
    TranslateModule,
    DateWithoutTime,
    KendoAngularExtensionsModule,
    AsyncPipe,
  ],
})
export class BaseDetailsComponent<T> implements OnDestroy {
  private sanitizer = inject(DomSanitizer);

  public isAlive = true;
  windowRef: GkWindowRef;
  parseCallback: (dto: T) => BaseDetailSettings[];
  baseDetailsService = inject(BaseDetailsService);
  protected readonly DetailType = DetailType;
  protected details: BaseDetailSettings[] = [];

  getSafeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  getDetailsByUrl(
    url: string,
    requestType?: RequestType,
    body?: unknown,
  ): Observable<T> {
    return this.baseDetailsService.getDetailsByUrl<T>(url, requestType, body);
  }

  loadDetailsFromAPI(url: string, requestType?: RequestType, body?: any): void {
    this.getDetailsByUrl(url, requestType, body)
      .pipe(
        takeWhile(() => this.isAlive),
        map((dto) => this.processDetails(dto)),
      )
      .subscribe((details) => (this.details = details));
  }

  setDetails(details: T): void {
    this.details = this.processDetails(details);
  }

  loadDetailsWithData(data: T): void {
    this.details = this.processDetails(data);
  }

  generateDefaultDetails(dto: T): BaseDetailSettings[] {
    return Object.entries(dto).map(([key, value]) => ({
      title: key,
      value: value,
      type: DetailType.Text,
    }));
  }

  isString(value: unknown): value is string {
    return typeof value === 'string';
  }

  convertToString(value: unknown): string {
    if (!value) {
      return '';
    }

    return this.isString(value) ? value : value.toString();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private processDetails(dto: T): BaseDetailSettings[] {
    return typeof this.parseCallback === 'function'
      ? this.parseCallback(dto)
      : this.generateDefaultDetails(dto);
  }
}
