import { Injectable } from '@angular/core';
import { GridDataService } from '../../../../../../gk-kendo';
import { BuildingDetailsDto } from '../../../map-building-details/map-building-details.model';

@Injectable({
  providedIn: 'any',
})
export class BuildingDetailsGridDataService extends GridDataService<BuildingDetailsDto> {
  constructor() {
    super('api/mapa/budynki/search', 'POST');
  }
}
