import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';

export class BuildingDetails {
  static fromApiToApp(data: BuildingDetailsDto): BaseDetailSettings[] {
    return [
      {
        title: 'BUILDING_DETAILS.CADASTRAL_NUMBER',
        value: data?.NumerEwidencyjny,
        type: DetailType.Numeric,
      },
      {
        title: 'BUILDING_DETAILS.IDENTIFIER',
        value: data?.Identyfikator,
        type: DetailType.Text,
      },
      {
        title: 'BUILDING_DETAILS.CADASTRAL_DISCTRICT',
        value: data?.ObrebNazwa,
        type: DetailType.Text,
      },
      {
        title: 'BUILDING_DETAILS.ADDRESS',
        value: data?.Adres,
        type: DetailType.Text,
      },
      {
        title: 'BUILDING_DETAILS.LAND',
        value: data?.NumeryDzialek,
        type: DetailType.Numeric,
      },
      {
        title: 'BUILDING_DETAILS.FLOORSABOVE',
        value: data?.KondygnacjeNadziemne,
        type: DetailType.Numeric,
      },
      {
        title: 'BUILDING_DETAILS.FLOORSBELOW',
        value: data?.KondygnacjePodziemne,
        type: DetailType.Numeric,
      },
      {
        title: 'BUILDING_DETAILS.CONSTRUCTION_AREA',
        value: data?.PowierzchniaZabudowyM2,
        type: DetailType.Numeric,
      },
      {
        title: 'BUILDING_DETAILS.CLASS',
        value: data?.RodzajKstNazwa,
        type: DetailType.Text,
      },
      {
        title: 'BUILDING_DETAILS.STATUS',
        value: data?.Status,
        type: DetailType.Text,
      },
    ];
  }
}

export interface BuildingDetailsDto {
  PowierzchniaZabudowyM2: number;
  NumerEwidencyjny: number;
  Identyfikator: string;
  ObrebNazwa: string;
  Adres: string;
  KondygnacjeNadziemne: number;
  KondygnacjePodziemne: number;
  Status: string;
  RodzajKstNazwa: string;
  NumeryDzialek: number;
  DataD: string;
  DataM: string;
  UserD: string;
  UserM: string;
}
