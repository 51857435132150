import { Injectable } from '@angular/core';
import { GridDataService } from '@gk/gk-modules';
import { FreeServicesRequestListGridDto } from './free-services-request-list-grid-data-model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class FreeServicesRequestListGridDataService extends GridDataService<FreeServicesRequestListGridDto> {
  constructor(private httpClient: HttpClient) {
    super('/api/interesant/przp/zamowienia/search', 'POST');
  }

  public cancelFreeServicesRequest(zamowUuid: string): Observable<void> {
    return this.httpClient.post<void>(
      `/api/interesant/zamowienia/${zamowUuid}/anuluj`,
      undefined,
    );
  }
}
