import { Component, OnInit } from '@angular/core';
import {
  DokumentacjaPowiazanaResponseDto,
  GkKendoGridComponent,
} from '@gk/gk-modules';
import { FSPRequestDocumentGridDataService } from './request-document-grid-data.service';
import {
  getRequestDocumentGridToolbarItems,
  requestDocumentGridColumns,
} from './request-document-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesRequestListGridDataService } from '../free-services-request-list-grid/free-services-request-list-grid-data.service';
import { FreeServicesRequestListGridDto } from '../free-services-request-list-grid/free-services-request-list-grid-data-model';

@Component({
  selector: 'app-fsp-request-document-grid',
  templateUrl:
    '../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FSPRequestDocumentGridComponent
  extends GkKendoGridComponent<DokumentacjaPowiazanaResponseDto>
  implements OnInit
{
  constructor(
    private fspRequestDocumentGridDataService: FSPRequestDocumentGridDataService,
    private freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {
    super(fspRequestDocumentGridDataService);
  }

  override topGridToolbarItems = getRequestDocumentGridToolbarItems(this);
  override columns = requestDocumentGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  public requestDocumentGridDataSelection: DokumentacjaPowiazanaResponseDto;
  public requestListGridDataSelection: FreeServicesRequestListGridDto;

  ngOnInit(): void {
    this.setRequestListUuidSelected();
    this.subscribeToRequestDocumentGridData();
  }

  setRequestListUuidSelected(): void {
    this.freeServicesRequestListGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.fspRequestDocumentGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((request) => {
        const uuid = request.selectedRows[0].dataItem.ZamowUuid;
        this.requestListGridDataSelection = request.selectedRows[0].dataItem;
        this.fetchRequestDocumentGridData(uuid);
      });
  }

  fetchRequestDocumentGridData(uuid: string): void {
    this.fspRequestDocumentGridDataService.queryByUrlParams(
      `/api/interesant/zamowienia/${uuid}/dokprzych`,
    );
  }

  subscribeToRequestDocumentGridData(): void {
    this.fspRequestDocumentGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.requestDocumentGridDataSelection = selection.selectedItems[0];
      });
  }
}
