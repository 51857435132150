import { AfterViewInit, Component } from '@angular/core';
import { GkKendoGridComponent, WladajacyResponseDto } from '@gk/gk-modules';
import { FspOwnersGridDataService } from './fsp-owners-grid-data.service';
import {
  getFspOwnersGridToolbarItems,
  fspOwnersGridColumns,
} from './fsp-owners-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesParcelRequestGridDataService } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';
import { FreeServicesParcelRequestGridDto } from '../fsp-parcel-request-grid/free-services-parcel-request-grid-data-model';

@Component({
  selector: 'app-fsp-owners-grid',
  templateUrl:
    '../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspOwnersGridComponent
  extends GkKendoGridComponent<WladajacyResponseDto>
  implements AfterViewInit
{
  constructor(
    public fspOwnersGridDataService: FspOwnersGridDataService,
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
  ) {
    super(fspOwnersGridDataService);
  }

  override topGridToolbarItems = getFspOwnersGridToolbarItems();
  override columns = fspOwnersGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  selectedParcelGridItem: FreeServicesParcelRequestGridDto;

  override ngAfterViewInit(): void {
    this.subscribeToRequestListGridSelectedKeys();
  }

  subscribeToRequestListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelGridItem = undefined;
          this.fspOwnersGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelGridItem = selection.selectedRows[0].dataItem;
        this.fetchGridData();
      });
  }

  fetchGridData(): void {
    this.fspOwnersGridDataService.$loading.next(true);
    this.freeServicesParcelRequestGridDataService
      .fetchParcelDetailsFromApi(this.selectedParcelGridItem.KdzialkaUuid)
      .subscribe((data) => {
        this.fspOwnersGridDataService.$loading.next(false);
        this.fspOwnersGridDataService.next({
          data: data.Wladajacy,
          total: data.Wladajacy.length,
        });
      });
  }
}
