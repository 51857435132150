import {
  iwIconDrawPoint,
  iwIconPodgladMapa,
  iwIconRysujWielobok,
} from '../../../../../../assets/css/ewid-svg/svg-icons';
import {
  ColumnSetting,
  ColumnType,
  GenericToolbarItemName,
  GridToolBarItem,
} from '../../../../../../gk-kendo';
import { AnyGeometryObject } from '../../../../../models';
import { AdressPointDetailsGridDto } from './map-address-point-details-grid-data-model';
import { MapAddressPointDetailsGridComponent } from './map-address-point-details-grid.component';

export const getAddressPointDetailsGridToolbarItems = (
  c: MapAddressPointDetailsGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: GenericToolbarItemName.GetPointGeometryFromMap,
      svgIcon: iwIconPodgladMapa,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.ADD_ADDRESS_POINT_FROM_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.ADD_ADDRESS_POINT_FROM_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetPolygonGeometryFromMap,
      svgIcon: iwIconRysujWielobok,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      text: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetLineGeometryFromMap,
      svgIcon: iwIconDrawPoint,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
  ];
};

export const landDetailsGridColumns: ColumnSetting[] = [
  {
    field: 'Miejscowosc',
    title: 'ADDRESS_POINT.ADDRESS_POINTS',
    type: ColumnType.Text,
    cellTemplate: (_item, dataItem: AdressPointDetailsGridDto): string =>
      `${dataItem.Miejscowosc}, ul. ${dataItem.Ulica} ${dataItem.NumerPorz}`,
  },
];
