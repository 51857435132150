import {
  ColumnSetting,
  ColumnType,
  GridToolBarItem,
  iwIconAnuluj,
  iwIconColorDlk,
  iwIconColorSz,
  iwIconPlus,
} from '@gk/gk-modules';
import { FreeServicesRequestListGridComponent } from './free-services-request-list-grid.component';

export const getFreeServicesRequestListGridToolbarItems = (
  c: FreeServicesRequestListGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: 'searchRequests',
      svgIcon: iwIconPlus,
      className: 'svg-icon-blue',
      title: 'FREE_PORTAL_SERVICES.SEARCH',
      text: 'FREE_PORTAL_SERVICES.SEARCH',
      showText: 'overflow',
      callback: () => c.openSearchRequestWindow(),
    },
    {
      name: 'parcelForRequest',
      svgIcon: iwIconColorDlk,
      title: 'FREE_PORTAL_SERVICES.PARCELS',
      text: 'FREE_PORTAL_SERVICES.PARCELS',
      showText: 'overflow',
      callback: () => c.openParcelsForRequestListWindow(),
    },
    {
      name: 'requestDetails',
      svgIcon: iwIconColorSz,
      title: 'FREE_PORTAL_SERVICES.DETAILS',
      text: 'FREE_PORTAL_SERVICES.DETAILS',
      showText: 'overflow',
      callback: () => c.showDetailsWindow(),
    },
    {
      name: 'cancelRequest',
      svgIcon: iwIconAnuluj,
      className: 'svg-icon-red',
      title: 'FREE_PORTAL_SERVICES.CANCEL',
      text: 'FREE_PORTAL_SERVICES.CANCEL',
      showText: 'overflow',
      disabled: () => c.isDisableCancelRequest,
      callback: () => c.handleCancelRequest(),
    },
    {
      spacer: true,
      name: 'archival',
      title: 'FREE_PORTAL_SERVICES.ARCHIVAL',
      text: 'FREE_PORTAL_SERVICES.ARCHIVAL',
      toggleable: true,
      selected: () => c.displayRequestArchive,
      callback: (event) => c.onDisplayRequestArchiveChange(event as Event),
    },
  ];
};

export const freeServicesRequestListGridColumns: ColumnSetting[] = [
  {
    field: 'Numer',
    title: 'Nr kancelaryjny',
    type: ColumnType.Text,
  },
  {
    field: 'DataRozpocz',
    title: 'Data rozpoczęcia',
    type: ColumnType.Date,
    width: 140,
  },
  {
    field: 'Status',
    title: 'Status',
    type: ColumnType.Text,
  },
  {
    field: 'DataZalatw',
    title: 'Data załatwienia',
    type: ColumnType.Date,
    width: 140,
  },
  {
    field: 'Dokumenty',
    title: 'Dokumenty z ODGiK',
    type: ColumnType.Boolean,
  },
];
