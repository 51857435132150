import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContextMenuModule,
  ContextMenuService,
} from '@perfectmemory/ngx-contextmenu';
import { AngularOpenlayersModule } from 'ng-openlayers';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { GkDynamicFormModule } from '../gk-dynamic-form/gk-dynamic-form.module';
import { GkDynamicListModule } from '../gk-dynamic-list/gk-dynamic-list.module';
import { GkKendoModule } from '../gk-kendo';
import { MapSettingsService } from '../services/map-settings/map-settings.service';
import {
  AttributesDynamicListComponent,
  AttributesFormComponent,
  ClearButtonComponent,
  FileLoaderFormComponent,
  HelpControlComponent,
  IncreaseRangeByBufferButtonComponent,
  InfoContentComponent,
  LandDetailsGridComponent,
  LayersTreeButtonComponent,
  LayersTreeSidebarComponent,
  LineStringComponent,
  MapComponent,
  MapGeometryComponent,
  MapLandClassesGridComponent,
  MapObjectTableComponent,
  MapPopupComponent,
  MapSheetFormComponent,
  MapToolbarComponent,
  MapToolComponent,
  MapToolContainerComponent,
  MapTooltipComponent,
  MapViewComponent,
  PaperDropdownComponent,
  PointComponent,
  PolygonComponent,
  PopupControlComponent,
  RangeFromAllParcelsButtonComponent,
  RangeFromSelectedParcelsButtonComponent,
  ScaleDropdownComponent,
  TreeNodeComponent,
  WmsLayersComponent,
} from './components';
import { MapBuildingDetailsComponent } from './components/map/map-building-details/map-building-details.component';
import { MapLandDetailsDetailsComponent } from './components/map/map-land-details-wrapper/map-land-details-details/map-land-details-details.component';
import { MapLandDetailsWrapperComponent } from './components/map/map-land-details-wrapper/map-land-details-wrapper.component';
import { Bdot500DetailsDetailsComponent } from './components/map/map-object-info/bdot500-details-wrapper/bdot500-details-details/bdot500-details-details.component';
import { Bdot500DetailsGridComponent } from './components/map/map-object-info/bdot500-details-wrapper/bdot500-details-grid/bdot500-details-grid.component';
import { Bdot500DetailsWrapperComponent } from './components/map/map-object-info/bdot500-details-wrapper/bdot500-details-wrapper.component';
import { BuildingDetailsGridComponent } from './components/map/map-object-info/building-details-wrapper/building-details-grid/building-details-grid.component';
import { BuildingDetailsWrapperComponent } from './components/map/map-object-info/building-details-wrapper/building-details-wrapper.component';
import { DevelopmentPlanDetailsGridComponent } from './components/map/map-object-info/development-plan-details/development-plan-details-grid/development-plan-details-grid.component';
import {
  MapAddressPointDetailsDetailsComponent,
  MapAddressPointDetailsGridComponent,
  MapAddressPointDetailsWrapperComponent,
} from './components/map/map-object-info/map-address-point-details-wrapper';
import { MatrixDetailsDetailsComponent } from './components/map/map-object-info/matrix-details-wrapper/matrix-details-details/matrix-details-details.component';
import { MatrixDetailsGridComponent } from './components/map/map-object-info/matrix-details-wrapper/matrix-details-grid/matrix-details-grid.component';
import { MatrixDetailsWrapperComponent } from './components/map/map-object-info/matrix-details-wrapper/matrix-details-wrapper.component';
import { StreetAxisDetailsDetailsComponent } from './components/map/map-object-info/street-axis-details-wrapper/street-axis-details-details/street-axis-details-details.component';
import { StreetAxisDetailsGridComponent } from './components/map/map-object-info/street-axis-details-wrapper/street-axis-details-grid/street-axis-details-grid.component';
import { StreetAxisDetailsWrapperComponent } from './components/map/map-object-info/street-axis-details-wrapper/street-axis-details-wrapper.component';
import { DownloadRangeModalComponent } from './components/map/map-toolbar/download-range-modal/download-range-modal.component';
import { MapMeasurementResultComponent } from './components/map/map-view/map-measurement-result/map-measurement-result.component';
import { MapMousePositionComponent } from './components/map/map-view/map-mouse-position/map-mouse-position.component';
import { MapPinControlComponent } from './components/map/map-view/map-pin-control/map-pin-control.component';
import { ElementFocusDirective } from './directives';
import { MapStateService } from './services';
import { MapDrawingActionService } from './services/map-drawing-action/map-drawing-action.service';

const components = [
  MapComponent,
  MapViewComponent,
  MapToolbarComponent,
  ScaleDropdownComponent,
  MapTooltipComponent,
  MapGeometryComponent,
  PaperDropdownComponent,
  MapToolbarComponent,
  TreeNodeComponent,
  LayersTreeButtonComponent,
  LayersTreeSidebarComponent,
  WmsLayersComponent,
  MapToolComponent,
  HelpControlComponent,
  AttributesFormComponent,
  MapObjectTableComponent,
  FileLoaderFormComponent,
  MapSheetFormComponent,
  MapToolContainerComponent,
  MapPopupComponent,
  InfoContentComponent,
  AttributesDynamicListComponent,
  PopupControlComponent,
  PointComponent,
  LineStringComponent,
  PolygonComponent,
  ClearButtonComponent,
  RangeFromSelectedParcelsButtonComponent,
  RangeFromAllParcelsButtonComponent,
  IncreaseRangeByBufferButtonComponent,
  DownloadRangeModalComponent,
  MapMeasurementResultComponent,
  LandDetailsGridComponent,
  MapLandClassesGridComponent,
  MapBuildingDetailsComponent,
  MapLandDetailsWrapperComponent,
  MapLandDetailsDetailsComponent,
  MapPinControlComponent,
  MapMousePositionComponent,
  MapAddressPointDetailsWrapperComponent,
  MapAddressPointDetailsGridComponent,
  MapAddressPointDetailsDetailsComponent,
  MatrixDetailsWrapperComponent,
  MatrixDetailsGridComponent,
  MatrixDetailsDetailsComponent,
  Bdot500DetailsWrapperComponent,
  Bdot500DetailsGridComponent,
  Bdot500DetailsDetailsComponent,
  Bdot500DetailsWrapperComponent,
  StreetAxisDetailsWrapperComponent,
  StreetAxisDetailsGridComponent,
  StreetAxisDetailsDetailsComponent,
  DevelopmentPlanDetailsGridComponent,
  BuildingDetailsWrapperComponent,
  BuildingDetailsGridComponent,
];

const directives = [ElementFocusDirective];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    AngularOpenlayersModule,
    TranslateModule,
    ReactiveFormsModule,
    GkDynamicFormModule,
    GkDynamicListModule,
    ContextMenuModule,
    GkComponentsModule,
    GkKendoModule,
  ],
  declarations: [...components, ...directives],
  exports: [...components, ...directives],
  providers: [
    ContextMenuService,
    MapSettingsService,
    MapStateService,
    MapDrawingActionService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
})
export class GkMapModule {}
