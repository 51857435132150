<kendo-splitter orientation="vertical" class="h-100">
  <kendo-splitter-pane size="65%">
    <app-free-services-request-list-grid> </app-free-services-request-list-grid>
  </kendo-splitter-pane>
  <kendo-splitter-pane>
    <kendo-tabstrip class="h-100" [keepTabContent]="true">
      <kendo-tabstrip-tab
        title="{{ 'M_APPRAISER.REQUESTS.REQUEST' | translate }}"
        [selected]="true"
      >
        <ng-template kendoTabContent>
          <app-fsp-request-document-grid></app-fsp-request-document-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="{{ 'M_APPRAISER.REQUESTS.SUBJECT_OF_REQUEST' | translate }}"
      >
        <ng-template kendoTabContent>
          <app-fsp-subject-request-grid></app-fsp-subject-request-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'M_APPRAISER.REQUESTS.DOCS' | translate }}">
        <ng-template kendoTabContent>
          <app-fsp-documents-grid></app-fsp-documents-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      )
    </kendo-tabstrip>
  </kendo-splitter-pane>
</kendo-splitter>
