import { Component } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { GeometryPostBody } from 'libs/gk-modules/src/lib/gk-map/models';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { AdressPointDetailsGridDto } from './map-address-point-details-grid-data-model';
import { MapPointsDetailsGridDataService } from './map-address-point-details-grid-data.service';
import {
  getAddressPointDetailsGridToolbarItems,
  landDetailsGridColumns,
} from './map-address-point-details-grid-model';

@Component({
  selector: 'gk-address-point-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class MapAddressPointDetailsGridComponent extends GkKendoGridComponent<AdressPointDetailsGridDto> {
  constructor(
    private mapPointsDetailsGridDataService: MapPointsDetailsGridDataService,
  ) {
    super(mapPointsDetailsGridDataService);
  }

  override topGridToolbarItems = getAddressPointDetailsGridToolbarItems(this);
  override columns = landDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  protected override firstRowSelected = true;
  override sortable = true;

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);
    this.mapPointsDetailsGridDataService
      .queryByState(payload as State)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): GeometryPostBody {
    return {
      Wkt: geometry,
    };
  }
}
