import {
  iwIconDrawPoint,
  iwIconPodgladMapa,
  iwIconRysujWielobok,
} from '../../../../../../assets/css/ewid-svg/svg-icons';
import {
  ColumnSetting,
  ColumnType,
  GenericToolbarItemName,
  GridToolBarItem,
} from '../../../../../../gk-kendo';
import { AnyGeometryObject } from '../../../../../models';
import { Bdot500DetailsGridDto } from './bdot500-details-grid-data-model';
import { Bdot500DetailsGridComponent } from './bdot500-details-grid.component';

export const getBdot500DetailsGridToolbarItems = (
  c: Bdot500DetailsGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: GenericToolbarItemName.GetPointGeometryFromMap,
      svgIcon: iwIconPodgladMapa,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.ADD_LAND_FROM_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.ADD_LAND_FROM_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetPolygonGeometryFromMap,
      svgIcon: iwIconRysujWielobok,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      text: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetLineGeometryFromMap,
      svgIcon: iwIconDrawPoint,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
  ];
};

export const bdot500DetailsGridColumns: ColumnSetting[] = [
  {
    field: 'ObiektId',
    title: 'BDOT500_DETAILS.GRID.OBJECT',
    type: ColumnType.Text,
    cellTemplate: (_item, dataItem: Bdot500DetailsGridDto): string =>
      `${dataItem.KodKartograficzny} ${dataItem.Nazwa}`,
  },
];
