import { Injectable } from '@angular/core';
import { GridDataService } from '@gk/gk-modules';
import { FspSubjectRequestGridDto } from './fsp-subject-request-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class FspSubjectRequestGridDataService extends GridDataService<FspSubjectRequestGridDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
