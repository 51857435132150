import { HttpClient } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  GkComponentsModule,
  GkDynamicListModule,
  GkKendoModule,
  GkMapModule,
  RecaptchaService,
} from '@gk/gk-modules';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../../shared/shared.module';
import { FreeServicesMapComponent } from './free-services-map/free-services-map.component';
import { FreeServicesPortalRoutingModule } from './free-services-portal-routing.module';
import { FreeServicesPortalComponent } from './free-services-portal.component';
import { NewRequestSummaryComponent } from './new-request-summary/new-request-summary.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { FSPNewRequestFormService } from './services/new-request-form/new-request-form.service';
import {
  DialogContainerDirective,
  WindowContainerDirective,
} from '@progress/kendo-angular-dialog';
import { FreeServicesRequestListGridComponent } from './kendo-request-list/free-services-request-list-grid/free-services-request-list-grid.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { FreeServicesRequestSearchFormComponent } from './kendo-request-list/free-services-request-search-form/free-services-request-search-form.component';
import { FreeServicesRequestDetailsComponent } from './kendo-request-list/free-services-request-details/free-services-request-details.component';
import { FreeServicesRequestListComponent } from './kendo-request-list/free-services-request-list.component';
import { FSPRequestDocumentGridComponent } from './kendo-request-list/free-services-request-document-grid/request-document-grid.component';
import { FspDocumentsGridComponent } from './kendo-request-list/free-services-documents-grid/fsp-documents-grid.component';
import { FreeServicesParcelRequestGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-parcel-request-grid/free-services-parcel-request-grid.component';
import { FspBorderPointsGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-border-points-grid/fsp-border-points-grid.component';
import { FspClassUseGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-class-use-grid/fsp-class-use-grid.component';
import { FspBuildingsGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-building-register/fsp-buildings-register-grid/fsp-buildings-grid.component';
import { FspPremissesGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-premisses-register-grid/fsp-premisses-grid/fsp-premisses-grid.component';
import { FreeServicesParcelSearchFormComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-parcel-search-form/fsp-parcel-search-form.component';
import { FspParcelsComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-parcels.component';
import { FspOwnersGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-owners-grid/fsp-owners-grid.component';
import { FspBuildingRegisterComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-building-register/fsp-building-register.component';
import { FspSubjectRequestGridComponent } from './kendo-request-list/free-services-subject-request-grid/fsp-subject-request-grid.component';
import { FspBuildingsDocsGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-building-register/fsp-buildings-docs/fsp-buildings-docs-grid.component';
import { FspBuildingsOwnerGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-building-register/fsp-building-owners/fsp-buildings-owner-grid.component';
import { FspPremissesRegisterComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-premisses-register-grid/fsp-premisses-register.component';
import { FspPremissesDocsGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-premisses-register-grid/fsp-premisses-docs/fsp-premisses-docs-grid.component';
import { FspPremissesOwnerGridComponent } from './kendo-request-list/free-services-parcel-request-grid/fsp-premisses-register-grid/fsp-premisses-owners/fsp-premisses-owner-grid.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    GkDynamicListModule,
    GkMapModule,
    FreeServicesPortalRoutingModule,
    GkComponentsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    DialogContainerDirective,
    WindowContainerDirective,
    GkKendoModule,
    RecaptchaV3Module,
  ],
  declarations: [
    FreeServicesPortalComponent,
    NewRequestComponent,
    NewRequestSummaryComponent,
    FreeServicesMapComponent,
    FreeServicesRequestListGridComponent,
    FreeServicesRequestSearchFormComponent,
    FreeServicesRequestDetailsComponent,
    FreeServicesRequestListComponent,
    FSPRequestDocumentGridComponent,
    FspDocumentsGridComponent,
    FreeServicesParcelRequestGridComponent,
    FspBorderPointsGridComponent,
    FspClassUseGridComponent,
    FspBuildingsGridComponent,
    FspPremissesGridComponent,
    FreeServicesParcelSearchFormComponent,
    FspParcelsComponent,
    FspOwnersGridComponent,
    FspBuildingRegisterComponent,
    FspSubjectRequestGridComponent,
    FspBuildingsDocsGridComponent,
    FspBuildingsOwnerGridComponent,
    FspPremissesRegisterComponent,
    FspPremissesDocsGridComponent,
    FspPremissesOwnerGridComponent,
  ],
  providers: [
    FSPNewRequestFormService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (recaptchaService: RecaptchaService): string =>
        recaptchaService.siteKey,
      deps: [RecaptchaService],
    },
  ],
})
export class FreeServicesPortalModule {}
