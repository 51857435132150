import { booleanAttribute, Component, Input } from '@angular/core';
import { AbortRequestConfig } from '../models/request.model';

@Component({
  selector: 'kendo-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss'],
  standalone: false,
})
export class LoadingPanelComponent {
  public hostClass = true;
  @Input({ transform: booleanAttribute }) isLoading = false;
  @Input() abortConfig: AbortRequestConfig;
  @Input() abortRequest: () => void;

  public handleAbortRequest(): void {
    if (typeof this.abortConfig?.callback === 'function') {
      this.abortConfig.callback();

      return;
    }
    this.abortRequest();
  }
}
