import { Injectable } from '@angular/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataService } from '../../../../../../gk-kendo';
import { AdressPointDetailsGridDto } from './map-address-point-details-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class MapPointsDetailsGridDataService extends GridDataService<AdressPointDetailsGridDto> {
  constructor() {
    super('api/mapa/pkt/adresowy/szczegoly/bygeom', 'POST', (data) =>
      orderBy(data, [{ field: 'Miejscowosc', dir: 'asc' }] as SortDescriptor[]),
    );
  }
}
