import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { StreetAxisDetailsGridDto } from '../street-axis-details-grid/street-axis-details-grid-data-model';
import { StreetAxisDetailsGridDataService } from '../street-axis-details-grid/street-axis-details-grid-data.service';
import { StreetAxisDetails } from './street-axis-details-details.model';

@Component({
  selector: 'gk-street-axis-details-details',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
})
export class StreetAxisDetailsDetailsComponent
  extends BaseDetailsComponent<StreetAxisDetailsGridDto>
  implements OnInit
{
  override parseCallback = StreetAxisDetails.fromApiToApp;

  constructor(
    private steetAxisDetailsGridDataService: StreetAxisDetailsGridDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAddressPointChange();
  }

  private subscribeToAddressPointChange(): void {
    this.steetAxisDetailsGridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItem = selection?.selectedItems[0];
        if (selectedItem) {
          this.loadDetailsWithData(selectedItem);
        }
      });
  }
}
