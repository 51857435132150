import { Component, OnInit } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { GeometryPostBody } from 'libs/gk-modules/src/lib/gk-map/models';
import { takeWhile } from 'rxjs';
import { GkKendoGridComponent } from '../../../../../../gk-kendo';
import { DevelopmentPlanDetailsGridDto } from './development-plan-details-grid-data-model';
import { DevelopmentPlanDetailsGridDataService } from './development-plan-details-grid-data.service';
import {
  developmentPlanDetailsGridColumns,
  getDevelopmentPlanDetailsGridToolbarItems,
} from './development-plan-details-grid-model';

@Component({
  selector: 'gk-development-plan-details-grid',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
})
export class DevelopmentPlanDetailsGridComponent
  extends GkKendoGridComponent<DevelopmentPlanDetailsGridDto>
  implements OnInit
{
  constructor(
    private developmentPlanDetailsGridDataService: DevelopmentPlanDetailsGridDataService,
  ) {
    super(developmentPlanDetailsGridDataService);
  }

  override topGridToolbarItems =
    getDevelopmentPlanDetailsGridToolbarItems(this);
  override columns = developmentPlanDetailsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  protected override firstRowSelected = true;
  protected detailsWindowTitleTranslation: string;

  ngOnInit(): void {
    this.subscribeToDetailsWindowTitleTranslation();
  }

  addPointGeometryFromMap(geometry: string): void {
    const payload = this.createPostBody(geometry);
    this.developmentPlanDetailsGridDataService
      .queryByState(payload as State)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }

  private createPostBody(geometry: string): GeometryPostBody {
    return {
      Wkt: geometry,
    };
  }

  subscribeToDetailsWindowTitleTranslation(): void {
    this.translateService
      .get('COORDINATION_MEETING_PORTAL.REQUESTS_LIST.DETAILS.TITLE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.detailsWindowTitleTranslation = translation),
      );
  }

  getDetailsWindowTitle(): string {
    return `${this.detailsWindowTitleTranslation}: ${this.developmentPlanDetailsGridDataService.$selection.getValue().selectedItems[0].PlanId}`;
  }
}
