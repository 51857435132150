import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  FormFieldOrientation,
  FormFieldType,
  FormFieldWrappers,
} from '@gk/gk-modules';
import { FreeServicesRequestSearchFormControlName } from './free-services-request-search-form.model';

@Injectable({
  providedIn: 'root',
})
export class FreeServicesRequestSearchFormService {
  getFormConfig(): {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  } {
    return {
      fields: [
        {
          wrappers: [FormFieldWrappers.FormField],
          key: FreeServicesRequestSearchFormControlName.OfficeNumber,
          type: FormFieldType.KendoInput,
          props: {
            label: 'FREE_PORTAL_SERVICES.OFFICE_NR',
          },
        },
        {
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'FREE_PORTAL_SERVICES.START_DATE',
            orientation: FormFieldOrientation.Horizontal,
          },
          fieldGroupClassName: 'd-flex flex-row column-gap-1',
          fieldGroup: [
            {
              key: FreeServicesRequestSearchFormControlName.StartDateFrom,
              type: FormFieldType.KendoDatepicker,
              props: {
                placeholder: 'od',
              },
            },
            {
              key: FreeServicesRequestSearchFormControlName.StartDateTo,
              type: FormFieldType.KendoDatepicker,
              props: {
                placeholder: 'do',
              },
            },
          ],
        },
        {
          key: FreeServicesRequestSearchFormControlName.Status,
          type: FormFieldType.KendoInput,
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'FREE_PORTAL_SERVICES.STATUS',
          },
        },
        {
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'FREE_PORTAL_SERVICES.EXEC_DATE',
            orientation: FormFieldOrientation.Horizontal,
          },
          fieldGroupClassName: 'd-flex flex-row column-gap-1',
          fieldGroup: [
            {
              key: FreeServicesRequestSearchFormControlName.ExecDateFrom,
              type: FormFieldType.KendoDatepicker,
              props: {
                placeholder: 'od',
              },
            },
            {
              key: FreeServicesRequestSearchFormControlName.ExecDateTo,
              type: FormFieldType.KendoDatepicker,
              props: {
                placeholder: 'do',
              },
            },
          ],
        },
        {
          key: FreeServicesRequestSearchFormControlName.HasDocumentation,
          type: FormFieldType.KendoSelect,
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'FREE_PORTAL_SERVICES.DOCS',
            options: [
              { name: 'tak', id: true },
              { name: 'nie', id: false },
            ],
            valueProp: 'id',
            labelProp: 'name',
          },
        },
        {
          key: FreeServicesRequestSearchFormControlName.SelectPoint,
          type: FormFieldType.KendoSelectPoint,
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'FREE_PORTAL_SERVICES.RANGE',
          },
        },
      ],
    };
  }
}
