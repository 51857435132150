import { Injectable } from '@angular/core';
import { GridDataService, WladajacyResponseDto } from '@gk/gk-modules';

@Injectable({
  providedIn: 'any',
})
export class FspPremissesOwnerGridDataService extends GridDataService<WladajacyResponseDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
