import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/kendo/form-field';
import { iwIconWyczysc } from '../../../assets/css/ewid-svg/svg-icons';
import { GenericToolbarItemName } from '../../gk-kendo-grid/gk-kendo-grid-toolbar/gk-kendo-grid-toolbar.model';
import {
  GridToolBarItem,
  GridToolBarItemType,
} from '../../gk-kendo-grid/gk-kendo-grid.model';
import { AnyGeometryObject } from '../../../gk-map/models';

@Component({
  selector: 'gk-formly-field-kendo-select-point',
  template: `
    <gk-kendo-grid-toolbar
      [formlyAttributes]="field"
      [formControl]="formControl"
      [gridToolbarItems]="getToolbarItems()"
      [overflow]="false"
    ></gk-kendo-grid-toolbar>
  `,
  standalone: false,
})
export class FormlyFieldKendoSelectPointComponent extends FieldType<
  FieldTypeConfig<FormlyFieldProps>
> {
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  getToolbarItems(): GridToolBarItem[] {
    return [
      {
        name: GenericToolbarItemName.GetPointGeometryFromMap,
        title:
          'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.ADD_POINT_TO_POSITION',
        text: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.ADD_POINT_TO_POSITION',
        showText: 'overflow',
        payload: () => ({
          doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
            this.formControl.setValue(anyGeometryObject[0]);
            this.changeDetectorRef.markForCheck();
          },
        }),
      },
      {
        name: 'clearSelectedPoint',
        svgIcon: iwIconWyczysc,
        className: 'svg-icon-blue',
        title: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.CLEAR',
        text: 'COORDINATION_MEETING_PORTAL.CASE_STANCE_ADD.BUTTONS.CLEAR',
        showText: 'overflow',
        callback: () => this.clearGeomFromMap(),
        visible: () => this.isExitGeomFromMap(),
      },
      {
        name: 'selectPointFromMap',
        text: () => this.getPointText(),
        type: GridToolBarItemType.Text,
        visible: () => this.isExitGeomFromMap(),
      },
    ];
  }

  isExitGeomFromMap(): boolean {
    return !!this.formControl.value;
  }

  clearGeomFromMap(): void {
    this.formControl.setValue(null);
  }

  getPointText(): string {
    const geomWkt: string = this.formControl.value?.geom;
    if (!geomWkt) {
      return '';
    }

    const matches = geomWkt.match(/POINT\s*\(\s*([-\d.]+)\s+([-\d.]+)\s*\)/i);

    if (!matches || matches.length < 3) {
      return '';
    }

    const x = matches[1];
    const y = matches[2];

    return `X= ${x}, Y= ${y}`;
  }
}
