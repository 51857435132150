import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Bdot500DetailsDto } from './bdot500-details-details/bdot500-details-details.model';
import { Bdot500DetailsGridDataService } from './bdot500-details-grid/bdot500-details-grid-data.service';

@Injectable({
  providedIn: 'any',
})
export class Bdot500DetailsWrapperService {
  public $loading = new BehaviorSubject<boolean>(false);
  public $bdot500Details = new Subject<Bdot500DetailsDto[]>();
  public bdot500DetailsSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private bdot500DetailsGridDataService: Bdot500DetailsGridDataService,
  ) {}

  handleLandSelected(bdot500UUID: number): void {
    this.$loading.next(true);
    this.bdot500DetailsGridDataService.$loading.next(true);
    this.bdot500DetailsSubscription = this.httpClient
      .get<
        Bdot500DetailsDto[]
      >(`/api/mapa/bdot500/mzObiekty/${bdot500UUID}/atrybuty/danedoedycji`)
      .subscribe((data: Bdot500DetailsDto[]) => {
        this.$bdot500Details.next(data);
        this.bdot500DetailsGridDataService.$loading.next(false);
        this.$loading.next(false);
      });
  }
}
