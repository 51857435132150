import { ComponentRef, EventEmitter } from '@angular/core';
import { ButtonThemeColor } from '@progress/kendo-angular-buttons/common/models/theme-color';
import { WindowComponent } from '@progress/kendo-angular-dialog';
import { DisplayMode } from '@progress/kendo-angular-toolbar';
import { State } from '@progress/kendo-data-query';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { BaseDetailSettings } from 'kendo-angular-extensions';
import {
  iwIconBubbles2,
  iwIconCamera2,
  iwIconColorSz,
  iwIconColorZakresZapisz,
  iwIconCross,
  iwIconEmail,
  iwIconInfo,
  iwIconMinus,
  iwIconPlus,
  iwIconShowDxf,
  iwIconWrysujZakres,
  iwIconZobaczZakres,
} from '../../assets/css/ewid-svg/svg-icons';
import { RequestType } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { RangeTypeValue } from '../../gk-map/configs';
import {
  FileUploadOwner,
  GkKendoSuccessEvent,
  GkKendoUploadEvent,
  UploadDocTypeName,
} from '../gk-kendo-upload';
import { KendoWindowInstanceNames } from '../services/kendo-window/kendo-window.model';
import { DocumentOrigin } from './gk-kendo-documentation-grid/gk-kendo-documentation-grid-model';
import { GkKendoGridComponent } from './gk-kendo-grid.component';
import { PerunResponseContainer } from '../../utils/perun-api.model';

export type GkKendoGridItem<T, U> = U extends void ? T : U;
export type GkKendoGridItemKey<T, U> = keyof GkKendoGridItem<T, U>;

export interface GkKendoGridDataResult<T, U> {
  data: GkKendoGridItem<T, U>[];
  total: number;
}

export interface GkKendoGridDataResultWithPerunResponse<T, U> {
  data: GkKendoGridItem<T, U>[];
  total: number;
  response: PerunResponseContainer<T> | T[];
}

export interface GkKendoGridSelection<T, U> {
  selectedKeys: GkKendoGridItemKey<T, U>[];
  selectedRows: GkRowArgs<GkKendoGridItem<T, U>>[] | undefined;
  selectedItems: GkKendoGridItem<T, U>[];
}

export interface GkKendoGridFormControlValue<T, U> {
  data: GkKendoGridItem<T, U>[];
  selection: GkKendoGridItem<T, U>[];
}

export interface GkRowArgs<T> {
  dataItem: T;
  index: number;
}

export interface ColumnSetting {
  field: string;
  title: string;
  type: ColumnType;
  width?: number;
  media?: string;
  format?: string;
  cellTemplate?: (item: any, dataItem: any) => string;
}

export enum ColumnType {
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Date = 'date',
  Html = 'html',
}

export enum GridToolBarItemType {
  Dropdown = 'dropdown',
  Text = 'text',
  ButtonWithInput = 'buttonWithInput',
}

export interface InputToolBarItemData {
  width?: string;
  min: number;
  max?: number;
  value: number;
  suffixValue?: string;
}

export type GridToolBarItemPayload =
  | GridToolbarItemMapDrawingPayload
  | GenericGridToolbarItemAddFilePayload
  | GenericGridToolbarItemRemoveFilePayload
  | GenericGridToolbarItemDownloadFilePayload
  | GenericGridToolbarItemPreviewFilePayload
  | GenericGridToolbarItemShowGeometryPayload
  | GenericGridToolbarSaveGeometryInFilePayload
  | GenericGridToolbarItemDocumentationPayload
  | GenericGridToolbarItemMessagesListPayload
  | GenericGridToolbarItemCommunicatorPayload
  | GenericToolbarModifyGeometryPayload;

export interface GridToolBarItem {
  name: string;
  iconClass?: string | (() => string);
  svgIcon?: SVGIcon | (() => SVGIcon);
  className?: string | (() => string);
  title?: string | (() => string);
  text?: string | (() => string);
  showText?: DisplayMode;
  type?: GridToolBarItemType;
  disabled?: boolean | (() => boolean);
  visible?: boolean | (() => boolean);
  data?: GridToolBarItem[];
  callback?: (payload?: Event | number | GridToolBarItemPayload) => void;
  payload?: () => GridToolBarItemPayload;
  separator?: boolean;
  spacer?: boolean;
  inputData?: InputToolBarItemData;
  beforeClickValidation?: () => boolean;
  buttonThemeColor?: ButtonThemeColor;
  toggleable?: boolean;
  selected?: boolean | (() => boolean);
}

export interface LegendConfig {
  text: string;
  class?: string;
  isVisible?: () => boolean;
}

export interface AbortRequestConfig {
  isAbortRequest: boolean;
  callback?: () => void;
}

export const MIN_WIDTH_FOR_DATE_COLUMN = 90;
export const DEFAULT_MOBILE_COLUMN_WIDTH = 80;

export interface GridToolbarItemMapDrawingPayload {
  doneCallback: (mapObject: any) => void;
}

export interface GenericGridToolbarItemAddFilePayload {
  uploadSaveUrl: string;
  uploadDocTypeDict: UploadDocTypeName;
  uploadData?: {
    Owner?: FileUploadOwner;
    OwnerId?: number | string;
    OwnerUuid?: string;
    FaktUuid?: string;
    ZudUuid?: string;
  };
  onUploadButtonClick?: (
    uploadEvent: EventEmitter<GkKendoUploadEvent>,
  ) => Promise<number>;
  onUploadSuccess?: (uploadEvent: GkKendoSuccessEvent) => void;
}

export interface GenericGridToolbarItemRemoveFilePayload {
  removeUrl: string;
}

export interface GenericGridToolbarItemDownloadFilePayload {
  downloadUrl: string;
  kergId?: number;
  successCallback?: () => void;
  origin?: DocumentOrigin;
}

export interface GenericGridToolbarItemPreviewFilePayload {
  url: string;
  kergId?: number;
  requestType?: RequestType;
  fileName?: string;
  transIds?: number[];
}

export interface GenericGridToolbarItemShowGeometryPayload {
  url?: string;
  gridGeometryFieldName?: string;
  ajaxPayload?: any;
}

export interface GenericGridToolbarSaveGeometryInFilePayload {
  gridGeometryFieldName?: string;
}

export interface GenericToolbarModifyGeometryPayload {
  wktGeom: string;
}
export interface GenericToolbarShowGeometryPayload {
  wktGeom: string;
}

export enum GenericGridToolbarItemName {
  AddFile = 'addFile',
  RemoveFile = 'removeFile',
  RemoveGridItem = 'removeGridItem',
  RemoveAllGridItems = 'removeAllGridItems',
  DownloadFile = 'downloadFile',
  NamePatternFiles = 'namePatternFiles',
  PreviewDocument = 'previewDocument',
  ShowDxf = 'showDxf',
  ShowGeometry = 'showGeometry',
  DrawGeometry = 'drawGeometry',
  ModifyGeometry = 'modifyGeometry',
  Details = 'details',
  Refresh = 'refresh',
  RangeFromSelectedParcels = 'rangeFromSelectedParcels',
  IncreaseRangeByBuffer = 'increaseRangeByBuffer',
  SaveRangeToWkt = 'saveRangeToWkt',
  SaveRangeToTxt = 'saveRangeToTxt',
  SaveRangeToDxf = 'saveRangeToDxf',
  SaveRangeToGml = 'saveRangeToGml',
  SaveRangeToFiles = 'saveRangeToFiles',
  Documentation = 'documentation',
  MessagesList = 'messagesList',
  Communicator = 'communicator',
}

export class GenericGridToolbarItemDetailsPayload {
  windowInstanceName: KendoWindowInstanceNames;
  windowTitle?: string | (() => string);
  url?: string;
  parseCallback: (dto: any) => BaseDetailSettings[];
}

export class GenericGridToolbarItemDocumentationPayload {
  windowInstanceName: KendoWindowInstanceNames;
  url: string;
  gridToolBarItems: GridToolBarItem[];
  windowTitle?: string;
}

export class GenericGridToolbarItemCommunicatorPayload {
  caseIdKey: string;
  messagesUrl: string;
  recipientsUrl?: string;
  sendMessageUrl: string;
  windowTitle?: string | (() => string);
}

export class GenericGridToolbarItemMessagesListPayload {
  newMessagesUrl: string;
  communicator: GenericGridToolbarItemCommunicatorPayload;
}

export const getGenericGridToolbarItems = (
  c: GkKendoGridComponent<never>,
): GridToolBarItem[] => [
  {
    name: GenericGridToolbarItemName.AddFile,
    svgIcon: iwIconPlus,
    className: 'svg-icon-red',
    title: 'Dodaj',
    callback: (payload) =>
      c.addFile(payload as GenericGridToolbarItemAddFilePayload),
  },
  {
    name: GenericGridToolbarItemName.RemoveFile,
    svgIcon: iwIconMinus,
    className: 'svg-icon-red',
    title: 'Usuń',
    callback: (payload) =>
      c.removeFile(payload as GenericGridToolbarItemRemoveFilePayload),
  },
  {
    name: GenericGridToolbarItemName.RemoveGridItem,
    svgIcon: iwIconMinus,
    className: 'svg-icon-red',
    title: 'Usuń',
    callback: () => c.removeSelectedGridItem(),
  },
  {
    name: GenericGridToolbarItemName.RemoveAllGridItems,
    svgIcon: iwIconCross,
    className: 'svg-icon-red',
    title: 'Usuń wszystko',
    text: 'Usuń wszystko',
    showText: 'overflow',
    callback: () => c.removeAllGridItems(),
  },
  {
    name: GenericGridToolbarItemName.DownloadFile,
    iconClass: 'fa fa-download',
    title: 'REPORTS_IN_SCOPE.DOCS.DOWNLOAD_ALL',
    callback: (payload) =>
      c.downloadFile(payload as GenericGridToolbarItemDownloadFilePayload),
  },
  {
    name: GenericGridToolbarItemName.NamePatternFiles,
    svgIcon: iwIconInfo,
    title: 'WORK_DIGITAL_DOCS.GRID_TOOLBAR.NAME_PATTERN_FILES',
    callback: () => c.showNamePatternFilesWindow(),
  },
  {
    name: GenericGridToolbarItemName.PreviewDocument,
    iconClass: 'fa fa-file-text-o',
    title: 'REPORTS_IN_SCOPE.DOCS.PREVIEW_DOCUMENT',
    callback: (payload) =>
      c.showDocumentPreviewWindow(
        payload as GenericGridToolbarItemPreviewFilePayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.ShowDxf,
    svgIcon: iwIconShowDxf,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.SHOW_DXF',
    callback: (payload) =>
      c.showGeomDxfOnMap(payload as GenericGridToolbarItemPreviewFilePayload),
  },
  {
    name: GenericGridToolbarItemName.ShowGeometry,
    svgIcon: iwIconZobaczZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.MAP_PREVIEW',
    showText: 'overflow',
    callback: (payload) =>
      c.onShowGeometryToolbarButtonClick(
        payload as GenericGridToolbarItemShowGeometryPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.DrawGeometry,
    svgIcon: iwIconWrysujZakres,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DRAW_ON_MAP',
    showText: 'overflow',
    callback: (payload) =>
      c.onDrawGeometryToolbarButtonClick(
        payload as GenericGridToolbarItemShowGeometryPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.Details,
    svgIcon: iwIconColorSz,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.DETAILS',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.DETAILS',
    showText: 'overflow',
    callback: (payload) =>
      c.onDetailsToolbarButtonClick(
        payload as GenericGridToolbarItemDetailsPayload,
      ),
  },
  {
    name: GenericGridToolbarItemName.Documentation,
    svgIcon: iwIconCamera2,
    title: 'GK_KENDO_GRID.DOCUMENTS.TITLE',
    text: 'GK_KENDO_GRID.DOCUMENTS.TITLE',
    showText: 'overflow',
    callback: (payload): void => {
      c.openDocumentationWindow(
        payload as GenericGridToolbarItemDocumentationPayload,
      );
    },
  },
  {
    name: GenericGridToolbarItemName.MessagesList,
    svgIcon: iwIconEmail,
    title: 'ACCEPTED_WORK.NEW_MESSAGES_LIST',
    text: 'ACCEPTED_WORK.NEW_MESSAGES_LIST',
    showText: 'overflow',
    callback: (payload): void => {
      c.openMessagesListWindow(
        payload as GenericGridToolbarItemMessagesListPayload,
      );
    },
  },
  {
    name: GenericGridToolbarItemName.Communicator,
    svgIcon: iwIconBubbles2,
    className: 'svg-icon-info',
    title: 'ACCEPTED_WORK.COMMUNICATOR',
    text: 'ACCEPTED_WORK.COMMUNICATOR',
    showText: 'overflow',
    callback: (payload): void => {
      c.openCommunicator(payload as GenericGridToolbarItemCommunicatorPayload);
    },
  },
  {
    name: GenericGridToolbarItemName.Documentation,
    svgIcon: iwIconCamera2,
    title: 'GK_KENDO_GRID.DOCUMENTS.TITLE',
    text: 'GK_KENDO_GRID.DOCUMENTS.TITLE',
    showText: 'overflow',
    callback: (payload): void => {
      c.openDocumentationWindow(
        payload as GenericGridToolbarItemDocumentationPayload,
      );
    },
  },
  {
    name: GenericGridToolbarItemName.Refresh,
    iconClass: 'fa fa-refresh',
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.REFRESH',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.REFRESH',
    showText: 'overflow',
    callback: () => c.rebind(),
  },
  {
    name: GenericGridToolbarItemName.SaveRangeToFiles,
    svgIcon: iwIconColorZakresZapisz,
    title: 'GK_KENDO_GRID.GRID_TOOLBAR.SAVE_RANGE',
    text: 'GK_KENDO_GRID.GRID_TOOLBAR.SAVE_RANGE',
    showText: 'overflow',
    type: GridToolBarItemType.Dropdown,
    data: [
      {
        name: GenericGridToolbarItemName.SaveRangeToWkt,
        title: 'WKT',
        text: 'WKT',
        callback: (payload) =>
          c.saveRangeToFile(
            RangeTypeValue.Wkt,
            payload as GenericGridToolbarSaveGeometryInFilePayload,
          ),
      },
      {
        name: GenericGridToolbarItemName.SaveRangeToTxt,
        title: 'TXT',
        text: 'TXT',
        callback: (payload) =>
          c.saveRangeToFile(
            RangeTypeValue.Txt,
            payload as GenericGridToolbarSaveGeometryInFilePayload,
          ),
      },
      {
        name: GenericGridToolbarItemName.SaveRangeToDxf,
        title: 'DXF',
        text: 'DXF',
        callback: (payload) =>
          c.saveRangeToFile(
            RangeTypeValue.Dxf,
            payload as GenericGridToolbarSaveGeometryInFilePayload,
          ),
      },
      {
        name: GenericGridToolbarItemName.SaveRangeToGml,
        title: 'GML',
        text: 'GML',
        callback: (payload) =>
          c.saveRangeToFile(
            RangeTypeValue.Gml,
            payload as GenericGridToolbarSaveGeometryInFilePayload,
          ),
      },
    ],
  },
];

export interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export type ExtendedGkKendoGridComponent<T extends GkKendoGridComponent<any>> =
  T;

export interface ExtendedState extends State {
  [key: string]: any;
}

export interface KendoWindowWithItemSelectionCallbackResult<C, T, U> {
  window: ComponentRef<WindowComponent>;
  content: C;
  selectedItems: GkKendoGridItem<T, U>[];
}
