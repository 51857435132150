import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';

export class Bdot500Details {
  static fromApiToApp(data: Bdot500DetailsDto[]): BaseDetailSettings[] {
    return data.map((bdot500Item) => {
      return {
        title: bdot500Item.MzAtrybutNazwa,
        value: bdot500Item.MzSloAtrybutNazwa,
        type: DetailType.Text,
      };
    });
  }
}

export interface Bdot500DetailsDto {
  MzAtrybutNazwa: string;
  MzSloAtrybutNazwa: string;
}
