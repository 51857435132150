import { Component, OnInit } from '@angular/core';
import { GkKendoGridComponent, WladajacyResponseDto } from '@gk/gk-modules';
import { FspBuildingsOwnerGridDataService } from './fsp-buildings-owner-grid-data.service';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FspBuildingsGridDataService } from '../fsp-buildings-register-grid/fsp-buildings-grid-data.service';
import {
  fspOwnersGridColumns,
  getFspOwnersGridToolbarItems,
} from '../../fsp-owners-grid/fsp-owners-grid-model';

@Component({
  selector: 'app-fsp-buildings-owners-grid',
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspBuildingsOwnerGridComponent
  extends GkKendoGridComponent<WladajacyResponseDto>
  implements OnInit
{
  constructor(
    private fspBuildingsOwnerGridDataService: FspBuildingsOwnerGridDataService,
    private fspBuildingsGridDataService: FspBuildingsGridDataService,
  ) {
    super(fspBuildingsOwnerGridDataService);
  }

  override topGridToolbarItems = getFspOwnersGridToolbarItems();
  override columns = fspOwnersGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  ngOnInit(): void {
    this.subscribeBuildingsGridSelected();
  }

  subscribeBuildingsGridSelected(): void {
    this.fspBuildingsGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.fspBuildingsOwnerGridDataService.clearGridData();
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.fspBuildingsOwnerGridDataService.next({
          data: selection.selectedItems[0].Wladajacy,
          total: selection.selectedItems[0].Wladajacy.length,
        });
      });
  }
}
