export enum MainRoutes {
  PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan = 'extract-from-local-spatial-development-plan',
  PlanningDepartmentExtractFromStudy = 'extract-from-study',
  PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan = 'certificate-from-local-spatial-development-plan',
  Designer = 'designer-portal',
  Przp = 'free-services-portal',
  GrantingRights = 'granting-rights-portal',
  EcoPortal = 'eco-portal',
  RoadExitPortal = 'road-exit-portal',
  ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse = 'excluding-land-from-agricultural-production-agricultural-land-use',
  ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse = 'excluding-land-from-agricultural-production-non-agricultural-land-use',
  LaneOccupancyPortalInfrastructureDevices = 'lane-occupancy-infrastructure-devices',
  LaneOccupancyPortalBuildingObjects = 'lane-occupancy-building-objects',
  BuildingPermitTransfer = 'building-permit-transfer',
  LaneOccupancyPortalBuildingWorks = 'lane-occupancy-building-works',
  EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest = 'environmental-protection-portal-simplified-forest-management-plan-request',
  EnvironmentalProtectionPortalFishingCardRequest = 'environmental-protection-portal-fishing-card-request',
  EnvironmentalProtectionPortalWaterEquipmentRequest = 'environmental-protection-portal-water-equipment-request',
  EnvironmentalProtectionPortalTreesAndShrubsFellingRequest = 'environmental-protection-portal-trees-and-shrubs-felling',
  ConstructionPortalConstructionNotificationRequest = 'construction-portal-construction-notification-request',
  ConstructionPortalDemolitionNotificationRequest = 'construction-portal-demolition-notification-request',
  ConstructionPortalPremiseIndependenceRequest = 'construction-portal-premise-independence-notification-request',
  ConstructionPortalConstructionLogRequest = 'construction-portal-construction-log-request',
  OfficeDepartmentsPortal = 'office-departments-portal',
  OfficeDepartmentsPortalHub = 'office-departments-portal/departments',
  AddressNumberRequestPortal = 'address-number-request-portal',
  NoAccess = 'no-access',
  NotFound = '404',
  SignIn = 'sign-in',
  SurveyorPortal = 'surveyor-portal',
  AppraiserPortal = 'appraiser-portal',
  SpatialInformationPortal = 'spatial-information-portal',
  Department = 'office-departments-portal/department',
  GeoDepartment = 'office-departments-portal/department/GEO',
  PropertyLease = 'property-lease',
  SupplementaryParcelPurchase = 'supplementary-parcel-purchase',
  OwnershipConversion = 'ownership-conversion',
  OwnershipConversionFeeCalculationRequest = 'ownership-conversion-fee-calculation-request',
  StreetNaming = 'street-naming',
  CommunalPremisePurchase = 'communal-premise-purchase',
  LandAndBuildingRegisterDataChangesNotificationRequest = 'land-and-building-register-data-changes-notification',
  LandAndBuildingRegisterDataUpdateRequest = 'land-and-building-register-data-update-request',
  LandAndBuildingRegisterDataUpdateUponEntitiesRequest = 'land-and-building-register-data-update-request-by-entity',
  SoilClassificationOfLandRequest = 'soil-classification-of-land-request',
  PresentationCommentsLandBuildingRegistryProject = 'presentation-comments-land-building-registry-project',
  AccessToInformationOnEnvironmentAndItsProtection = 'access-to-information-on-environment-and-its-protection',
  BuildingPermit = 'building-permit',
  DemolitionPermit = 'demolition-permit',
  BuildingOrPartUseChange = 'building-or-part-use-change',
  TemporaryStructureBuildingPermit = 'temporary-structure-building-permit',
  BuildingPermitModificationDecision = 'building-permit-modification-decision',
  NotObjectedNotificationRightsObligationsTransfer = 'not-objected-notification-rights-obligations-transfer',
  PlotLandDevArchConstrProjSeparateDecision = 'plot-land-dev-arch-constr-proj-separate-decision',
  AdjacentPropertyNeedEnterDecision = 'adjacent-property-need-enter-decision',
  SingleFamilyBuilding70ConstructionConversion = 'single-family-building-70-construction-conversion',
  SingleFamilyBuildingConstructionConversion = 'single-family-building-construction-conversion',
  GeodeticAndCartographicServiceDataRequest = 'geodetic-and-cartographic-service-data-request',
  CoordinationMeetingPortal = 'coordination-meeting-portal',
  InterMod = 'intermod',
  PermanentManagementFeeDiscountDecisionGrantingRequest = 'permanent-management-fee-discount-decision-request',
  BailiffPortal = 'bailiff-portal',
  AccessibilityStatementRegular = 'accessibility-statement-regular',
  AccessibilityStatementMobile = 'accessibility-statement-mobile',
  AccessibilityStatementForClient = 'accessibility-statement-forClient',
}

export enum PzInitRoutes {
  Designer = '/api/pz/init/projektant',
  Przp = '/api/pz/init/przp',
  GrantingRights = '/api/pz/init/nadanieUprawnienSystemowych',
  RoadExit = '/api/pz/init/budPrzebudZjazd',
  LaneOccupancyInfrastructureDevices = '/api/pz/init/pasZajeciePodUrzadzenie',
  LaneOccupancyBuildingObjects = '/api/pz/init/pasZajeciePodReklame',
  ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse = '/pz/init/wylGrZProdRolnej',
  ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse = '/pz/init/wylFaktyczneGrZProdRolnej',
  BuildingPermitTransfer = '/api/pz/init/przeniesieniePozwoleniaBudowy',
  LaneOccupancyBuildingWorks = '/api/pz/init/pasZajecieRobotyBudowlane',
  EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest = '/pz/init/objecieDzialkiUpul',
  EnvironmentalProtectionPortalFishingCardRequest = '/pz/init/wydanieKartyWedkarskiej',
  EnvironmentalProtectionPortalWaterEquipmentRequest = '/pz/init/nadanieNrSprzetuPlywajacego',
  EnvironmentalProtectionPortalTreesAndShrubsFellingRequest = '/pz/init/wycinkaDrzewIKrzewow',
  ConstructionPortalConstructionNotificationRequest = '/pz/init/zgloszenieZamiaruBudowy',
  ConstructionPortalDemolitionNotificationRequest = '/pz/init/zgloszenieRozbiorki',
  ConstructionPortalConstructionLogRequest = '/pz/init/rejestracjaDziennikaBudowy',
  ConstructionPortalPremiseIndependenceRequest = '/pz/init/samodzielnoscLokalu',
  PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest = '/pz/init/wypisWyrysZPlanuMiejscowego',
  PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest = '/pz/init/zaswiadczenieZPlanuMiejscowego',
  PlanningDepartmentExtractFromStudy = '/pz/init/wypisWyrysZeStudiumUwarunkowan',
  AddressNumberRequestPortal = '/api/pz/init/ustalenieNumeruPorzadkowego',
  PzInitSystemUrl = '/pz/init/system',
  OwnershipConversion = '/api/pz/init/przeksztalcenieWPrawoWlasnosci',
  OwnershipConversionFeeCalculationRequest = '/api/pz/init/naliczenieOplatyPrzeksztalcenieUzytkowaniaWieczystegoWeWlasnosc',
  SupplementaryParcelPurchase = '/api/pz/init/zakupDzialkaUzupelniajaca',
  PropertyLease = '/api/pz/init/wydzierzawienieNieruchomosciKomunalnej',
  StreetNaming = '/api/pz/init/nadanieNazwyUlicy',
  CommunalPremisePurchase = '/api/pz/init/nabycieLokaluKomunalnego',
  LandAndBuildingRegisterDataChangesNotificationRequest = '/api/pz/init/zgloszenieZmianyDanychEgib',
  LandAndBuildingRegisterDataUpdateRequest = '/api/pz/init/aktualizacjaDanychEgib',
  LandAndBuildingRegisterDataUpdateUponEntitiesRequest = '/api/pz/init/aktualizacjaDanychEgibPrzezPodmiotWlad',
  SoilClassificationOfLandRequest = '/api/pz/init/gleboznawczaKlasyfikacjaGruntow',
  PresentationCommentsLandBuildingRegistryProject = '/api/pz/init/zgloszenieUwagDoWylozeniaProjektuEwidencji',
  AccessToInformationOnEnvironmentAndItsProtection = '/api/pz/init/informacjaStanSrodowiska',
  BuildingPermit = '/api/pz/init/pozwolenieNaBudowe',
  DemolitionPermit = '/api/pz/init/pozwolenieNaRozbiorke',
  BuildingOrPartUseChange = '/api/pz/init/zmianaSposUzytkBud',
  TemporaryStructureBuildingPermit = '/api/pz/init/pozwolenieNaBudoweTymczasowegoBud',
  BuildingPermitModificationDecision = '/api/pz/init/zmianaPozwolenieNaBudowe',
  NotObjectedNotificationRightsObligationsTransfer = '/api/pz/init/przeniesieniePrawWynikajacychZeZgloszenia',
  PlotLandDevArchConstrProjSeparateDecision = '/api/pz/init/zatwierdzenieProjektuZagospDzialki',
  AdjacentPropertyNeedEnterDecision = '/api/pz/init/wejscieNaSasiedniaNieruchomosc',
  SingleFamilyBuilding70ConstructionConversion = '/api/pz/init/zgloszenieBudowyBudynkuJednorodzinnego70',
  SingleFamilyBuildingConstructionConversion = '/api/pz/init/zgloszenieBudowyBudynkuJednorodzinnego',
  GeodeticAndCartographicServiceDataRequest = '/api/pz/init/udostMatSluzbyGeodezyjne',
  PermanentManagementFeeDiscountDecisionGrantingRequest = '/api/pz/init/bonifikataOplataTrwalyZarzad',
}

export enum MainRouteParams {
  ParentRoute = 'parentRoute',
}
