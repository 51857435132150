import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { FreeServicesRequestDetails } from './free-services-request-details.model';
import { FreeServicesRequestListGridDataService } from '../free-services-request-list-grid/free-services-request-list-grid-data.service';
import { FreeServicesRequestListGridDto } from '../free-services-request-list-grid/free-services-request-list-grid-data-model';

@Component({
  selector: 'app-control-network-details',
  templateUrl:
    '../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
  standalone: false,
})
export class FreeServicesRequestDetailsComponent
  extends BaseDetailsComponent<FreeServicesRequestListGridDto>
  implements OnInit
{
  override parseCallback = FreeServicesRequestDetails.fromApiToApp;

  constructor(
    private freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadDataDetails();
  }

  loadDataDetails(): void {
    this.freeServicesRequestListGridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        if (selection.selectedKeys?.length) {
          this.setDetails(selection?.selectedItems[0]);
        } else {
          this.windowRef.close();
        }
      });
  }
}
