import { Injectable } from '@angular/core';
import { GridDataService } from '@gk/gk-modules';
import { FspBuildingsGridDto } from './fsp-buildings-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class FspBuildingsGridDataService extends GridDataService<FspBuildingsGridDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
