<kendo-gridlayout
  [cols]="['50%', '50%']"
  class="h-100 m-0"
  [rows]="['100%']"
  [gap]="{ rows: 1, cols: 2 }"
>
  <ng-container *ngComponentOutlet="gridComponent"></ng-container>

  <kendo-gridlayout
    [rows]="['7%', '93%']"
    class="h-100 m-0"
    [gap]="{ rows: 2, cols: 0 }"
  >
    <gk-kendo-grid-toolbar
      #gridToolbar
      [gridToolbarItems]="getToolbarItems()"
      [overflow]="gridToolbarOverflow"
      [parent]="gridToolbar"
      [position]="'center'"
      [horizontalPosition]="'center'"
    ></gk-kendo-grid-toolbar>
    <ng-container *ngComponentOutlet="detailsComponent"></ng-container>
  </kendo-gridlayout>
</kendo-gridlayout>
