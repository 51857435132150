import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoGridWithDetailsComponent } from '../../../../../gk-kendo';
import { Bdot500DetailsDetailsComponent } from './bdot500-details-details/bdot500-details-details.component';
import { Bdot500DetailsGridDto } from './bdot500-details-grid/bdot500-details-grid-data-model';
import { Bdot500DetailsGridDataService } from './bdot500-details-grid/bdot500-details-grid-data.service';
import { Bdot500DetailsGridComponent } from './bdot500-details-grid/bdot500-details-grid.component';
import { Bdot500DetailsWrapperService } from './bdot500-details-wrapper.service';

@Component({
  selector: 'gk-bdot500-details-wrapper',
  standalone: false,
  templateUrl:
    '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component.html',
})
export class Bdot500DetailsWrapperComponent extends GkKendoGridWithDetailsComponent<Bdot500DetailsGridDto> {
  override gridComponent = Bdot500DetailsGridComponent;
  override detailsComponent = Bdot500DetailsDetailsComponent;

  protected override handleSelectedEnabled = true;
  protected override selectionTextKey = 'BDOT500_DETAILS.SELECTION_TEXT';
  protected override noRecordsTextKey = 'BDOT500_DETAILS.NO_RECORDS_TEXT';

  constructor(
    private bdot500DetailsWrapperService: Bdot500DetailsWrapperService,
    bdot500GridDataService: Bdot500DetailsGridDataService,
    translateService: TranslateService,
  ) {
    super(bdot500GridDataService, translateService);
  }

  protected override handleLandSelected(
    selectedItem: Bdot500DetailsGridDto,
  ): void {
    this.bdot500DetailsWrapperService.handleLandSelected(selectedItem.ObiektId);
  }
}
