import { Injectable } from '@angular/core';
import { GridDataService, KlasouzytekResponseDto } from '@gk/gk-modules';

@Injectable({
  providedIn: 'any',
})
export class FspClassUseGridDataService extends GridDataService<KlasouzytekResponseDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
