import { Injectable } from '@angular/core';
import { GridDataService } from '../../../../../../gk-kendo';
import { MatrixDetailsGridDto } from './matrix-details-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class MatrixDetailsGridDataService extends GridDataService<MatrixDetailsGridDto> {
  constructor() {
    super('api/mapa/osnowa/search', 'POST');
  }
}
