import { ColumnSetting, ColumnType, GridToolBarItem } from '@gk/gk-modules';
import { FspPremissesGridComponent } from './fsp-premisses-grid.component';

export const getFspPremissesGridToolbarItems = (
  c: FspPremissesGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: 'print',
      iconClass: 'fa fa-print',
      title: 'FREE_PORTAL_SERVICES.PRINT',
      callback: () => c.downloadReport(),
    },
  ];
};

export const fspPremissesGridColumns: ColumnSetting[] = [
  {
    field: 'Identyfikator',
    title: 'Identyfikator',
    type: ColumnType.Text,
    width: 230,
  },
  {
    field: 'NrPorzadkowy',
    title: 'Nr porządkowy',
    type: ColumnType.Text,
    width: 110,
  },
  {
    field: 'SloFunLokNazwa',
    title: 'Rodzaj',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'Kondygnacja',
    title: 'Kondygnacja',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'PowUzytkowa',
    title: 'Pow. użytkowa',
    type: ColumnType.Text,
    width: 110,
  },
  {
    field: 'PowPomieszczenPrzynaleznych',
    title: 'Pow. pom. przynależnych',
    type: ColumnType.Text,
    width: 190,
  },
  {
    field: 'ListaPomieszczenPrzynaleznych',
    title: 'Pom. przynależne',
    type: ColumnType.Text,
    width: 260,
  },
  {
    field: 'NrKw',
    title: 'Nr KW',
    type: ColumnType.Text,
    width: 160,
  },
  {
    field: 'Adres',
    title: 'Adres',
    type: ColumnType.Text,
    width: 280,
  },
  {
    field: 'JednostkaRejestrowaNazwa',
    title: 'Jedn. rejestrowa',
    type: ColumnType.Text,
    width: 120,
  },
];
