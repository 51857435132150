import { Component } from '@angular/core';
import { FreeServicesRequestSearchFormService } from './free-services-request-search-form.service';
import { FormGroup } from '@angular/forms';
import {
  freeServicesRequestSearchFormActionButtonsConfig,
  FreeServicesRequestSearchFormModel,
  freeServicesRequestSearchFormModel,
} from './free-services-request-search-form.model';
import { GkWindowRef } from '@gk/gk-modules';
import * as _ from 'lodash';
import { FreeServicesRequestListGridDataService } from '../free-services-request-list-grid/free-services-request-list-grid-data.service';

@Component({
  selector: 'app-free-services-request-search-form',
  templateUrl: './free-services-request-search-form.component.html',
  standalone: false,
})
export class FreeServicesRequestSearchFormComponent {
  windowRef: GkWindowRef;
  form = new FormGroup({});
  formlyFormConfig = this.freeServicesRequestSearchFormService.getFormConfig();
  formModel = _.cloneDeep(freeServicesRequestSearchFormModel);
  freeServicesRequestSearchFormActionButtonsConfig =
    freeServicesRequestSearchFormActionButtonsConfig;
  constructor(
    private freeServicesRequestSearchFormService: FreeServicesRequestSearchFormService,
    private freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {}

  clearForm(): void {
    this.form.reset();
  }

  onFormSubmit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this.freeServicesRequestListGridDataService
        .queryByState(
          {
            filter: FreeServicesRequestSearchFormModel.fromAppToApi(
              this.formModel,
              this.windowRef.content.instance.parent.displayRequestArchive,
            ),
          },
          true,
          '/api/interesant/przp/zamowienia/search',
        )
        .subscribe(() => {
          this.windowRef.close();
        });
    }
  }

  onCancel(): void {
    this.windowRef.close();
  }
}
