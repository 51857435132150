import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ColumnHeader,
  defaultMapGeometryStyles,
  GkKendoGridMapService,
  GkKendoWindowActionButtons,
  GkKendoWindowService,
  initialToolsStateWithButtonText,
  KendoWindowInstanceNames,
  ListConfigs,
  MapId,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  SourceType,
  ToolType,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';
import { FreeServicesRequestListComponent } from '../kendo-request-list/free-services-request-list.component';

@Component({
  selector: 'app-free-services-map',
  templateUrl: './free-services-map.component.html',
  styleUrls: ['./free-services-map.component.scss'],
  standalone: false,
})
export class FreeServicesMapComponent implements OnInit, OnDestroy {
  private isAlive = true;
  freeServicesMapState = MapState.getInitialStruct();
  initialToolsState = _.cloneDeep(initialToolsStateWithButtonText);
  defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  availableParcelsListConfigs: ListConfigs;

  constructor(
    private mapSettingsService: MapSettingsService,
    private mapStateService: MapStateService,
    private gkKendoWindowService: GkKendoWindowService,
    private configManagerService: ConfigManagerService,
    public gkKendoGridMapService: GkKendoGridMapService,
  ) {}

  ngOnInit(): void {
    this.subscribeToMapSettings();
    this.fetchAvailableParcelsListConfigs();
    this.openFreeServicesPortalMainWindow();
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.FreeServices)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((freeServicesMapSettings) =>
        this.setFreeServicesMapState(freeServicesMapSettings),
      );
  }

  fetchAvailableParcelsListConfigs(): void {
    this.availableParcelsListConfigs =
      this.configManagerService.getConfigForAvailableParcelsList();
  }

  openFreeServicesPortalMainWindow(): void {
    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: null,
      windowInstanceName: KendoWindowInstanceNames.FSPRequestList,
      title: 'FREE_PORTAL_SERVICES.REQUEST_LIST',
      content: FreeServicesRequestListComponent,
      height: 560,
      width: 880,
      hiddenActionButtons: [GkKendoWindowActionButtons.close],
    });
  }

  setFreeServicesMapState(freeServicesMapSettings: MapSettings): void {
    this.gkKendoGridMapService.$mapState.next(
      new MapState(
        MapId.FreeServices,
        this.mapStateService.getViewState(
          MapId.FreeServices,
          freeServicesMapSettings,
        ),
        this.mapStateService.getToolbarState(
          [ToolType.LandParcel],
          freeServicesMapSettings.papers,
          undefined,
          undefined,
          true,
        ),
        {
          ...this.initialToolsState,
          [ToolType.LandParcel]: {
            buttonText: 'GK.MAP.TOOL_TYPE.LAND_PARCEL',
            isActive: false,
            icon: 'gk-map-icon-select-land-parcel',
            label: 'GK.MAP.SELECT_LAND_PARCEL',
            mapObjects: [],
            mapGeometryStyleConfig:
              this.initialToolsState[ToolType.LandParcel]
                .mapGeometryStyleConfig,
            [SourceType.AttributesForm]:
              this.initialToolsState[ToolType.LandParcel][
                SourceType.AttributesForm
              ],
          },
        },
        this.mapStateService.getLayersState(
          MapId.FreeServices,
          freeServicesMapSettings,
          MapPortalName.FreeServices,
        ),
        [
          new MapObjectTableState(
            [
              new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
              new ColumnHeader('mapObjectNumber', 'GK.MAP.PARCEL_NUMBER'),
            ],
            undefined,
            this.defaultMapGeometryStyles,
            true,
            false,
            true,
          ),
        ],
      ),
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
