import {
  ErrorEvent,
  FileInfo,
  SelectEvent,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { UploadDocType } from './gk-kendo-upload-doc-type-dictionary/gk-kendo-upload-doc-type-dictionary.model';
import { EventEmitter } from '@angular/core';
import { KendoWindowInstanceNames } from '../services/kendo-window/kendo-window.model';

export interface UploadConfig {
  parentWindowInstanceName: KendoWindowInstanceNames;
  uploadSaveUrl: string;
  uploadDocTypeDict?: UploadDocTypeName;
  uploadData?: GkKendoUploadData;
  uploadEventHandler?: (e: EventEmitter<GkKendoUploadEvent>) => void;
  onUploadButtonClick?: (
    uploadEvent: EventEmitter<GkKendoUploadEvent>,
  ) => Promise<any>;
  onUploadSuccess?: (uploadEvent: GkKendoSuccessEvent) => void;
  onUploadError?: (uploadEvent: ErrorEvent) => void;
}

export enum UploadDocTypeName {
  Default = 'default',
  WorkReports = 'workReports',
  Work = 'work',
  FeeConfirmation = 'feeConfirmation',
  CoordinationMeetingPortalCase = 'coordinationMeetingPortalCase',
  Other = 'other',
}

export interface NewDokumentPowiazanyRequestDto {
  NazwaPliku: string;
  Plik: File;
  OwnerId: number;
  RodzajOpracowaniaDokumentu: number;
  Opis: string;
  WktPlik: string;
}

export enum FileUploadOwner {
  WorkReport = 'operat',
  ReportWaiting = 'kerg_czeka',
  FeeWaiting = 'opl_czeka',
  Invoice = 'zamFakt',
  ZudSpr = 'zud_spr',
}

export interface GkKendoUploadData {
  Owner?: FileUploadOwner;
  OwnerId?: number | string;
  FaktUuid?: string;
  ZudUuid?: string;
}

export interface GkKendoUploadFileInfo extends FileInfo {
  typeOfDoc: UploadDocType;
  description?: string;
}

export class GkKendoUploadEvent extends UploadEvent {
  override data: NewDokumentPowiazanyRequestDto;
  override files: Array<GkKendoUploadFileInfo>;
}

export class GkKendoSelectEvent extends SelectEvent {
  override files: Array<GkKendoUploadFileInfo>;
}

export class GkKendoSuccessEvent extends SuccessEvent {
  override files: Array<GkKendoUploadFileInfo>;
}
