export interface TextDetailSettings {
  title: string;
  value: string;
  type: DetailType.Text;
}

export interface BooleanDetailSettings {
  title: string;
  value: boolean;
  type: DetailType.Boolean;
}

export interface DateDetailSettings {
  title: string;
  value: string;
  type: DetailType.Date;
}

export interface NumericDetailSettings {
  title: string;
  value: number;
  type: DetailType.Numeric;
}

export interface HtmlDetailSettings {
  title: string;
  value: string;
  type: DetailType.Html;
}

export type BaseDetailSettings =
  | TextDetailSettings
  | BooleanDetailSettings
  | DateDetailSettings
  | NumericDetailSettings
  | HtmlDetailSettings;

export enum DetailType {
  Text = 'text',
  Boolean = 'boolean',
  Date = 'date',
  Numeric = 'numeric',
  Html = 'html',
}
