import { AfterViewInit, Component, Input } from '@angular/core';
import { GkKendoGridComponent, RequestType } from '@gk/gk-modules';
import { FspBuildingsGridDataService } from './fsp-buildings-grid-data.service';
import { FspBuildingsGridDto } from './fsp-buildings-grid-data-model';
import {
  getFspBuildingsGridToolbarItems,
  fspBuildingsGridColumns,
} from './fsp-buildings-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { FreeServicesParcelRequestGridDto } from '../../fsp-parcel-request-grid/free-services-parcel-request-grid-data-model';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesParcelRequestGridDataService } from '../../fsp-parcel-request-grid/free-services-parcel-request-grid-data.service';
import { FspBuildingRegisterComponent } from '../fsp-building-register.component';

@Component({
  selector: 'app-fsp-buildings-grid',
  templateUrl:
    '../../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FspBuildingsGridComponent
  extends GkKendoGridComponent<FspBuildingsGridDto>
  implements AfterViewInit
{
  constructor(
    public fspBuildingsGridDataService: FspBuildingsGridDataService,
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
  ) {
    super(fspBuildingsGridDataService);
  }

  @Input() parent: FspBuildingRegisterComponent;
  override topGridToolbarItems = getFspBuildingsGridToolbarItems(this);
  override columns = fspBuildingsGridColumns;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;
  selectedParcelGridItem: FreeServicesParcelRequestGridDto;
  selectedBuildingGridItem: FspBuildingsGridDto;

  override ngAfterViewInit(): void {
    this.subscribeToParcelListGridSelectedKeys();
    this.subscribeToBuildingsGridSelectedKeys();
  }

  subscribeToParcelListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelGridItem = selection.selectedRows[0].dataItem;
        this.fetchGridData();
      });
  }

  subscribeToBuildingsGridSelectedKeys(): void {
    this.fspBuildingsGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedBuildingGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedBuildingGridItem = selection.selectedRows[0].dataItem;
      });
  }

  fetchGridData(): void {
    this.fspBuildingsGridDataService
      .queryByState(
        null,
        null,
        `/api/interesant/przp/dzialka/${this.selectedParcelGridItem.KdzialkaUuid}/budynki`,
      )
      .subscribe((response) => {
        if (!response.data.length) {
          this.toastr.warning(
            this.translateService.instant('FREE_PORTAL_SERVICES.NO_BUILDINGS'),
          );
        }
      });
  }

  downloadReport(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    this.downloadService.downloadAndSaveFile(
      `/api/interesant/przp/dzialka/${this.selectedParcelGridItem.KdzialkaUuid}/budynek/${this.selectedBuildingGridItem.Uuid}/wydruk`,
      {},
      RequestType.Post,
    );
  }
}
