import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { AdressPointDetailsGridDto } from '../map-address-point-details-grid/map-address-point-details-grid-data-model';
import { MapPointsDetailsGridDataService } from '../map-address-point-details-grid/map-address-point-details-grid-data.service';
import { AdressPointDetails } from './map-address-point-details-details.model';

@Component({
  selector: 'gk-map-address-point-details-details',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
})
export class MapAddressPointDetailsDetailsComponent
  extends BaseDetailsComponent<AdressPointDetailsGridDto>
  implements OnInit
{
  override parseCallback = AdressPointDetails.fromApiToApp;

  constructor(
    private mapPointsDetailsGridDataService: MapPointsDetailsGridDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAddressPointChange();
  }

  private subscribeToAddressPointChange(): void {
    this.mapPointsDetailsGridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItem = selection?.selectedItems[0];
        if (selectedItem) {
          this.loadDetailsWithData(selectedItem);
        }
      });
  }
}
