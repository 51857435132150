import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';
import { MatrixDetailsGridDto } from '../matrix-details-grid/matrix-details-grid-data-model';
export class MatrixDetails {
  static fromApiToApp(data: MatrixDetailsGridDto): BaseDetailSettings[] {
    return [
      {
        title: 'MATRIX_DETAILS.POINT_NUMBER',
        value: data?.NrPunktu,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.OLD_POINT_NUMBER',
        value: data?.StaryNumerPunktu,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.IDENTIFIER',
        value: data?.Identyfikator,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.STATE',
        value: data?.Stan,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.HORIZONTAL_NETWORK',
        value: data?.OsnowaPozioma,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.HORIZONTAL_NETWORK_CLASS',
        value: data?.KlasaOsnowyPoziomej,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.VERTICAL_NETWORK',
        value: data?.OsnowaWysokosciowa,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.VERTICAL_NETWORK_CLASS',
        value: data?.KlasaOsnowyWysokosciowej,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.LATITUDE',
        value: data?.SzerokoscGeograficzna,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.ELLIPSOIDAL_HEIGHT',
        value: data?.WysokoscElipsoidalna,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.COORDINATE_X',
        value: data?.WspXy[0]?.WspolrzedneX,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.COORDINATE_Y',
        value: data?.WspXy[0]?.WspolrzedneY,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.COORDINATE_Z',
        value: data?.WspH[0]?.Wysokosc,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.BUILDING_TYPE',
        value: data?.TypZabudowy,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.STABILIZATION_TYPE',
        value: data?.TypStabilizacji,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.COORDINATE_DETERMINATION',
        value: data?.SposobWyznaczeniaWspolrzednych,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.HEIGHT_DETERMINATION',
        value: data?.SposobWyznaczeniaWysokosci,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.POSITION_ERROR',
        value: data?.BladPolozenia,
        type: DetailType.Text,
      },
      {
        title: 'MATRIX_DETAILS.TOPOGRAPHIC_DESCRIPTIONS',
        value: data?.OpisyTopograficzneFileUuids.toString(),
        type: DetailType.Text,
      },
    ];
  }
}
