import { ColumnSetting, ColumnType, GridToolBarItem } from '@gk/gk-modules';

export const getFspOwnersGridToolbarItems = (): GridToolBarItem[] => {
  return [];
};

export const fspOwnersGridColumns: ColumnSetting[] = [
  {
    field: 'FormaWladaniaSkrot',
    title: 'F. wł.',
    type: ColumnType.Text,
    width: 80,
  },
  {
    field: 'OsobaNazwa',
    title: 'Osoba',
    type: ColumnType.Text,
    width: 160,
  },
  {
    field: 'Udzial',
    title: 'Udział',
    type: ColumnType.Text,
    width: 90,
  },
  {
    field: 'GrupaRejestrowaNazwa',
    title: 'Grupa Rej.',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'Adres',
    title: 'Adres',
    type: ColumnType.Text,
    width: 200,
  },
  {
    field: 'AdresDoKorespondencji',
    title: 'Adres do korespondencji',
    type: ColumnType.Text,
    width: 180,
  },
  {
    field: 'DataNabycia',
    title: 'Data nabycia',
    type: ColumnType.Date,
    width: 140,
  },
  {
    field: 'DataUplywuWladania',
    title: 'Data upływu wł.',
    type: ColumnType.Date,
    width: 140,
  },
  {
    field: 'Uwagi',
    title: 'Uwagi',
    type: ColumnType.Text,
    width: 180,
  },
];
