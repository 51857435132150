import { ColumnSetting, ColumnType, GridToolBarItem } from '@gk/gk-modules';

export const getFspBorderPointsGridToolbarItems = (): GridToolBarItem[] => {
  return [];
};

export const fspBorderPointsGridColumns: ColumnSetting[] = [
  {
    field: 'Identyfikator',
    title: 'Id',
    type: ColumnType.Text,
    width: 160,
  },
  {
    field: 'PunktNumer',
    title: 'Nr',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'PunktNumerZOperatu',
    title: 'Nr z operatu',
    type: ColumnType.Text,
    width: 110,
  },
  {
    field: 'WspX',
    title: 'X',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'WspY',
    title: 'Y',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'Blad',
    title: 'Błąd',
    type: ColumnType.Text,
    width: 160,
  },
  {
    field: 'Pochodzenie',
    title: 'Pochodzenie',
    type: ColumnType.Text,
    width: 120,
  },
  {
    field: 'Stabilizacja',
    title: 'Stabilizacja',
    type: ColumnType.Text,
    width: 150,
  },
  {
    field: 'ListaOperatow',
    title: 'Operaty',
    type: ColumnType.Text,
  },
  {
    field: 'WspXPierw',
    title: 'X pierw.',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'WspYPierw',
    title: 'Y pierw.',
    type: ColumnType.Text,
    width: 100,
  },
  {
    field: 'UkladNazwa',
    title: 'Układ wsp.',
    type: ColumnType.Text,
    width: 100,
  },
];
