import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyKendoModule } from '@ngx-formly/kendo';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import {
  FormFieldModule,
  InputsModule,
  TextAreaModule,
} from '@progress/kendo-angular-inputs';
import '@progress/kendo-angular-intl/locales/pl/all';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { MenusModule } from '@progress/kendo-angular-menu';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import {
  AttributesFilterService,
  MapRequestsService,
  PolygonTopologyService,
} from '../gk-map/services';
import { DomRefService } from '../services/dom-ref/dom-ref.service';
import { MobileDeviceTextPipe } from '../utils/pipes/mobile-device-text/mobile-device-text.pipe';
import { BaseDocumentationGridService } from './base-documentation-grid/base-documentation-grid.service';
import { FileNamingPatternsComponent } from './components/file-naming-patterns/file-naming-patterns.component';
import { SizeReportsLimitComponent } from './components/gk-size-report-limit/size-reports-limit.component';
import { FormFieldType, FormFieldWrappers } from './formly';
import { FormlyFieldKendoCheckboxComponent } from './formly/custom-types/checkbox.type';
import { FormlyFieldKendoComboboxTypeComponent } from './formly/custom-types/comboboxtype';
import { FormlyFieldKendoDatepickerComponent } from './formly/custom-types/datepicker.type';
import { FormlyFieldKendoInputAutocompleteComponent } from './formly/custom-types/input-autocomplete.type';
import { FormlyFieldKendoInputComponent } from './formly/custom-types/input.type';
import { FormlyFieldKendoMultiSelectComponent } from './formly/custom-types/multiselect.type';
import { FormlyFieldPercentInputComponent } from './formly/custom-types/percent-input';
import { FormlyFieldKendoRadioComponent } from './formly/custom-types/radio.type';
import { FormlyFieldRangePickerComponent } from './formly/custom-types/rangePicker.type';
import { FormlyFieldKendoSelectComponent } from './formly/custom-types/select.type';
import { FormlyFieldKendoSelectRangeComponent } from './formly/custom-types/selectRange.type';
import { FormlyFieldKendoTextAreaComponent } from './formly/custom-types/textarea.type';
import { registerKendoFormlyPresets } from './formly/form-presets/communities-districts-sheets.preset';
import { LandParcelSearchFormComponent } from './formly/forms/land-parcel-search-form/land-parcel-search-form.component';
import { FormlyWrapperFormFieldComponent } from './formly/wrappers/form-field.wrapper';
import { PanelWrapperFieldComponent } from './formly/wrappers/kendo-panel.wrapper';
import { GkKendoDataBindingDirective } from './gk-kendo-data-binding.directive';
import { GkKendoActionButtonsComponent } from './gk-kendo-grid-action-buttons/gk-kendo-action-buttons.component';
import { GkKendoCommunicatorComponent } from './gk-kendo-grid/gk-kendo-communicator/gk-kendo-communicator.component';
import { GkKendoDocumentationGridWrapperComponent } from './gk-kendo-grid/gk-kendo-documentation-grid/gk-kendo-documentation-grid-wrapper.component';
import { GkKendoDocumentationGridComponent } from './gk-kendo-grid/gk-kendo-documentation-grid/gk-kendo-documentation-grid.component';
import { GkKendoGeomFileSelectComponent } from './gk-kendo-grid/gk-kendo-geom-file-select/gk-kendo-geom-file-select.component';
import { GkKendoGridCounterComponent } from './gk-kendo-grid/gk-kendo-grid-footer/gk-kendo-grid-counter/gk-kendo-grid-counter.component';
import { GkKendoGridFooterComponent } from './gk-kendo-grid/gk-kendo-grid-footer/gk-kendo-grid-footer.component';
import { GkKendoGridLegendComponent } from './gk-kendo-grid/gk-kendo-grid-footer/gk-kendo-grid-legend/gk-kendo-grid-legend.component';
import { GkKendoGridMapService } from './gk-kendo-grid/gk-kendo-grid-map.service';
import { GkKendoGridToolbarComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/gk-kendo-grid-toolbar.component';
import { GkToolbarButtonWithInputComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-button-with-input.component';
import { GkToolBarButtonListComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-buttonlist.component';
import { GkToolbarDropdownlistComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-dropdownlist.component';
import { GkToolbarSelectAllCheckboxComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-select-all-checkbox.component';
import { GkToolbarSplitbuttonComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-splitbutton.component';
import { GkToolbarTextComponent } from './gk-kendo-grid/gk-kendo-grid-toolbar/tools/gk-toolbar-text.component';
import { GkKendoGridWithDetailsComponent } from './gk-kendo-grid/gk-kendo-grid-with-details/gk-kendo-grid-with-details.component';
import { GkKendoGridComponent } from './gk-kendo-grid/gk-kendo-grid.component';
import { GkKendoIdsFileSelectComponent } from './gk-kendo-grid/gk-kendo-ids-file-select/gk-kendo-ids-file-select.component';
import { GkKendoMessagesListGridWrapperComponent } from './gk-kendo-grid/gk-kendo-messages-list-grid/gk-kendo-messages-list-grid-wrapper.component';
import { GkKendoMessagesListGridComponent } from './gk-kendo-grid/gk-kendo-messages-list-grid/gk-kendo-messages-list-grid.component';
import { GkMapSheetFormComponent } from './gk-kendo-grid/gk-map-sheet-form/gk-map-sheet-form.component';
import { GkMapSheetFormService } from './gk-kendo-grid/gk-map-sheet-form/gk-map-sheet-form.service';
import { GridToolbarItemsPipe } from './gk-kendo-grid/grid-toolbar-items.pipe';
import { GkKendoUploadService } from './gk-kendo-upload';
import { GkKendoUploadComponent } from './gk-kendo-upload/gk-kendo-upload.component';
import { GkUploadActionButtonsComponentComponent } from './gk-kendo-upload/gk-upload-action-buttons-component/gk-upload-action-buttons-component.component';
import { MapRangePickerGridComponent } from './map-range-picker-grid/map-range-picker-grid.component';
import { DialogManagerService } from './services/gk-dialog-manager.service';
import { GkKendoMessageService } from './services/gk-kendo-message.service';
import { GkKendoWindowService } from './services/kendo-window/gk-kendo-window/gk-kendo-window.service';
import { KendoAngularExtensionsModule } from 'kendo-angular-extensions';
import { FormlyFieldKendoSelectPointComponent } from './formly/custom-types/selectPoint.type';

@NgModule({
  declarations: [
    FormlyFieldPercentInputComponent,
    FormlyFieldKendoInputComponent,
    FormlyFieldKendoSelectComponent,
    FormlyFieldKendoRadioComponent,
    FormlyWrapperFormFieldComponent,
    FormlyFieldKendoDatepickerComponent,
    FormlyFieldRangePickerComponent,
    FormlyFieldKendoInputAutocompleteComponent,
    FormlyFieldKendoComboboxTypeComponent,
    PanelWrapperFieldComponent,
    GkKendoGridComponent,
    GkKendoGridLegendComponent,
    GkKendoGridCounterComponent,
    GkKendoGridFooterComponent,
    GkToolbarTextComponent,
    GkToolbarButtonWithInputComponent,
    GkKendoDataBindingDirective,
    GkKendoUploadComponent,
    GkToolbarSelectAllCheckboxComponent,
    GkUploadActionButtonsComponentComponent,
    GkKendoActionButtonsComponent,
    FileNamingPatternsComponent,
    SizeReportsLimitComponent,
    GkToolBarButtonListComponent,
    GkToolbarSplitbuttonComponent,
    GkToolbarDropdownlistComponent,
    GkKendoGeomFileSelectComponent,
    GkKendoIdsFileSelectComponent,
    MapRangePickerGridComponent,
    GkMapSheetFormComponent,
    GridToolbarItemsPipe,
    LandParcelSearchFormComponent,
    GkKendoGridToolbarComponent,
    FormlyFieldKendoCheckboxComponent,
    FormlyFieldKendoTextAreaComponent,
    FormlyFieldKendoSelectRangeComponent,
    FormlyFieldKendoSelectPointComponent,
    FormlyFieldKendoMultiSelectComponent,
    GkKendoDocumentationGridComponent,
    GkKendoDocumentationGridWrapperComponent,
    GkKendoMessagesListGridComponent,
    GkKendoMessagesListGridWrapperComponent,
    GkKendoCommunicatorComponent,
    MobileDeviceTextPipe,
    GkKendoGridWithDetailsComponent,
  ],
  imports: [
    CommonModule,
    WindowModule,
    GridModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    TranslateModule,
    TooltipsModule,
    ToolBarModule,
    UploadModule,
    LabelModule,
    DropDownsModule,
    FormFieldModule,
    DialogModule,
    DateInputsModule,
    ButtonsModule,
    LayoutModule,
    FormlyKendoModule,
    FormlyPresetModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: FormFieldWrappers.FormField,
          component: FormlyWrapperFormFieldComponent,
        },
        {
          name: FormFieldWrappers.KendoPanel,
          component: PanelWrapperFieldComponent,
        },
      ],
      types: [
        {
          name: FormFieldType.KendoInput,
          component: FormlyFieldKendoInputComponent,
        },
        {
          name: FormFieldType.KendoSelect,
          component: FormlyFieldKendoSelectComponent,
        },
        {
          name: FormFieldType.KendoRadio,
          component: FormlyFieldKendoRadioComponent,
        },
        {
          name: FormFieldType.KendoDatepicker,
          component: FormlyFieldKendoDatepickerComponent,
        },
        {
          name: FormFieldType.RangePicker,
          component: FormlyFieldRangePickerComponent,
          defaultOptions: {
            validation: {
              messages: {
                required: 'Praca wymaga wskazania obszaru',
                landParcelArchivalFound:
                  'Na liście znajduje się archiwalna działka. Nie możesz kontynuować',
                notSelectedRange: 'Praca wymaga wskazania obszaru',
                notAdjacentParcels:
                  'Wszystkie działki muszą do siebie przylegać',
                notIntersectsRange:
                  'Co najmniej jedna działka nie przecina się z aktualnym zakresem',
                rangesCoverageNotValid:
                  'GK.MAP.PREVIOUS_RANGE_COVERAGE_VALIDATION',
              },
            },
          },
        },
        {
          name: FormFieldType.KendoInputAutocomplete,
          component: FormlyFieldKendoInputAutocompleteComponent,
        },
        {
          name: FormFieldType.KendoCombobox,
          component: FormlyFieldKendoComboboxTypeComponent,
        },
        {
          name: FormFieldType.KendoCheckbox,
          component: FormlyFieldKendoCheckboxComponent,
          wrappers: [FormFieldWrappers.FormField],
        },
        {
          name: FormFieldType.Textarea,
          component: FormlyFieldKendoTextAreaComponent,
          wrappers: [FormFieldWrappers.FormField],
        },
        {
          name: FormFieldType.KendoSelectRange,
          component: FormlyFieldKendoSelectRangeComponent,
          wrappers: [FormFieldWrappers.FormField],
        },
        {
          name: FormFieldType.KendoSelectPoint,
          component: FormlyFieldKendoSelectPointComponent,
          wrappers: [FormFieldWrappers.FormField],
        },
        {
          name: FormFieldType.MultiSelect,
          component: FormlyFieldKendoMultiSelectComponent,
          wrappers: [FormFieldWrappers.FormField],
        },
      ],
      validationMessages: [
        { name: 'required', message: 'To pole jest wymagane' },
      ],
    }),
    ReactiveFormsModule,
    FormlySelectModule,
    FormsModule,
    IndicatorsModule,
    FileSelectModule,
    TextAreaModule,
    GkComponentsModule,
    MenusModule,
    KendoAngularExtensionsModule,
  ],
  exports: [
    WindowModule,
    GridModule,
    DialogModule,
    ToolBarModule,
    LayoutModule,
    LabelModule,
    FormFieldModule,
    DateInputsModule,
    InputsModule,
    TabStripModule,
    DropDownsModule,
    GkToolbarTextComponent,
    GkToolbarButtonWithInputComponent,
    GkKendoDataBindingDirective,
    GkKendoGridLegendComponent,
    GkKendoGridCounterComponent,
    GkKendoGridFooterComponent,
    GkToolbarSelectAllCheckboxComponent,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    GkKendoActionButtonsComponent,
    FileNamingPatternsComponent,
    SizeReportsLimitComponent,
    IndicatorsModule,
    GkToolbarSplitbuttonComponent,
    GkToolbarDropdownlistComponent,
    MapRangePickerGridComponent,
    GkMapSheetFormComponent,
    GridToolbarItemsPipe,
    GkKendoGridComponent,
    TextAreaModule,
    TooltipsModule,
    GkKendoGridToolbarComponent,
    TranslateModule,
    GkKendoDocumentationGridComponent,
    MobileDeviceTextPipe,
    GkComponentsModule,
    MenusModule,
    KendoAngularExtensionsModule,
  ],
  providers: [
    { provide: MessageService, useClass: GkKendoMessageService },
    DialogManagerService,
    GkKendoWindowService,
    GkKendoUploadService,
    MapRequestsService,
    AttributesFilterService,
    PolygonTopologyService,
    BaseDocumentationGridService,
    GkKendoGridMapService,
    GkMapSheetFormService,
    DomRefService,
    {
      provide: FORMLY_CONFIG,
      useFactory: registerKendoFormlyPresets,
      multi: true,
    },
  ],
})
export class GkKendoModule {}
