import { Injectable } from '@angular/core';
import { GridDataService } from '@gk/gk-modules';
import { FspBorderPointsGridDto } from './fsp-border-points-grid-data-model';

@Injectable({
  providedIn: 'any',
})
export class FspBorderPointsGridDataService extends GridDataService<FspBorderPointsGridDto> {
  constructor() {
    super(undefined, 'GET');
  }
}
