import { Component } from '@angular/core';

@Component({
  selector: 'free-services-parcels',
  templateUrl: './fsp-parcels.component.html',
  standalone: false,
})
export class FspParcelsComponent {
  constructor() {}
}
