import { AfterViewInit, Component } from '@angular/core';
import {
  GkKendoGridComponent,
  GkWindowRef,
  KendoWindowInstanceNames,
  RequestType,
} from '@gk/gk-modules';
import { FreeServicesParcelRequestGridDataService } from './free-services-parcel-request-grid-data.service';
import { FreeServicesParcelRequestGridDto } from './free-services-parcel-request-grid-data-model';
import {
  getFreeServicesParcelRequestGridToolbarItems,
  freeServicesParcelRequestGridColumns,
} from './free-services-parcel-request-grid-model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { filter, takeWhile, tap } from 'rxjs';
import { FreeServicesRequestListGridDataService } from '../../free-services-request-list-grid/free-services-request-list-grid-data.service';
import { FreeServicesRequestListGridComponent } from '../../free-services-request-list-grid/free-services-request-list-grid.component';
import { FreeServicesRequestListGridDto } from '../../free-services-request-list-grid/free-services-request-list-grid-data-model';
import { FspBorderPointsGridComponent } from '../fsp-border-points-grid/fsp-border-points-grid.component';
import { FspClassUseGridComponent } from '../fsp-class-use-grid/fsp-class-use-grid.component';
import { FreeServicesParcelSearchFormComponent } from '../fsp-parcel-search-form/fsp-parcel-search-form.component';
import { FspBuildingRegisterComponent } from '../fsp-building-register/fsp-building-register.component';
import { FspPremissesRegisterComponent } from '../fsp-premisses-register-grid/fsp-premisses-register.component';
import { FreeServicesParcelSearchApiFields } from '../fsp-parcel-search-form/fsp-parcel-search-form.model';

@Component({
  selector: 'app-free-services-parcel-request-grid',
  templateUrl:
    '../../../../../../../../../libs/gk-modules/src/lib/gk-kendo/gk-kendo-grid/gk-kendo-grid.component.html',
  standalone: false,
})
export class FreeServicesParcelRequestGridComponent
  extends GkKendoGridComponent<FreeServicesParcelRequestGridDto>
  implements AfterViewInit
{
  constructor(
    public freeServicesParcelRequestGridDataService: FreeServicesParcelRequestGridDataService,
    public freeServicesRequestListGridDataService: FreeServicesRequestListGridDataService,
  ) {
    super(freeServicesParcelRequestGridDataService);
    this.selectionKey = 'KdzialkaUuid';
  }
  windowRef: GkWindowRef;
  parent: FreeServicesRequestListGridComponent;
  selectedRequestGridItem: FreeServicesRequestListGridDto;
  selectedParcelRequestGridItem: FreeServicesParcelRequestGridDto;

  override pageable = {
    pageSizes: [20, 50, 100],
  };
  override pageSize = 50;
  override firstRowSelected = true;
  override topGridToolbarItems =
    getFreeServicesParcelRequestGridToolbarItems(this);
  override columns = freeServicesParcelRequestGridColumns;
  override serverPagingSorting = true;
  override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  override sortable = true;

  override ngAfterViewInit(): void {
    this.subscribeToRequestListGridSelectedKeys();
    this.subscribeToParcelRequestListGridSelectedKeys();
  }

  subscribeToRequestListGridSelectedKeys(): void {
    this.freeServicesRequestListGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedRequestGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedRequestGridItem = selection.selectedRows[0].dataItem;
        this.freeServicesParcelRequestGridDataService
          .queryByState({
            filter: {
              logic: 'and',
              filters: [
                {
                  field: FreeServicesParcelSearchApiFields.Uuid,
                  operator: 'eq',
                  value: this.selectedRequestGridItem.ZamowUuid,
                },
              ],
            },
          })
          .subscribe();
      });
  }
  subscribeToParcelRequestListGridSelectedKeys(): void {
    this.freeServicesParcelRequestGridDataService.$selection
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.selectedParcelRequestGridItem = undefined;
        }),
        filter(Boolean),
      )
      .subscribe((selection) => {
        this.selectedParcelRequestGridItem = selection.selectedRows[0].dataItem;
      });
  }

  openSearchParcelsWindow(): void {
    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      windowInstanceName: KendoWindowInstanceNames.FSPSearchParcel,
      title: 'FREE_PORTAL_SERVICES.SEARCH',
      content: FreeServicesParcelSearchFormComponent,
      width: 440,
      height: 280,
    });
  }

  handleOpenBorderPoints(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      windowInstanceName: KendoWindowInstanceNames.FSPBorderPoints,
      title: 'FREE_PORTAL_SERVICES.BORDER_POINTS',
      content: FspBorderPointsGridComponent,
      width: 960,
      height: 280,
    });
  }

  handleOpenClassUse(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      windowInstanceName: KendoWindowInstanceNames.FSPClassUse,
      title: 'FREE_PORTAL_SERVICES.CLASS_USE',
      content: FspClassUseGridComponent,
      width: 460,
      height: 220,
    });
  }

  handleOpenBuildings(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      windowInstanceName: KendoWindowInstanceNames.FSPBuildingRegister,
      title: 'FREE_PORTAL_SERVICES.BUILDINGS',
      content: FspBuildingRegisterComponent,
      width: 830,
      height: 520,
    });
  }

  handleOpenPremisses(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    this.gkKendoWindowService.open({
      parent: this,
      parentWindowInstanceName: KendoWindowInstanceNames.FSPParcelRequest,
      windowInstanceName: KendoWindowInstanceNames.FSPPremisses,
      title: 'FREE_PORTAL_SERVICES.PREMISSES',
      content: FspPremissesRegisterComponent,
      width: 1240,
      height: 520,
    });
  }

  downloadReport(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    this.downloadService.downloadAndSaveFile(
      `/api/interesant/przp/dzialka/${this.selectedParcelRequestGridItem.KdzialkaUuid}/wydruk`,
      {},
      RequestType.Post,
    );
  }
}
