import { Component, OnInit } from '@angular/core';
import { BaseDetailsComponent } from 'kendo-angular-extensions';
import { takeWhile } from 'rxjs';
import { MatrixDetailsGridDto } from '../matrix-details-grid/matrix-details-grid-data-model';
import { MatrixDetailsGridDataService } from '../matrix-details-grid/matrix-details-grid-data.service';
import { MatrixDetails } from './matrix-details-details.model';

@Component({
  selector: 'gk-matrix-details-details',
  standalone: false,
  templateUrl:
    '../../../../../../../../../../libs/kendo-angular-extensions/src/lib/base-details/base-details.component.html',
})
export class MatrixDetailsDetailsComponent
  extends BaseDetailsComponent<MatrixDetailsGridDto>
  implements OnInit
{
  override parseCallback = MatrixDetails.fromApiToApp;

  constructor(
    private matrixDetailsGridDataService: MatrixDetailsGridDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToAddressPointChange();
  }

  private subscribeToAddressPointChange(): void {
    this.matrixDetailsGridDataService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItem = selection?.selectedItems[0];
        if (selectedItem) {
          this.loadDetailsWithData(selectedItem);
        }
      });
  }
}
