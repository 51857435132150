export interface RequestListGridDto {
  DataRozpocz: string;
  DataZalatw: string;
  Dokumenty: boolean;
  IsFromWebEwid: boolean;
  Numer: string;
  Status: RequestStatuses;
  ZamowId: number;
  ZamowUuid: string;
}

export enum RequestStatuses {
  Cancelled = 'ANULOWANE',
  Rejected = 'ODRZUCONE',
  Completed = 'ZREALIZOWANE',
  Registered = 'ZAREJESTROWANE',
}
