import { Component } from '@angular/core';

@Component({
  selector: 'fsp-premisses-register',
  templateUrl: './fsp-premisses-register.component.html',
  standalone: false,
})
export class FspPremissesRegisterComponent {
  constructor() {}
}
