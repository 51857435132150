<form
  class="k-form k-form-sm k-form-horizontal d-flex h-100 flex-column justify-content-between"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit()"
>
  <formly-form
    [form]="form"
    [fields]="formlyFormConfig.fields"
    [model]="formModel"
  ></formly-form>
  <gk-kendo-action-buttons
    *ngIf="!!freeServicesParcelSearchFormActionButtonsConfig"
    [buttonsConfigs]="freeServicesParcelSearchFormActionButtonsConfig"
  ></gk-kendo-action-buttons>
</form>
