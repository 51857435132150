import {
  iwIconDrawPoint,
  iwIconPodgladMapa,
  iwIconRysujWielobok,
} from '../../../../../../assets/css/ewid-svg/svg-icons';
import { RequestType } from '../../../../../../gk-dynamic-list/gk-dynamic-list.model';
import {
  ColumnSetting,
  ColumnType,
  GenericGridToolbarItemDetailsPayload,
  GenericGridToolbarItemDocumentationPayload,
  GenericGridToolbarItemName,
  GenericToolbarItemName,
  GridToolBarItem,
  KendoWindowInstanceNames,
} from '../../../../../../gk-kendo';
import { AnyGeometryObject } from '../../../../../models';
import { DevelopmentPlanDetails } from './development-plan-details-details/development-plan-details-details.model';
import { DevelopmentPlanDetailsGridComponent } from './development-plan-details-grid.component';

export const getDevelopmentPlanDetailsGridToolbarItems = (
  c: DevelopmentPlanDetailsGridComponent,
): GridToolBarItem[] => {
  return [
    {
      name: GenericToolbarItemName.GetPointGeometryFromMap,
      svgIcon: iwIconPodgladMapa,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.ADD_LAND_FROM_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.ADD_LAND_FROM_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetPolygonGeometryFromMap,
      svgIcon: iwIconRysujWielobok,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      text: 'GENERIC_TOOLBAR_ITEM.SEARCH_BY_MARK_RANGE',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericToolbarItemName.GetLineGeometryFromMap,
      svgIcon: iwIconDrawPoint,
      className: 'svg-icon-blue',
      title: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      text: 'GENERIC_TOOLBAR_ITEM.DRAW_LINE_ON_MAP',
      showText: 'overflow',
      payload: () => ({
        doneCallback: (anyGeometryObject: AnyGeometryObject[]): void => {
          c.addPointGeometryFromMap(anyGeometryObject[0].geom);
        },
      }),
    },
    {
      name: GenericGridToolbarItemName.RemoveAllGridItems,
      className: 'svg-icon-blue',
    },
    {
      name: GenericGridToolbarItemName.Documentation,
      title: 'DEVELOPMENT_PLAN_DETAILS.REQUEST_INCOMING_DOCUMENTS_TITLE',
      text: 'DEVELOPMENT_PLAN_DETAILS.REQUEST_INCOMING_DOCUMENTS_TITLE',
      payload: (): GenericGridToolbarItemDocumentationPayload => ({
        windowInstanceName:
          KendoWindowInstanceNames.CmpRequestIncomingDocumentsWindow,
        windowTitle:
          'DEVELOPMENT_PLAN_DETAILS.REQUEST_INCOMING_DOCUMENTS_TITLE',
        url: '/api/planzagospodarowania/uchwala/{UchwalaId}/dokpowiazana',
        gridToolBarItems: [
          {
            name: GenericGridToolbarItemName.PreviewDocument,
            payload: () => ({
              url: '/api/planzagospodarowania/dokpowiazana/download',
              requestType: RequestType.Post,
            }),
            separator: true,
          },
          {
            name: GenericGridToolbarItemName.DownloadFile,
            payload: () => ({
              downloadUrl: '/api/planzagospodarowania/dokpowiazana/download',
            }),
          },
        ],
      }),
    },
    {
      name: GenericGridToolbarItemName.Details,
      payload: (): GenericGridToolbarItemDetailsPayload => ({
        windowInstanceName: KendoWindowInstanceNames.CmpRequestsDetailsWindow,
        windowTitle: () => c.getDetailsWindowTitle(),
        url: '/api/planzagospodarowania/uchwalyplany/{UchwalaId}',
        parseCallback: DevelopmentPlanDetails.fromApiToApp,
      }),
    },
  ];
};

export const developmentPlanDetailsGridColumns: ColumnSetting[] = [
  {
    field: 'NrUchwaly',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.RESOLUTION_NUMBER',
    type: ColumnType.Text,
  },
  {
    field: 'Stan',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.STATUS',
    type: ColumnType.Text,
  },
  {
    field: 'ZDnia',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.DATE',
    type: ColumnType.Text,
  },
  {
    field: 'Typ',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.TYPE',
    type: ColumnType.Text,
  },
  {
    field: 'Rada',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.COUNCIL',
    type: ColumnType.Text,
  },
  {
    field: 'LinkUchw',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.LINK',
    type: ColumnType.Html,
  },
  {
    field: 'LinkZgr',
    title: 'DEVELOPMENT_PLAN_DETAILS.GRID.GRAPHIC_ATTACHMENT',
    type: ColumnType.Html,
  },
];
