import { Component, OnDestroy, OnInit } from '@angular/core';
import { GkWindowRef } from '@gk/gk-modules';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'free-services-request-list',
  templateUrl: './free-services-request-list.component.html',
  standalone: false,
})
export class FreeServicesRequestListComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}
  windowRef: GkWindowRef;
  urlSubscription!: Subscription;

  ngOnInit(): void {
    this.maybeCloseWindowByRoute();
  }

  maybeCloseWindowByRoute(): void {
    this.urlSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url;

        if (!currentUrl.includes('free-services-portal/request-list')) {
          this.windowRef.close();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }
}
