import { BaseDetailSettings, DetailType } from 'kendo-angular-extensions';
import { FreeServicesRequestListGridDto } from '../free-services-request-list-grid/free-services-request-list-grid-data-model';

export class FreeServicesRequestDetails {
  static fromApiToApp(
    dto: FreeServicesRequestListGridDto,
  ): BaseDetailSettings[] {
    return [
      {
        title: 'FREE_PORTAL_SERVICES.TASK_DESCRIPTION',
        value: dto?.ZadaniePubliczne,
        type: DetailType.Text,
      },
      {
        title: 'FREE_PORTAL_SERVICES.LEGAL_PROVISION',
        value: dto?.PodstawaPrawnaZadPubl,
        type: DetailType.Text,
      },
      {
        title: 'FREE_PORTAL_SERVICES.PERIOD',
        value: dto?.CzasDostepuTxt,
        type: DetailType.Text,
      },
    ];
  }
}
