import {
  DataBindingDirective,
  GridDataResult,
  SelectionDirective,
} from '@progress/kendo-angular-grid';
import {
  Directive,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { delay, of, takeWhile } from 'rxjs';
import { GridDataService } from './services/grid-data.service';
import {
  GkKendoGridDataResult,
  GkKendoGridItem,
  GkKendoGridItemKey,
  GkRowArgs,
} from './gk-kendo-grid/gk-kendo-grid.model';
import * as _ from 'lodash';
import { orderBy, State } from '@progress/kendo-data-query';
import { switchMap } from 'rxjs/operators';

@Directive({
  selector: '[gkKendoGridBinding]',
  standalone: false,
})
export class GkKendoDataBindingDirective<T, U>
  extends DataBindingDirective
  implements OnInit, OnDestroy
{
  @Input() autoBind = false;
  @Input() unionResponseWithGridData = false;
  @Input() firstRowSelected = false;
  @Input() serverPagingSorting = false;

  private isAlive = true;
  private gridSelectionKey: GkKendoGridItemKey<T, U>;
  private gridRemoveMode = false;
  private toastrService = inject(ToastrService);
  private renderer = inject(Renderer2);
  private gridDataService: GridDataService<T, U>;

  @Input({
    required: true,
    alias: 'gkKendoGridBinding',
  })
  public set gridData(
    gridData: GridDataService<T, U> | Array<GkKendoGridItem<T, U>>,
  ) {
    if (Array.isArray(gridData)) {
      this.grid.data = gridData;
    } else {
      gridData.dataBindingDirective = this;
      this.gridDataService = gridData;
    }
  }

  public get gridDataResult(): GkKendoGridDataResult<T, U> {
    if (this.serverPagingSorting) {
      if (this.grid.data) {
        return this.grid.data as GkKendoGridDataResult<T, U>;
      } else {
        return {
          data: [],
          total: 0,
        };
      }
    } else {
      if (this.state.sort) {
        return {
          data: orderBy(this.originalData, this.state.sort),
          total: this.originalData.length,
        };
      } else {
        return {
          data: this.originalData,
          total: this.originalData.length,
        };
      }
    }
  }

  private get gridSelectionDirective(): SelectionDirective {
    return this.grid.selectionDirective as SelectionDirective;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setGridSelectionKey();
    this.handleGridSelectionKeysChange();
    if (this.gridDataService) {
      this.subscribeToGridDataService();
      this.subscribeToGridSelectedKeys();
      this.gridDataService.$count.next(0);
    }
    this.handleRemoveEvent();
    this.handleGridAutoBinding();
  }

  public override rebind(): void {
    if (this.serverPagingSorting) {
      this.grid.loading = true;
      this.gridDataService.queryByState(this.state, false).subscribe();
    } else {
      super.rebind();
    }
  }

  public refreshGrid(): void {
    this.grid.loading = true;
    this.gridDataService.queryByState(this.state, false).subscribe();
  }

  public showMessageIfNoRowsSelected(): boolean {
    if (
      this.gridSelectionDirective.selectedKeys.length > 1 &&
      this.grid.selectableSettings.mode === 'single'
    ) {
      this.toastrService.warning('Proszę zaznaczyć wiersz');

      return false;
    }
    if (!this.gridSelectionDirective.selectedKeys.length) {
      this.toastrService.warning('Proszę zaznaczyć wiersz (-e)');

      return false;
    }

    return true;
  }

  public getState(): State {
    return this.state;
  }

  public maybeEmitRemoveEvent(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    this.grid.remove.emit();
  }

  public emitRemoveAllGridItems(): void {
    this?.grid?.remove.emit({
      isNew: false,
      dataItem: undefined,
      rowIndex: 0,
      sender: undefined,
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    setTimeout(() => {
      this.removeAllGridItems();
    });
    this.isAlive = false;
  }

  private removeAllGridItems(): void {
    this.gridRemoveMode = true;
    this.gridDataService?.next({ data: [], total: 0 });
    if (this.grid.scrollable === 'virtual') {
      this.grid.skip = 0;
    }
  }

  private subscribeToGridSelectedKeys(): void {
    this.gridDataService.$selectedKeys
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selectedKeys) => {
        if (selectedKeys) {
          this.gridSelectionDirective.selectedKeysChange.emit(selectedKeys);
        } else {
          this.gridSelectionDirective.selectedKeysChange.emit([]);
        }
      });
  }

  private subscribeToGridDataService(): void {
    this.gridDataService
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((gridDataResult) => {
        this.gridSelectionDirective.selectedKeysChange.emit([]);
        // if (gridDataResult.data.length) {
        if (this.serverPagingSorting) {
          this.grid.data = this.maybeUnionResponseWithGridData(gridDataResult);
        } else {
          this.data = this.maybeUnionResponseWithGridData(gridDataResult).data;
        }
        setTimeout(() => {
          this.gridDataService.$gridDataBound.next(this.gridDataResult.data);
          this.maybeSelectFirstRow(this.gridDataResult);
          this.gridDataService.$count.next(this.gridDataResult.total);
        });
        // } else {
        //   this.grid.data = gridDataResult;
        //   this.gridDataService.$selection.next(undefined);
        //   this.gridDataService.$count.next(this.grid.data.total);
        // }
        this.gridRemoveMode = false;
        this.notifyDataChange();
      });
  }

  private handleGridAutoBinding(): void {
    if (this.autoBind) {
      this.gridDataService.queryByState(this.state, false).subscribe();
    }
  }

  private handleRemoveEvent(): void {
    this.grid.remove
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap((removeEvent) => {
          this.setScrollOnTop();

          return of(removeEvent).pipe(delay(0));
        }),
      )
      .subscribe((removeEvent) => {
        if (removeEvent?.rowIndex === 0) {
          this.removeAllGridItems();
        } else {
          this.removeSelectedGridItems();
        }
        this.gridSelectionDirective.selectedKeysChange.emit([]);
        this.gridDataService.$gridDataBound.next(this.gridDataResult.data);
      });
  }

  private maybeSelectFirstRow(gridDataResult: GridDataResult): void {
    if (!gridDataResult.data.length) {
      return;
    }
    if (this.firstRowSelected) {
      const key = this.gridSelectionKey
        ? [gridDataResult.data[0][this.gridSelectionKey]]
        : [0];
      this.gridSelectionDirective.selectedKeysChange.emit(key);
    }
  }

  private maybeUnionResponseWithGridData(
    gridDataResult: GkKendoGridDataResult<T, U>,
  ): GkKendoGridDataResult<T, U> {
    if (this.unionResponseWithGridData && !this.gridRemoveMode) {
      return this.handleUnionResponseWithGridData(gridDataResult);
    } else {
      return this.maybeMapGridData(gridDataResult);
    }
  }

  private maybeMapGridData(
    gridDataResult: GkKendoGridDataResult<T, U>,
  ): GkKendoGridDataResult<T, U> {
    if (this.gridDataService.mapGridData) {
      const mappedGridData = this.gridDataService.mapGridData(
        gridDataResult.data,
      );
      return {
        data: mappedGridData,
        total: mappedGridData.length,
      };
    } else {
      return gridDataResult;
    }
  }

  private handleUnionResponseWithGridData(
    gridDataResult: GkKendoGridDataResult<T, U>,
  ): GkKendoGridDataResult<T, U> {
    const unitedGridData = _.unionBy(
      gridDataResult.data,
      this.gridDataResult.data || [],
      this.gridSelectionKey,
    );

    return {
      data: unitedGridData,
      total: unitedGridData.length,
    };
  }

  private setGridSelectionKey(): void {
    this.gridSelectionKey = <GkKendoGridItemKey<T, U>>(
      this.gridSelectionDirective.selectionKey
    );
  }

  private handleGridSelectionKeysChange(): void {
    this.gridSelectionDirective.selectedKeysChange
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selectedKeys) => {
        if (selectedKeys.length) {
          if (this.gridDataService) {
            this.gridDataService.$selection.next({
              selectedKeys: selectedKeys,
              selectedRows: this.getSelectedRows(selectedKeys),
              selectedItems: this.getSelectedItems(selectedKeys),
            });
          }
        } else {
          this.gridSelectionDirective.selectedKeys = [];
          if (this.gridDataService) {
            this.gridDataService.$selection.next(undefined);
          }
        }
      });
  }

  private getSelectedItems(selectedKeys: any[]): GkKendoGridItem<T, U>[] {
    if (this.gridSelectionKey) {
      return this.gridDataResult.data.filter((value) =>
        selectedKeys.includes(value[this.gridSelectionKey]),
      );
    }

    return this.gridDataResult.data.filter((_, index) =>
      selectedKeys.includes(index),
    );
  }

  private getSelectedRows(
    selectedKeys: any[],
  ): GkRowArgs<GkKendoGridItem<T, U>>[] {
    return this.getSelectedItems(selectedKeys).map((value, index) => ({
      dataItem: value,
      index: index,
    }));
  }

  private removeSelectedGridItems(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const filteredGridData = this.gridDataResult.data.filter((value, index) =>
      this.gridSelectionKey
        ? !this.gridSelectionDirective.selectedKeys.includes(
            value[this.gridSelectionKey],
          )
        : !this.gridSelectionDirective.selectedKeys.includes(index),
    );

    this.gridRemoveMode = true;

    this.gridDataService.next({
      data: filteredGridData,
      total: filteredGridData.length,
    });
  }

  public setScrollOnTop(): void {
    this.renderer.setProperty(
      this.grid.wrapper.nativeElement.querySelector('.k-grid-content'),
      'scrollTop',
      0,
    );
  }
}
